import React, { ReactNode } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { EnvelopeIcon } from '@curated-property/icons';
import { CameraIcon } from '@curated-property/icons';
import { PinIcon } from '@curated-property/icons';
import { SaleIcon } from '@curated-property/icons';
import { useLanguage } from '@curated-property/utils';
import { InavMenuItem } from '../header';
import { isHexOrRgb, containsAbsoluteUrl } from '../functions/helper';

export interface INavUtilityStylingOptionProps {
  extraClasses?: string;
  fontSize?: string;
  color?: string;
  iconColor?: string;
  activeColor?: string;
  activeIconColor?: string;
  separatorColor?: string;
  underline?: boolean;
  underlineHover?: boolean;
}

interface Props {
  className?: string;
  iconMenu?: Array<InavMenuItem>;
  stylingOptions?: INavUtilityStylingOptionProps;
  activeHeader: (arg: boolean) => void;
  urlPrefix?: string;
}

export function NavUtility({
  className,
  iconMenu,
  stylingOptions,
  activeHeader,
  urlPrefix,
}: Props) {
  const language = useLanguage();
  const textColor = stylingOptions?.color || '';
  const iconColor = stylingOptions?.iconColor;
  const borderColor = stylingOptions?.separatorColor
    ? stylingOptions?.separatorColor
    : stylingOptions?.color;
  const fillColor = isHexOrRgb(iconColor || '') ? iconColor : '';
  const fontSizeAlt = stylingOptions?.fontSize || 'text-sm';
  const flexDir = 'flex-row';
  const underline = stylingOptions?.underline
    ? 'underline hover:no-underline'
    : stylingOptions?.underlineHover
    ? 'hover:underline'
    : 'hover:no-underline';

  return (
    <div
      data-testid="nav-utility"
      data-element-id="nav-utility"
      className={cx(className, 'hidden lg:flex')}
      style={{ borderColor: borderColor }}
    >
      <ul className="flex justify-around order-1 items-center">
        {iconMenu?.map((item, key) => {
          const iconStyles = `${
            isHexOrRgb(iconColor || '') ? '' : 'fill-current'
          } ${
            !isHexOrRgb(textColor) ? textColor : ''
          } w-4 h-4 mr-2 cursor-pointer`;
          let currentIcon: ReactNode;
          switch (item?.icon) {
            case 'nav-camera':
              currentIcon = (
                <CameraIcon className={iconStyles} fillColor={fillColor} />
              );
              break;
            case 'nav-pin':
              currentIcon = (
                <PinIcon className={iconStyles} fillColor={fillColor} />
              );
              break;
            case 'nav-envelope':
              currentIcon = (
                <EnvelopeIcon className={iconStyles} fillColor={fillColor} />
              );
              break;
            case 'nav-sale':
              currentIcon = (
                <SaleIcon className={cx(iconStyles, 'w-5 h-5')} fillColor={fillColor} />
              );
              break;
            default:
              currentIcon = '';
              return false;
          }
          return (
            <li
              key={key}
              className={`${underline} py-1 px-2 flex flex-col items-center justify-center ${
                !isHexOrRgb(textColor) ? textColor : ''
              } ${fontSizeAlt} ${stylingOptions?.extraClasses || ''}`}
              onMouseEnter={() => {
                activeHeader?.(true);
              }}
              onMouseLeave={() => {
                activeHeader?.(false);
              }}
            >
              {containsAbsoluteUrl(item?.url || '') ? (
                <a
                  href={item?.url?.split(urlPrefix || ' ')?.[0] || ''}
                  target="_blank"
                  className={`flex items-center justify-center cursor-pointer ${flexDir}`}
                  style={{
                    color: isHexOrRgb(textColor || '') ? textColor : undefined,
                  }}
                  rel="noreferrer"
                >
                  {currentIcon}
                  <span>{item?.label}</span>
                </a>
              ) : (
                <Link
                  href={`/${language}${item?.url}`}
                  style={{
                    color: isHexOrRgb(textColor || '') ? textColor : undefined,
                  }}
                  className={`flex items-center justify-center cursor-pointer ${flexDir}`}
                >
                  <>
                    {currentIcon}
                    <span>{item?.label}</span>
                  </>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

import React, { useState, useEffect, ComponentProps, useContext } from 'react';
import {
  ShopForm,
  ShopFormDates,
  ShopFormRooms,
  ShopFormSpecialRates,
} from '@dx-ui/osc-shop-form';
import { useTranslation } from 'next-i18next';
import { format, addDays } from 'date-fns';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { Caret } from '@curated-property/icon-list';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  FormChangeMonitor,
  SharedContext,
  parseBookingDate,
} from '@curated-property/utils';
import { sendReward } from '@dx-ui/framework-conductrics';

interface OscProps {
  ctyhocn?: string;
  defaultArrivalDate?: string;
  gmtHours?: number;
  brandCode?: string;
  currency?: string | 'USD';
  associatedHotels?: {
    hotel?: {
      name?: string;
      ctyhocn?: string;
    };
  }[];
  parentElement?: string;
  oscBookButtonStyle?: string;
  isInModal?: boolean;
}

const OscComposableSearchForm: React.FC<OscProps> = (oscProps) => {
  const sharedContext = useContext(SharedContext);
  const brandCode = oscProps?.brandCode || 'HI';
  const [searchCtyhocn, setSearchCtyhocn] = useState(oscProps?.ctyhocn);
  const [isPropertyGroup, setIsPropertyGroup] = useState(false);
  const [selectEntered, setSelectEntered] = useState('');
  const { t } = useTranslation();
  const language = useRouter().locale || 'en';
  const startDate = parseBookingDate(
    oscProps?.gmtHours,
    oscProps?.defaultArrivalDate
  );

  let nextDayDate: Date | string = addDays(startDate, 1);

  const nowDate: Date | string = format(
    startDate,
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
  );
  nextDayDate = format(nextDayDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

  const baseUrl = 'https://www.hilton.com';
  const onFormSubmit = (url: string | null) => {
    if (oscProps?.isInModal) {
      sendReward('EngageCheckRatesCTAOnModal');
    } else {
      sendReward('userClickedBookButtonCurated');
    }
    let urlStr = `${baseUrl}${url}`;
    if (isPropertyGroup === true) {
      const ctyhocnRegex = /ctyhocn=[A-Z]*&/gm;
      urlStr = urlStr.replace(
        ctyhocnRegex,
        `ctyhocn=${searchCtyhocn?.toUpperCase()}&`
      );
    }
    window.open(urlStr, '_blank', 'noopener noreferrer');
  };

  const handleFormSubmit: ComponentProps<typeof ShopForm>['onSubmit'] = ({
    url,
  }) => {
    onFormSubmit(url);
  };

  const associatedHotels = oscProps?.associatedHotels?.length || 0;

  const bookButtonLogic = () => {
    const bookBtn = oscProps?.parentElement
      ? document?.querySelector(
          `${oscProps?.parentElement} [data-osc-product="shop-form-button"]`
        )
      : document?.querySelector(`[data-osc-product="shop-form-button"]`);
    if (associatedHotels > 0) {
      if (selectEntered === '') {
        bookBtn?.setAttribute('disabled', 'true');
      } else {
        bookBtn?.removeAttribute('disabled');
      }
    }
  };

  useEffect(() => {
    if (associatedHotels > 0) {
      setIsPropertyGroup(true);
    }
    bookButtonLogic();
  }, [oscProps.ctyhocn, selectEntered, associatedHotels, bookButtonLogic]);

  useEffect(() => {
    setTimeout(() => {
      bookButtonLogic();
    }, 50);
  });

  const PropertySwitch = () => {
    return (
      <fieldset data-testid="hotelSelector" className={`w-full flex-shrink`}>
        <OscComposableWidgetLabel text="Resorts" for="oscHotelSelect" />
        <div className="relative">
          <select
            className={`px-3 py-1 pr-5 font-bold text-sm bg-bg border border-solid text-black rounded mr-1 w-full flex-shrink min-w-[200px]`}
            onChange={(e) => {
              setSearchCtyhocn(e.target.value);
              setSelectEntered(e.target.value);
            }}
            name="oscHotelSelect"
            id="oscHotelSelect"
            data-testid="hotelSelectDropdown"
            data-osc-product="osc-hotel-select"
            value={searchCtyhocn}
            required
          >
            <option value="" selected>
              Select a Resort
            </option>
            {oscProps?.associatedHotels?.map((i, e) => {
              return (
                <option value={i?.hotel?.ctyhocn} key={`option${e}`}>
                  {i?.hotel?.name}
                </option>
              );
            })}
          </select>
          <Caret className="absolute top-0 bottom-0 right-[12px] my-auto w-2 pointer-events-none" />
        </div>
      </fieldset>
    );
  };

  const adultAge =
    sharedContext?.dxGql?.hotel?.shopAvailOptions?.adultAge ?? undefined;
  const ageRange =
    sharedContext?.dxGql?.hotel?.shopAvailOptions?.ageBasedPricing && adultAge
      ? {
          max: adultAge,
          min: 0,
        }
      : undefined;
  const agesRequired = !!ageRange;

  return (
    <FormChangeMonitor onAny={() => sendReward('editCuratedSearchWidget')}>
      <ShopForm
        itemRef=""
        additionalQSParameters={{
          displayCurrency: oscProps?.currency,
          requestedRatesOnly: false,
          specPlan: [],
        }}
        cta={t('oscComposableSearch.bookLabel')}
        defaultValues={{
          rooms: [
            {
              adults: sharedContext?.allInclusive ? 2 : 1,
              children: [],
            },
          ],
          brandCode: brandCode,
          query: searchCtyhocn,
          dates: {
            arrivalDate: new Date(nowDate),
            datesFlex: false,
            departureDate: new Date(nextDayDate),
          },
          specialRates: {
            aaaRate: false,
            aarpRate: false,
            corporateCode: '',
            employeeRate: false,
            friendsAndFamilyRate: false,
            governmentOrMilitaryRate: false,
            groupCode: '',
            offerId: null,
            ownerHGVRate: false,
            ownerVIPRate: false,
            pnd: null,
            promoCode: '',
            redeemPts: false,
            seniorRate: false,
            travelAgentId: '',
            travelAgentRate: false,
            useOfferId: false,
            usePnd: false,
          },
        }}
        targetOHWPage="book"
        submitOpensNewTab={true}
        language={language}
        onSubmit={handleFormSubmit}
        className={isPropertyGroup ? 'is-property-group' : ''}
        wrapperClassName={cx(
          'osc-search-content-wrapper flex flex-wrap space-y-4 lg:flex-nowrap lg:space-y-0 lg:space-x-4 lg:rtl:space-x-reverse',
          isPropertyGroup
            ? 'lg:justify-between md:justify-center justify-between items-start'
            : 'justify-between items-center'
        )}
        buttonClassName={cx(
          'btn btn-lg md:btn-base lg:btn-lg w-full',
          !oscProps?.oscBookButtonStyle
            ? ` btn-primary `
            : ` btn-${oscProps?.oscBookButtonStyle} `
        )}
      >
        {isPropertyGroup && <PropertySwitch />}
        <fieldset
          className={cx(
            'flex justify-center lg:justify-start flex-col items-center',
            !isPropertyGroup ? 'w-full' : 'm-auto mt-4 md:mx-1'
          )}
        >
          {isPropertyGroup && (
            <OscComposableWidgetLabel text="Stay Dates" className="pl-3" />
          )}
          <ShopFormDates language={language} />
        </fieldset>
        <div className="flex w-full gap-2 lg:gap-5">
          <fieldset className="flex-1">
            {isPropertyGroup && (
              <OscComposableWidgetLabel text="Rooms & Guests" />
            )}
            <ShopFormRooms
              ageRange={ageRange}
              agesRequired={agesRequired}
              adultAge={adultAge}
              adultsOnly={
                sharedContext?.dxGql?.hotel?.shopAvailOptions
                  ?.maxNumChildren === 0
              }
              maxOccupants={
                sharedContext?.dxGql?.hotel?.shopAvailOptions?.maxNumOccupants
              }
              occupancyLimitMessage={t('occupancy.occupancyLimitMessage')}
            />
          </fieldset>
          <fieldset className="flex-1">
            {isPropertyGroup && (
              <OscComposableWidgetLabel text="Rates & Codes" />
            )}
            <ShopFormSpecialRates />
          </fieldset>
        </div>
      </ShopForm>
    </FormChangeMonitor>
  );
};

interface LabelProps {
  for?: string;
  text?: string;
  className?: string;
}

function OscComposableWidgetLabel(props: LabelProps) {
  return (
    <label
      htmlFor={props?.for}
      className={`font-bold text-sm pb-2 block h-6 ${props?.className}`}
    >
      {props?.text}
    </label>
  );
}

export default function OscComposableSearchFormWrapper(props: OscProps) {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <OscComposableSearchForm {...props} />
    </QueryClientProvider>
  );
}

import { createFetch } from '@curated-property/utils';
import { WordpressPagesQuery } from './generated/wp';
import { getAllWPPagesQuery } from './queries/wp';

interface Page {
  params: {
    slug?: boolean | string[];
    language?: string;
    hotelName?: string;
  };
}

export function slugMakeGetStaticPaths({
  wpApiEndpoint,
  locales,
  hotelName,
}: {
  wpApiEndpoint: string;
  locales: string[];
  hotelName?: string;
}) {
  const fetch = createFetch(wpApiEndpoint);
  return async function getStaticPaths() {
    const wpPages = await fetch<WordpressPagesQuery>(getAllWPPagesQuery);

    // each page WP will generate a static page per locale
    const paths: Page[] = wpPages?.pages?.nodes?.reduce(
      (prev: Page[], curr) => {
        const pagePerLang = locales.map((lang) => ({
          params: {
            slug:
              curr?.uri === '/'
                ? false
                : curr?.uri?.split('/').filter((s) => !!s),
            language: lang,
            hotelName,
          },
        }));

        return [...prev, ...pagePerLang];
      },
      []
    ) as Page[];

    paths?.map((page: Page) => {
      if (page.params.slug instanceof Array) {
        page.params.slug = page.params.slug.filter(
          (slugSeg) => !slugSeg.startsWith('?lang')
        );
      }
      return page;
    });

    return {
      paths,
      fallback: false,
    };
  };
}

import { Ifooter } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/tcv5fca.css"></link>
    </>
  );
};

// this override footer
const SharedFooter = dynamic<Ifooter>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: Ifooter) => {
  return <SharedFooter {...props} brandPhoneNumber="+1-877-666-2312" />;
};

import React, { useRef, useState } from 'react';
import { PopupContent } from '../../popover-content';
import { RoomInfoControls, RoomInfoControlProps } from './room-info-controls';
import { useTranslation } from 'next-i18next';
import { useLanguage } from '@curated-property/utils';
import cx from 'classnames';

type Props = RoomInfoControlProps;

export function RoomInfoButton({ rooms, updateRooms, ageRange }: Props) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<null | HTMLButtonElement>(null);
  const [t] = useTranslation();
  const lang = useLanguage();
  const numGuests = rooms.reduce(
    (count, { adults, kids }) => count + adults + kids,
    0
  );
  return (
    <div>
      <button
        id="roomInfoButton"
        className={cx(
          'truncate btn btn-primary-outline btn-lg',
          lang === 'ar' && 'ml-2 mr-1'
        )}
        ref={buttonRef}
        onClick={() => setOpen((s) => !s)}
        aria-label={`${t('occupancy.numRooms', { count: rooms.length })}, ${t(
          'occupancy.numGuests',
          {
            count: numGuests,
          }
        )}. ${t('occupancy.buttonLabel')}`}
      >
        {t('occupancy.numRooms', { count: rooms.length })},{' '}
        {t('occupancy.numGuests', { count: numGuests })}{' '}
      </button>
      {open && (
        <PopupContent
          targetRef={buttonRef}
          onClose={() => setOpen(false)}
          className="overflow-y-auto"
        >
          <RoomInfoControls
            rooms={rooms}
            updateRooms={updateRooms}
            ageRange={ageRange}
          />
        </PopupContent>
      )}
    </div>
  );
}

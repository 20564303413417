import {
  MenuProps,
  Ifooter,
  BreadcrumbProps,
} from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { SmallChevron } from '@curated-property/icon-list';
import { MakaiLogo } from '@curated-property/icons';

const myFontWeight = 'bold' as const;

const brandLogo = (
  <MakaiLogo className="fill-current hidden sm:block w-36 pl-4 mr-4 text-current antialiased" />
);

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((h) => h.Header)
);

export const Header = (props: MenuProps) => {
  const makaiHeaderStyles =
    props?.pageSlug === 'makai'
      ? {
          headerClassName: '',
          separatorColor: '#fff',
          textColorOnLoad: '#fff',
          bgColorOnLoad: '#242124',
          textColorOnActive: '#fff',
          bgColorOnActive: '#242124',
          bgSubmenuColor: '#242124',
          fontWeight: myFontWeight,
          menuItems: {
            paddingClasses: 'p-2.5',
            colorOnHover: '#fff',
            underline: {
              active: true,
              color: '#fff',
            },
          },
          iconMenu: {
            color: 'text-dark',
            underline: true,
          },
        }
      : {
          headerClassName: '',
          textColorOnLoad: '#fff',
          bgColorOnLoad: 'rgba(42, 58, 78, 0.7)',
          textColorOnActive: '#fff',
          bgColorOnActive: 'rgba(10, 57, 113, 0.98)',
          bgSubmenuColor: 'rgba(10, 57, 113, 0.97)',
        };

  return (
    <SharedHeader
      {...props}
      brandLogo={props?.pageSlug === 'makai' ? brandLogo : null}
      styleOptions={makaiHeaderStyles}
    />
  );
};
// this override footer

const SharedFooter = dynamic<Ifooter>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: Ifooter) => {
  const makaiFooterStyles = props?.pageSlug === 'makai' && {
    backgroundColor: 'makaiBg',
  };

  return <SharedFooter {...props} styleOptions={makaiFooterStyles} />;
};

const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((b) => b.BreadCrumbs)
);

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  const makaiCrumbStyles =
    props?.pageSlug === 'makai'
      ? {
          background: 'background-light',
          anchorClasses: 'text-brand-5 hover:underline',
          activeAnchorClasses: 'text-brand-5 font-regular inline-block',
        }
      : {};

  const makaiDelimiter =
    props?.pageSlug === 'makai' ? (
      <span className="px-2 text-bg -tx-2">
        <SmallChevron className="inline w-2 h-4" />
      </span>
    ) : (
      <span className="px-2 text-bg">/</span>
    );

  return (
    <SharedBreadCrumbs
      {...props}
      styleOptions={makaiCrumbStyles}
      delimiter={makaiDelimiter}
    />
  );
};

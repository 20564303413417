import gql from 'graphql-tag-transform.macro';

const NavFragment = gql`
  fragment NavFragment on RootQuery {
    mainNav: menuItems(
      first: 100
      where: { location: MAIN_NAVIGATION, language: $language, parentId: "0" }
    ) {
      edges {
        node {
          label
          label_noTx: label
          databaseId
          path
          url
          NoTranslate {
            noTx
          }
          connectedNode {
            node {
              databaseId
            }
          }
          childItems {
            edges {
              node {
                databaseId
                label
                label_noTx: label
                path
                url
                NoTranslate {
                  noTx
                }
                connectedNode {
                  node {
                    databaseId
                  }
                }
                childItems {
                  edges {
                    node {
                      databaseId
                      label
                      label_noTx: label
                      path
                      url
                      NoTranslate {
                        noTx
                      }
                      connectedNode {
                        node {
                          databaseId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    iconNav: menuItems(
      first: 100
      where: { location: MINI_NAVIGATION, language: $language, parentId: "0" }
    ) {
      edges {
        node {
          label
          databaseId
          path
          iconSelect {
            iconSelect
          }
          menu {
            node {
              locations
            }
          }
        }
      }
    }
    languageNavStyles: menu(id: "Language Menu", idType: NAME) {
      name
      languageSelector {
        languageNavAccentColour
        languageNavTextColour
      }
    }
    footerNav: menuItems(
      first: 100
      where: { location: FOOTER_NAVIGATION, language: $language, parentId: "0" }
    ) {
      edges {
        node {
          label
          databaseId
          path
          menu {
            node {
              locations
              count
            }
          }
        }
      }
    }
  }
`;

export const getAllMenusQuery = gql`
  query allWPMenus($language: String) {
    ...NavFragment
  }
  ${NavFragment}
`;

export const getAllWPPagesQuery = gql`
  query wordpressPages {
    pages(last: 50) {
      nodes {
        uri
        title
      }
    }
  }
`;

const twoColumnIconListSettings = `
  twoColumnIconColour
  twoColumnLabelColour
`;

const tabbedTableDataSettings = `
  activeTabAccentColour
  activeTabTextColour
  tableAltRowBackgroundColour
  tableAltRowTextColour
  tableHeaderBackgroundColour
  tableHeaderTextColour
  tableRowBackgroundColour
  tableRowTextColour
`;

const galleryWallSettings = `
  containerMaxWidth
  galleryWallCaptionColour
  galleryWallBackgroundColour
  galleryWallBackgroundOpacity
  galleryWallControlsBackgroundColour
  galleryWallControlsIconColour
`;

const imageGallerySettings = `
galleryTilesBackgroundColour
galleryTilesBackgroundOpacity
galleryTilesControlsBackgroundColour
galleryTilesControlsCaptionColour
galleryTilesControlsIconColour
hideImageCount
`;

const googleMapSettings = `
  layout
  contentAlignment
  buttonStyle
  enableSatelliteView
`;

const htmlMenuSettings = `
  tabBackgroundColour
  tabTextColour
  tabUnderlineColour
  activeTabBackgroundColour
  activeTabTextColour
  activeTabUnderlineColour
  menuItemLine1Colour
  menuItemLine2Colour
  titleWeight
  subtitleWeight
  line1Weight
  line2Weight
  priceWeight
  disclaimerWeight
  menuItemsLine1
  menuItemsLine2
  menuItemsPrice
  menuItemsDisclaimer
  sectionDisclaimerText
  sectionDisclaimerTitle
  sectionTitle
  sectionSubtitle
  tabTitle
`;

const keyInfoGridSettings = `
  containerMaxWidth
  gridContentColor
  gridSubtitleColor
  gridTitleColor
  columnBoxBackgroundColor
  columnBoxTextColor
  columnBoxIconColor
  columnTitleColor
  gradientPosition
  backgroundGradientOpacity
  backgroundGradientColor
  columnBoxIconAlignment
  contentBackgroundOpacity
`;

const hotelPoliciesSettings = `
  iconIndicatorColour
  iconIndicatorColourExpanded
  openPanelBackgroundColour
  borderColour
`;

const mediaAndCopySettings = `
  firstItemAlignment
  altRowContentBackgroundColor
  altRowContentBackgroundSize
  altRowContentBackgroundRepeat
  altRowContentBackgroundPosition
  altRowContentBackgroundImage {
    sourceUrl
  }
  titleLogoiconBottomMargin
  altRowSubtitleColor
  altRowTextAlignment
  altRowTextColor
  altRowTitleColor
  mediaAndCopyPadding
  mediaAndCopyRowPadding
  uiBackgroundColour
  uiControlsColour
  paginationSeperatorColour
  paginationTextColour
  paginationBackgroundColour
  containerMaxWidth
`;

const mediaAndCopyCarouselSettings = `
  mediaAndCopyCarouselHeaderTitleColour
  mediaAndCopyCarouselHeaderCopyColour
  uiPrimaryColour
  uiSecondaryColour
`;

const twoOneMediaCopySettings = `
  horizontalDirection
  verticalDirection
  smallImageBorderColour
  smallImageBorderWidth
`;

const offersCuratedComponentSettings = `
  additionalDataAccentColor
  arrowDataAccentColor
  tagBackgroundColor
  tagDisabledTextColor
  tagDisabledBackgroundColor
  tileBackgroundColor
  tileTextColor
  tagTextColor
  buttonStyles {
    bookButtonStyle
    termsBookButtonStyle
    termsButtonStyle
  }
  tileTypeSort
`;

const iconGridCorePlusSettings = `
  iconCoreColour
  labelCoreColour
  modalBackgroundColor
  modalTextColor
  modalTitleColor
  modalTitleUnderlineColor
`;

const iconGridSettings = `
  iconBlockColour
  labelBlockColour
`;

const multiColumnTilesWithHoverSettings = `
  nonHoverPadding
  nonHoverBackgroundColor
  hoverBackgroundColor
  imageOverlayGradient
`;

const copyBlockSettings = `
  layoutDirection
  padding
`;

const hotelTitleSettings = `
  layoutDirection
  image {
    altText
    sourceUrl
  }
`;

const fullWidthMediaComponentSettings = `
  contentBoxBackgroundOpacity
  contentBoxHorizontal
  contentBoxVertical
  contentBoxPadding
  gradientPosition
  gradientToggle
  uiPrimaryColour
  uiSecondaryColour
`;

const roomsComponentSettings = `
  rateFromColor
  rateFigureColor
  rateErrorColor
  rateCurrencyColor
  rateBackgroundColor
  modalAccentColor
  modalBackgroundColor
  modalIconColor
  modalTextColor
  paginationTextColor
  paginationSeperatorColor
  paginationBackgroundColor
  filterTitleColor
  filterRuleColor
  filterResultsColor
  filterBackgroundColor
  controlsIconColor
  controlsBackgroundColor
  viewDetailsColor
  viewDetailsTextColor
  viewDetailsBorderColor
  viewDetailsBackgroundColor
  bookCtaBackgroundColor
  bookCtaBorderColor
  bookCtaTextColor
  disableDropShadow
  feeTransparencyTextInExpandedDropdown
  feeTransparencyDefaultColor
`;

const meetingGroupSettings = `
  iconColor
  buttonStyle
  ctaBackgroundColor
  ctaBackgroundColorHover
  ctaTextColor
  ctaTextColorHover
`;

const meetingGroupOverrides = `
  eEventsUrlOverride
  rfpUrlOverride
  hiltonLinkOverride
`;

const meetingCapacitySettings = `
  statisticBlockTextAlignment
  statisticNumber
  subtitleColor
  textAlignment
  textColor
  titleAlignment
  titleColor
  separatorColor
  statLabelNumber
  contentBoxVertical
  contentBoxHorizontal
  dimensionColor
`;

const accordionSettings = `
  iconIndicatorColour
  iconIndicatorColourExpanded
  openPanelBackgroundColour
  borderColour
`;

const buttonClone = `
  buttons {
    buttonStyle
    link {
      target
      title
      url
    }
  }
`;

const clonedCropSettings = `
  enableCropping
  orientation
  cropType
  cropHeight
  cropWidth
  xPosition
  xPositionAdvanced
  yPosition
  yPositionAdvanced
  autoPosition
`;

const meetingCapacityCharts = `
  tableHeadings {
    backgroundColour
    fieldGroupName
    textColour
  }
  tableBody {
    rowTextColour2
    rowTextColour
    rowBackgroundColour
    fieldGroupName
    alternatingRowTextColour2
    alternatingRowTextColour
    alternatingRowBackgroundColour
  }
  unitTypeSwitch {
    toggleSwitchColour
    toggleOutlineColour
    toggleBackgroundColour
    textColourOfUnitTypeLabel
    textColourOfMeasurementType
    fieldGroupName
  }
`;

const eventsCalendarSettings = `
  paddingTop {
    fieldGroupName
    paddingTopDesktop
    paddingTopMobile
    paddingTopTablet
  }
  paddingBottom {
    fieldGroupName
    paddingBottomDesktop
    paddingBottomMobile
    paddingBottomTablet
  }
  componentAndContentBackgroundColors {
    calendarComponentBgColor
    calendarDatePickerBgColor
    calendarDesktopGridBgColor
    fieldGroupName
  }
  calendarUiSettings {
    additionalCalendarButtons {
      backgroundColor
      backgroundColorOnHover
      borderColor
      borderColorOnHover
      fieldGroupName
      textColor
      textColorOnHover
    }
    regularEventButtons {
      backgroundColor
      backgroundColorOnHover
      borderColor
      borderColorOnHover
      fieldGroupName
      textColor
      textColorOnHover
    }
    specialEventButtons {
      backgroundColor
      backgroundColorOnHover
      borderColor
      borderColorOnHover
      fieldGroupName
      textColor
      textColorOnHover
    }
    usesRegularEventButtonColors
    selectedDatepickerDateText
    selectedDatePickerDateRangeBg
    selectedDatePickerDateBg
    filterCheckboxColor
    fieldGroupName
    datePickerResetButtonText
    datePickerResetButtonBg
    dailyEventsSectionBgColor
    baseTextColor
    baseIconColor
    additionalTextFilterColor
  }
`;

const MediaBlockCropFrag = (strings: TemplateStringsArray) => `
  image${strings[0]} {
    fieldGroupName
    imageCrop11AddCrop {
      autoPosition
      cropHeight
      cropType
      cropWidth
      enableCropping
      fieldGroupName
      xPosition
      xPositionAdvanced
      yPositionAdvanced
      yPosition
    }
    imageCrop12AddCrop {
      autoPosition
      cropHeight
      cropType
      cropWidth
      enableCropping
      fieldGroupName
      xPosition
      xPositionAdvanced
      yPosition
      yPositionAdvanced
    }
    imageCrop21AddCrop {
      autoPosition
      cropHeight
      cropType
      cropWidth
      enableCropping
      fieldGroupName
      xPosition
      xPositionAdvanced
      yPosition
      yPositionAdvanced
    }    
  }
`;

const MediaBlocksFrag = `
  mediaBlockLayouts {
    mediaLayout {
      ... on Media_block_Mediablocklayouts_MediaLayout_SingleImage {
        fieldGroupName
        image {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`CropSingle`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_TwoImageHoz {
        fieldGroupName
        imageTop {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopCrop`}
        imageBottom {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`BottomCrop`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_TwoImageVert {
        fieldGroupName
        imageLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`LeftCrop`}
        imageRight {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`RightCrop`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageOneTopTwoBottom {
        fieldGroupName
        imageTop {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopCrop`}
        imageBottomLeft {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`BottomLeftCrop`}
        imageBottomRight {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`BottomRightCrop`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageTwoTopOneBottom {
        fieldGroupName
        imageBottom {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomCrop`}
        imageTopLeft {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`TopLeftCrop`}
        imageTopRight {
          sourceUrl
          altText
        }       
        ${MediaBlockCropFrag`TopRightCrop`} 
      }  
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageOneLeftAndTwoRight {
        fieldGroupName
        imageLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`LeftCrop`}
        imageTopRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopRightCrop`}       
        imageBottomRight {
          sourceUrl
          altText
        } 
        ${MediaBlockCropFrag`BottomRightCrop`}        
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageTwoLeftAndOneRight {
        fieldGroupName
        imageTopLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopLeftCrop`}
        imageBottomLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomLeftCrop`}        
        imageRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`RightCrop`}       
      } 
      ... on Media_block_Mediablocklayouts_MediaLayout_FourImage {
        fieldGroupName
        imageTopLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopLeftCrop`}       
        imageBottomLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomLeftCrop`}               
        imageTopRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopRightCrop`}               
        imageBottomRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomRightCrop`}              
      }                   
      ... on Media_block_Mediablocklayouts_MediaLayout_Video {
        fieldGroupName
        videoUrl
      }
    }
  }
`;

const gallerySliderComponentSettings = `
  imageCounterTextColour
  imageCounterBackgroundColour
  carouselControlsArrowColour
  carouselControlsBackgroundColour
`;

const informationBarComponentSettings = `
borderBottomColor
borderTopColor
contactBackgroundColor
contactBorderColor
iconColor
dividerColor
`;

const videoMediaCopyComponentSettings = `
  horizontalDirection
`;

const multiColumnListComponentSettings = `
  dividerColor
  listItemColor
  columnHeadingColor
`;

const WPThemeSettingsFragment = gql`
  fragment WPSettings on RootQuery {
    allSettings {
      generalSettingsTitle
      generalSettingsDescription
    }

    themeSettings {
      SettingsThemeSettings {
        brandTheme
        customEventCategories {
          customCategoryNames
          fieldGroupName
        }
      }
    }

    propertyInfo {
      propertyInfo {
        liveUrl
        brand
        ctyhocn
        faxNumber
        openingHours
        phoneNumber
        address {
          addressCountry
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
        geo {
          latitude
          longitude
        }
      }
    }

    socialLink {
      pageSlug
      pageTitle
      socialLinks {
        fieldGroupName
        facebookLink
        instagramLink
        twitterLink
        linkedinLink
      }
    }

    headerSettings {
      pageTitle
      SettingsHeaderSettings {
        fieldGroupName
        backgroundColour
        backgroundColourActive
        backgroundColourGradient
        textColour
        textColourActive
        bookingWidgetButtonStyles {
          fieldGroupName
          buttonStyle
          backgroundColour
          backgroundColourHover
          textCase
          textColour
          textColourHover
          borderColour
          borderColourHover
          hideBookingWidget
        }
        iconNavStyles {
          fieldGroupName
          iconColour
          iconColourActive
          separatorColour
          textColour
          textColourActive
          underline
        }
        mainNavStyles {
          fieldGroupName
          fontCase
          fontSize
          fontWeight
          menuItemActiveBackgroundColour
          menuItemActiveState
          menuItemActiveTextColour
          menuItemHoverColour
          menuItemPadding
          menuItemUnderlineColour
          menuItemUnderlineToggle
          submenuBackgroundColour
        }
        honorsSignInStyles {
          backgroundColour
          menuLinkBackgroundHighlightColour
          menuLinkTextHighlightColour
          textColour
        }
        languageSwitchButtonStyles {
          backgroundColour
          backgroundColourHover
          textCase
          textColour
          textColourHover
        }
        languageSwitchDropdownStyles {
          backgroundColour
          backgroundColourHover
          dividerColour
          textColour
          textColourHover
        }          
        languageSwitchTitleStyles {
          textCase
          textColour
          textColourActive
          textColourActiveHover
          textColourHover
        }
        languageSwitchButtonStylesActive {
          backgroundColour
          backgroundColourHover
          textCase
          textColour
          textColourHover
        }
      }
    }
    logoSettings {
      SettingsLogoSettings {
        brandLogoClasses
        brandLogoClassesFooter
        brandLogoColourActive
        brandLogoColourFooter
        brandLogoColourInactive
        hotelLogo {
          altText
          sourceUrl
        }
        brandLogo {
          altText
          sourceUrl
        }
        hotelLogoClasses
        hotelLogoClassesFooter
        hotelLogoColourActive
        hotelLogoColourFooter
        hotelLogoColourInactive
        additionalLogos {
          margin
          maxWidth
          tailwindClasses
          image {
            altText
            sourceUrl
          }
        }
      }
    }
    translationSettings {
      SettingsTranslations {
        languageSelectorOverrides {
          languageCode
          reservationsOnly
          urlOverride
        }
      }
    }
    buttonSettings {
      buttonsSettings {
        buttonStyles {
          backgroundColour
          backgroundColourHover
          borderColour
          borderColourHover
          borderRadius
          buttonType
          fieldGroupName
          fontFamily
          hoverStyle
          textColour
          textColourHover
          textTransform
          buttonHeight
          buttonPadding
          fontSize
          fontWeight
          pageRestrictions
          lineHeight
        }
        waldorfButtonStyles {
          buttonType
          textColour
          textColourHover
          underlineColour
          underlineColourHover
          pageRestrictions
        }
      }
    }
    footerSettings {
      SettingsFooterSettings {
        footer {
          backgroundImage {
            sourceUrl
          }
          backgroundColour
          disclaimerText
          textColour
          dividerColour
        }
        breadcrumbs {
          activeTextColour
          backgroundColour
          textColour
          delimiter
          delimiterColour
          delimiterPadding
        }
        copyright {
          backgroundColour
          enable
          textColour
        }
      }
    }
    roomSettings {
      pageTitle
      SettingsRoomSettings {
        roomFilters1Title
        roomFilters2Title
      }
    }
    alertBarSettings {
      alertBarSettings {
        backgroundColor
        linkColor
        textColor
      }
    }
    translationSettings {
      SettingsTranslations {
        translations {
          translationString
        }
      }
    }
    themeSettings {
      acfVersion
    }
    interactiveMapSettings {
      interactiveMapSettings {
        mapCategories {
          categoryName
          iconList
        }
      }
    }

    shopFormSettings {
      pageSlug
      pageTitle
      shopFormSettings {
        dateNumberAndRoomIconColor
        fieldGroupName
        mainTextColor
        oscBookButtonBackgroundColor
        oscBookButtonBackgroundColorOnHover
        oscBookButtonBorderColor
        oscBookButtonBorderColorOnHover
        oscBookButtonTextColor
        oscBookButtonTextColorOnHover
        searchAndFilterIcons
        searchButtonBackgroundColor
        searchButtonBackgroundColorOnHover
        searchButtonBorderColor
        searchButtonBorderColorOnHover
        searchButtonTextColor
        searchButtonTextColorOnHover

        selectedBookDatePickerDateBackground
        selectedBookDatePickerDateText
        selectedBookPickerDateRangeBackground
        selectedBookPickerDateRangeText
        shopFormBgColor

        bookButtonStyleFromGlobal {
          fieldGroupName
          shopFormBookGlobalButtonStyleButtonStyle
        }
        searchButtonStyleFromGlobal {
          fieldGroupName
          globalButtonStyleSettingButtonStyle
        }
      }
    }

    componentStyles {
      globalComponentSettings {
        globalComponentSettings {
          addressBarSettings {
            fieldGroupName
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            subtitleColor
            textAlignment
            textColor
            titleColor
            borderTopColour
            borderBottomColour
            seperatorColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
          }
          mastheadComponentSettings {
            titleColor
            textColor
            textAlignment
            subtitleColor
            overlayYPosition
            overlayXPosition
            overlayMobilePosition
            overlayBackgroundOpacity
            overlayBackgroundColour
            mastheadHeight
            mastheadGradient
            videoCtaBackgroundColor
            videoCtaBorderColor
            videoCtaMuteColor
            videoCtaPlaypauseColor
            videoCtaUnmuteColor
            videoMastheadCtaPosition
            videoCtaShadow
            contentBackgroundPosition
            logoXPosition
            logoYPosition
            heroCarouselArrowColour
            heroCarouselBackgroundColour
            heroCarouselTextColour
            heroCarouselCounterColour
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            mastheadSpacer
            mastheadSpacerColour
          }
          halfAndHalfComponentSettings {
            fieldGroupName
            accordionBorderColor
            accordionIconColor
            accordionIconColorActive
            accordionPanelBackgroundColor
            accordionPanelLabelColor
            accordionRowLabelColor
            accordionRowTextColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${mediaAndCopySettings}
          }
          singleItemSliderComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${mediaAndCopyCarouselSettings}
          }
          fullWidthImageComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${fullWidthMediaComponentSettings}
          }
          introductionComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${copyBlockSettings}
          }
          hotelTitleComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${hotelTitleSettings}
          }
          locationGoogleMapComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${googleMapSettings}
          }

          imageRevealComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd

            ${multiColumnTilesWithHoverSettings}
          }
          twoColumnIconListSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${twoColumnIconListSettings}
          }
          twoOneMediaCopySettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${twoOneMediaCopySettings}
          }
          twoTwoMediaCopySettings {
            fieldGroupName
            horizontalDirection
            #ClonedComponentSettingsStart
              componentBackgroundColor
              componentBackgroundSize
              componentBackgroundRepeat
              componentBackgroundPosition
              contentBackgroundColor
              contentBackgroundSize
              contentBackgroundRepeat
              contentBackgroundPosition
              contentBackgroundColorLayerTwo
              subtitleColor
              textAlignment
              textColor
              titleColor
              componentBackgroundImage {
                sourceUrl
              }
              contentBackgroundImage {
                sourceUrl
              }
              paddingTop {
                paddingTopDesktop
                paddingTopTablet
                paddingTopMobile
              }
              paddingBottom {
                paddingBottomDesktop
                paddingBottomTablet
                paddingBottomMobile
              }
              showHide
              hideAnimations
              animationDirection
              hideParallax
              sliderAnimations
              zoomOnHover
              containerMaxWidth
              headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          offersCuratedComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${offersCuratedComponentSettings}
          }
          multiItemCarouselComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            headerTitleColour
            headerCopyColour
            uiPrimaryColour
            uiSecondaryColour
            titleSize
            tileTitleBackgroundColour
            headingStyle
            tileTitleAlignment
            tileTitleLayout
          }
          socialMediaFeedSettings {
            fieldGroupName
            socialMediaIconColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          twoColumnCompareComponentSettings {
            fieldGroupName
            showHide
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          multiColumnComponentSettings {
            fieldGroupName
            showHide
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            filterActiveTextColour
            filterBackgroundColour
            filterTextColour
            uiBackgroundColour
            uiControlsColour
            headerTitleColour
            headerCopyColour
          }
          spacerComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          retailFlyoutComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }
          retailRailComponentSettings {
            componentBackgroundColor
            componentBackgroundPosition
            componentBackgroundRepeat
            componentBackgroundSize
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundColor
            contentBackgroundPosition
            contentBackgroundRepeat
            contentBackgroundSize
            contentBackgroundImage {
              sourceUrl
            }
            textAlignment
            textColor
            titleColor
            closeIconColour
            closeTextColour
            showHide
          }

          galleryWallComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


            ${galleryWallSettings}
          }

          imageGalleryComponentSettings {
            fieldGroupName
            ${imageGallerySettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          mediaAndCopyOverlayComponentSettings {
            fieldGroupName
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            subtitleColor
            textAlignment
            textColor
            titleColor
            mediaAndCopyPadding
            horizontalDirection
            zoomOnHover
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            containerMaxWidth
          }

          iconBlockComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${iconGridSettings}
          }

          htmlMenuSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${htmlMenuSettings}
          }

          iconBlockCorePlusComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${iconGridCorePlusSettings}
          }

          keyInfoGridSettings {
            fieldGroupName
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            ${keyInfoGridSettings}
          }

          roomsComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${roomsComponentSettings}
          }

          hotelPoliciesComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${hotelPoliciesSettings}
          }

          resortMapV2Settings {
            fieldGroupName
            showHide
            mapControlsColor
            mapControlsBackgroundColor
            tabBackgroundColor
            tabTextColor
            selectedTabBackgroundColor
            selectedTabTextColor
            mobileFilterDropdownBackgroundColor
            mapControlsPlacement
            mobileFilterDropdownTextColor
            pinAccentColor
            pinColor
            subtitleColor
            textAlignment
            titleColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          comparisonTableSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          onPageMenuSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


            ${tabbedTableDataSettings}
          }

          fourStaggeredTilesComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }

          restaurantsComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd hoursSeperatorColour
            hideTileBorder
            cuisineTypeTextColour
            cuisineTypeIconColour
            tileBorderColour
            topSectionBackgroundColour
          }

          accordionComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${accordionSettings}
          }

          meetingCapacitySummaryComponentSettings {
            fieldGroupName
            ${meetingCapacitySettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }

          meetingGroupComponentSettings {
            fieldGroupName
            ${meetingGroupSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }

          meetingCapacityChartsSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${meetingCapacityCharts}
          }

          eventsCalendarSettings {
            fieldGroupName
            showHide
            ${eventsCalendarSettings}
          }

          gallerySliderComponentSettings {
            fieldGroupName
            showHide
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${gallerySliderComponentSettings}
          }

          informationBarComponentSettings {
            fieldGroupName
            showHide
            ${informationBarComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          videoMediaCopySettings {
            fieldGroupName
            showHide
            ${videoMediaCopyComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          
          multiColumnListComponentSettings {
            ${multiColumnListComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
        }
      }
    }
    modalSettings {
      modalSettings {
        modalBackgroundOpacity
        modalBackgroundColour
        modalCloseButtonBackgroundColour
        modalCloseButtonIconColour
        contentBackgroundColour
        hideBoxShadow
      }
    }
  }
`;

export const getRecurringEventsQuery = gql`
  query recurringEvents {
    recurringevents(first: 50) {
      edges {
        node {
          title
          id
          databaseId
          slug
          RegularlyScheduledEvents {
            attractionEventLocation
            category
            description
            distanceFromResort
            fieldGroupName
            locationAtResort
            onSiteOrOffSite
            eventDisplayEndDate
            eventDisplayStartDate

            allDays {
              allDay
              endTime
              startTime
              heldDaily
              fieldGroupName
            }

            fridaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            image {
              sourceUrl(size: LARGE)
              altText
              title
              translated {
                id
              }
              databaseId
              locale {
                locale
                id
              }
            }
            buttons {
              buttonStyle
              link {
                target
                title
                url
              }
            }
            mondaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            saturdaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            sundaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            thursdaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            tuesdaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            wednesdaySchedule {
              startTime
              scheduled
              fieldGroupName
              endTime
              allDay
            }
          }
        }
      }
    }
    calendars(first: 50) {
      edges {
        node {
          title
          calendarId
          calendarOfEvents {
            attractionEventLocation
            category
            description
            distanceFromResort
            endDate
            endTime
            fieldGroupName
            locationAtResort
            onSiteOrOffSite
            startDate
            startTime
            image {
              altText
              sourceUrl(size: LARGE)
              translated {
                id
              }
              databaseId
              locale {
                locale
                id
              }
            }
            buttons {
              link {
                target
                title
                url
              }
              buttonStyle
            }
          }
        }
      }
    }
  }
`;

export const getWPThemeSettingsQuery = gql`
  query wordpressThemeSettings {
    ...WPSettings
  }
  ${WPThemeSettingsFragment}
`;

export const getLanguagePageIDsQuery = gql`
  query wordpressPageTranslationInfo(
    $id: ID!
    $idType: PageIdType!
    $asPreview: Boolean
  ) {
    page(id: $id, idType: $idType, asPreview: $asPreview) {
      databaseId
      translations {
        locale
        id
      }
    }
  }
`;

const PageFieldsFrag = gql`
fragment PageFields on Page {
  slug
  title
  pageId
  isFrontPage
  parent {
    node {
      slug
      ... on Page {
        title
        parent {
          node {
            slug
            ... on Page {
              title
            }
          }
        }
      }
    }
  }
  metaDataFields {
    fieldGroupName
    metaTitle
    metaDescription
    adobeDigitaldataOverwriteList
    adobeDigitaldataOverwriteText
    adobeDigitaldataOverwriteBrand
    adobeDigitaldataOverwriteCtyhocn
    adobeDigitaldataPageDetail1
    adobeDigitaldataPageDetail2
    adobeDigitaldataPageDetail3
    openGraphDescription
    openGraphTitle
    openGraphImage {
      sourceUrl
    }
    ctyhocnOverride
  }
  acfFlexible {
    fieldGroupName
    flexibleComponents {
      __typename
      ... on Page_Acfflexible_FlexibleComponents_Masthead {
        fieldGroupName
        subtitle
        subtitleSize
        title
        titleSize
        image {
          altText
          sourceUrl
        }
        imagePositioning
        imageCarouselChoice
        imageCarousel {
          altText
          sourceUrl
        }
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        logoImage {
          altText
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        logoOverlayColour
        logoOverlayOpacity
        logoWidth
        logoHeight
        videoChoice
        videoUrl {
          mediaItemUrl
        }
        videoCtaPlay
        videoCtaPause
        videoMute
        videoQualityOverride
        videoQualitySlider
        bookingWidgetChoice
        bookingWidgetPosition
        bookingWidgetMobile
        mobileCroppingAutoPosition
        mobileCroppingCropHeight
        mobileCroppingCropWidth
        mobileCroppingXPosition
        mobileCroppingXPositionAdvanced
        mobileCroppingYPosition
        mobileCroppingYPositionAdvanced
        ${clonedCropSettings}
        mastheadComponentSettings {
          fieldGroupName
          mastheadHeight
          overlayBackgroundColour
          overlayBackgroundOpacity
          overlayXPosition
          overlayYPosition
          mastheadGradient
          overlayMobilePosition
          titleColor
          subtitleColor
          videoCtaBackgroundColor
          videoCtaBorderColor
          videoCtaMuteColor
          videoCtaPlaypauseColor
          videoCtaUnmuteColor
          videoMastheadCtaPosition
          videoCtaShadow
          contentBackgroundPosition
          logoXPosition
          logoYPosition
          heroCarouselArrowColour
          heroCarouselBackgroundColour
          heroCarouselTextColour
          heroCarouselCounterColour
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          mastheadSpacer
          mastheadSpacerColour
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_AddressBar {
        addressLine1
        addressLine2
        checkInText
        arrivalTitleMobile
        arrivalTitle
        addressUrl
        addressTitleMobile
        addressTitle
        addressText
        checkInTitle
        checkOutText
        checkOutTitle
        fieldGroupName
        line2Icon
        line2Link
        line2Text
        line3Icon
        line3Link
        line3Text
        telephoneIcon
        telephoneLine2
        telephoneLine1
        telephoneLink
        telephoneNumber
        telephoneTitle
        telephoneTitleMobile
        tripAdvisorTitle
        tripAdvisorTitleMobile
        tripAdvisorToggle
        hideArrivalDeparture
        hideAddress
        hideContactInfo
        addressBarSettings {
          borderBottomColour
          borderTopColour
          seperatorColor
          componentBackgroundColor
          componentBackgroundImage {
            sourceUrl
          }
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundImage {
            sourceUrl
          }
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          fieldGroupName
          subtitleColor
          textAlignment
          textColor
          titleColor
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_OffersCurated {
        fieldGroupName
        title
        copy
        hideList
        offersCuratedComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${offersCuratedComponentSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_TwoColumnCompare {
        repeater {
          copy
          title
          title_noTx: title
          subtitle
          subtitle_noTx: subtitle
          backgroundPosition
          backgroundRepeat
          backgroundSize
          backgroundImage {
            altText
            sourceUrl
          }
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
          image {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
          logo {
            altText
            sourceUrl
            mediaDetails {
              height
              width
            }
          }
        }
        twoColumnCompareComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_ResortMapV2 {
        fieldGroupName
        mapCategoryDescription
        mapCategoryMobileButtonPosition
        mapCategoryTitle
        mapOverlayPosition
        mapImage {
          sourceUrl
          altText
          mediaDetails {
            height
            width
          }
        }
        mapPins {
          pinTitle
          pinX
          pinY
          pinCategory
          pinModal
          pinModalDescription
          pinModalTitle
          pinModalImage {
            sourceUrl(size: _540X390)
            altText
            mediaDetails {
              height
              width
            }
          }
          modalLinkText
          modalLink2 {
            url
            target
          }
          modalLink {
            ... on Page {
              id
              slug
              link
            }
          }
        }
        resortMapV2Settings {
          fieldGroupName
          showHide
          mapControlsColor
          mapControlsBackgroundColor
          tabBackgroundColor
          tabTextColor
          selectedTabBackgroundColor
          selectedTabTextColor
          mobileFilterDropdownBackgroundColor
          mapControlsPlacement
          mobileFilterDropdownTextColor
          pinAccentColor
          pinColor
          subtitleColor
          textAlignment
          titleColor
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_SocialMediaFeed {
        feedId
        fieldGroupName
        socialLinkTitle
        socialLinkUrl
        socialTitle
        socialMediaFeedSettings {
          fieldGroupName
          socialMediaIconColor
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_Introduction {
        copy
        fieldGroupName
        subtitle
        subtitle_noTx: subtitle
        title
        title_noTx: title
        combinedTitleSubtitle
        titleSize
        subtitleSize
        pullFromCorePlus
        pullQuote
        imageSize
        image {
          sourceUrl
          altText
        }
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        introductionComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${copyBlockSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_HotelTitle {
        fieldGroupName
        hotelTitleComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${hotelTitleSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_ImageReveal {
        fieldGroupName
        header
        columnCount
        repeater {
          copy
          fieldGroupName
          title
          title_noTx: title
          image {
            altText
            sourceUrl
          }
          ${clonedCropSettings}
          button {
            target
            title
            url
          }
          showHide
        }
        imageRevealComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${multiColumnTilesWithHoverSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_ImageGallery {
        fieldGroupName
        imageGallery {
          galleryTitle
          galleryThumbnail {
            altText
            sourceUrl(size: _540X390)
          }
          galleryImages {
            altText
            caption
            sourceUrl(size: _1920X1080)
            mediaDetails {
              height
              width
            }
          }
        }
        imageGalleryComponentSettings {
          fieldGroupName
          showHide
          ${imageGallerySettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_FullWidthImageContent {
        copy
        fieldGroupName
        styles
        title
        title_noTx: title
        subtitle
        subtitle_noTx: subtitle
        image {
          altText
          sourceUrl
        }
        mediaBlock {
          ... on Media_block {
            id
            ${MediaBlocksFrag}
          }
        }
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        enableCarousel
        enableCropping
        cropType
        cropHeight
        cropWidth
        xPosition
        xPositionAdvanced
        yPosition
        yPositionAdvanced
        autoPosition
        carouselItems {
          styles
          title
          title_noTx: title
          subtitle
          subtitle_noTx: subtitle
          image {
            altText
            sourceUrl
          }
          copy
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          showHide
        }
        fullWidthImageComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${fullWidthMediaComponentSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy {
        fieldGroupName
        reverseDirection
        column1 {
          title
          title_noTx: title
          copy
          image {
            altText
            sourceUrl
          }
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        column2 {
          title
          title_noTx: title
          copy
          image {
            altText
            sourceUrl
          }
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        imageColumn1AutoPosition
        imageColumn1CropHeight
        imageColumn1CropType
        imageColumn1CropWidth
        imageColumn1EnableCropping
        imageColumn1XPosition
        imageColumn1XPositionAdvanced
        imageColumn1YPosition
        imageColumn1YPositionAdvanced
        imageColumn2AutoPosition
        imageColumn2CropHeight
        imageColumn2CropType
        imageColumn2CropWidth
        imageColumn2EnableCropping
        imageColumn2XPosition
        imageColumn2XPositionAdvanced
        imageColumn2YPosition
        imageColumn2YPositionAdvanced
        twoTwoMediaCopySettings {
          fieldGroupName
          horizontalDirection
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy {
        fieldGroupName
        subtitle
        subtitle_noTx: subtitle
        title
        title_noTx: title
        copy
        largeImage {
          altText
          sourceUrl
        }
        smallImage {
          altText
          sourceUrl
        }
        imageLargeCropHeight
        imageLargeCropWidth
        imageLargeEnableCropping
        imageLargeCropType
        imageLargeXPosition
        imageLargeYPosition
        imageLargeXPositionAdvanced
        imageLargeYPositionAdvanced
        imageSmallCropHeight
        imageSmallCropWidth
        imageSmallEnableCropping
        imageSmallCropType
        imageSmallXPosition
        imageSmallYPosition
        imageSmallXPositionAdvanced
        imageSmallYPositionAdvanced
        ${buttonClone}
        twoOneMediaCopySettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${twoOneMediaCopySettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_SingleItemSlider {
        fieldGroupName
        imageSizes
        layoutDirection
        layoutMode
        offsetImage
        header
        headingValue
        copy
        ${buttonClone}
        repeater {
          copy
          fieldGroupName
          title
          title_noTx: title
          image {
            altText
            sourceUrl
          }
          ${clonedCropSettings}
          imagePositioning
          tableData {
            text
            title
          }
          singleLink {
            target
            title
            url
          }
          ${buttonClone}
          showHide
        }
        singleItemSliderComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${mediaAndCopyCarouselSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_FourStaggeredTiles {
        fieldGroupName
        repeater {
          copy
          tileSize
          title
          title_noTx: title
          image {
            altText
            sourceUrl
          }
          ${clonedCropSettings}
          ${buttonClone}
        }
        fourStaggeredTilesComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_HalfAndHalf {
        fieldGroupName
        repeater {
          accordionPanel {
            panelLabel
            panelRepeater {
              fieldGroupName
                rowLabel
                rowText
              }
          }
          copy
          titleIcon {
            altText
            sourceUrl
          }
          title
          image {
            altText
            sourceUrl
          }
          imageSize
          imagePositioning
          layoutMode
          mediaBlock {
            ... on Media_block {
              id
              ${MediaBlocksFrag}
            }
          }          
          mediaCopyIconList: iconList {
            iconColor
            iconList
            text
          }
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          tableData {
            title
            text
            fieldGroupName
          }
            
          ${clonedCropSettings}
          imageGallery {
            sourceUrl
            altText
          }
        }
        halfAndHalfComponentSettings {
          fieldGroupName
          showHide
          accordionBorderColor
          accordionIconColor
          accordionIconColorActive
          accordionPanelBackgroundColor
          accordionPanelLabelColor
          accordionRowLabelColor
          accordionRowTextColor
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${mediaAndCopySettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_OnPageMenus {
        fieldGroupName
        header
        menuRepeater {
          fieldGroupName
          menuName
          gallery {
            altText
            sourceUrl
            mediaDetails {
              height
              width
            }
          }
          contentSelection
          tableHeadingSpan {
            text
            columnSpan
          }
          menuColumn {
            fieldGroupName
            menuHeader
            menuItems
          }
          sectionTableColumns {
            column1
            column2
            column3
            column4
            column5
            column6
            column7
            column8
          }
        }
        onPageMenuSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${tabbedTableDataSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_IconBlock {
        fieldGroupName
        header
        headerCopy
        iconColumnCount
        iconSize
        iconRepeater {
          fieldGroupName
          iconList
          iconLabel
          iconColor
          labelColor
        }
        iconBlockComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${iconGridSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_MultiItemCarousel {
        fieldGroupName
        copy
        columnCount
        header
        headingValue
        headingOnActiveState
        titleAlignment
        tileLayout
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        tileSizes
        repeater {
          copy
          fieldGroupName
          title
          title_noTx: title
          tableData {
            title
            text
          }
          image {
            sourceUrl
            altText
          }
          logo {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
          tileBackgroundLayer2
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          showHide
        }
        multiItemCarouselComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          headerTitleColour
          headerCopyColour
          uiPrimaryColour
          uiSecondaryColour
          titleSize
          tileTitleBackgroundColour
          headingStyle
          tileTitleAlignment
          tileTitleLayout
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_RoomTypes {
        fieldGroupName
        ctyhocn
        showPricing
        hideLeadRates
        hideMaxGuestsFilter
        hideBedsFilter
        hidePriceFilter
        showFindYourBestRoomFilters
        arrivalDate
        displayRoomsFromPosts
        pricingDisclaimer
        sortRoomsBy
        room {
          roomTypeCode
          view
          recommendedFor
          premiumOptions
          balconyDetail
          customView
          customBalcony
          squareFootage
          outdoorFeatures
          roomFeatures
          roomType
          bathroomAmenities
          locations
          image {
            sourceUrl
            altText
          }
          icon {
            altText
            sourceUrl
          }
          iconWidth
          carouselImages {
            image {
              sourceUrl(size: _1920X1080)
              altText
              mediaDetails {
                height
                width
              }
            }
          }
          showCustomFilter1 {
            customFilter1Select
            showCustomFilter1
          }
          showCustomFilter2 {
            customFilter2Select
            showCustomFilter2
          }
          enableUpsell
          upsellModalTitle
          upsellModalTitleHighlight
          upsellRoomCode
          rooms {
            fieldGroupName
            selectedRoomTitle
            selectedRoomTitleHighlight
            upsellRoomTitle
            upsellRoomTitleHighlight
            selectedRoomFeatures {
              item
            }
            selectedRoomDownsellFeatures {
              item
            }
            upsellRoomFeatures {
              item
            }
            upsellRoomAdditionalFeatures {
              item
            }
          }
          balconyIconGroup {
            iconList
          }
          viewIconGroup {
            iconList
          }
          squareFootageIconGroup {
            iconList
          }
        }
        roomsComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${roomsComponentSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_RoomTypesLite {
        fieldGroupName
        ctyhocn
        hideRoomCodes
        showFindYourBestRoomFilters
        roomsComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${roomsComponentSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_LocationGoogleMap {
        fieldGroupName
        title
        title_noTx: title
        address
        addressUrl
        addressOverride
        phone
        email
        hideEmailCta
        emailCtaText
        emailCtaOverride {
          target
          title
          url
        }
        map {
          latitude
          longitude
          zoom
        }
        zoomControl
        locationGoogleMapComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${googleMapSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_HotelAmenities {
        showHotelAmenitiesIcons
        ctyhocn
        header
        buttonStyling
        iconBlockCorePlusComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${iconGridCorePlusSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_KeyInfoGrid {
        columnTitles {
          title1
          title2
          title3
        }
        gridContent
        gridSubtitle
        gridTitle
        repeater {
          title
          contentBackground
          content
          backgroundOpacity
          columnSelect
          iconList
        }
        keyInfoGridSettings {
          fieldGroupName
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          ${keyInfoGridSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_MultiColumn {
        fieldGroupName
        header
        headerCopy
        headingValue
        columnCount
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        imageSize
        tileFilters
        filterCategories {
          slug
          name
        }
        repeater {
          title
          title_noTx: title
          copy
          iconLabel
          iconList
          image {
            altText
            sourceUrl
          }
          images {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
          category {
            name
            slug
          }
          tableData {
            title
            copy
          }
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          showHide
        }
        multiColumnComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          filterActiveTextColour
          filterBackgroundColour
          filterTextColour
          uiBackgroundColour
          uiControlsColour
          headerTitleColour
          headerCopyColour
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_ComparisonTable {
        fieldGroupName
        introduction
        repeater {
          fieldGroupName
          sectionRows {
            fieldGroupName
            rowTitle
            subrows {
              column {
                fieldGroupName
                item
              }
              fieldGroupName
            }
          }
          tableHeading {
            fieldGroupName
            text
            backgroundColour
            headerUrl {
              target
              title
              url
            }
            textColour
          }
        }
        title
        comparisonTableSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_TwoColumnIconList {
        fieldGroupName
        subtitle
        repeater {
          fieldGroupName
          iconList
          iconDescription
          iconText
        }
        title
        twoColumnIconListSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
          ${twoColumnIconListSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_Spacer {
        componentHeight
        group {
          fieldGroupName
          large1024px
          medium768px
          small640px
          extraLarge1280px
          twoXExtraLarge1440px
        }
        fieldGroupName
        spacerComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_RetailFlyout {
        copy
        fieldGroupName
        openByDefault
        pagePosition
        buttonStyle
        title
        link {
          target
          title
          url
        }
        retailFlyoutComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_RetailRail {
        fieldGroupName
        copy
        title
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        retailRailComponentSettings {
          componentBackgroundColor
          componentBackgroundPosition
          componentBackgroundRepeat
          componentBackgroundSize
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundColor
          contentBackgroundPosition
          contentBackgroundRepeat
          contentBackgroundSize
          contentBackgroundImage {
            sourceUrl
          }
          textAlignment
          textColor
          titleColor
          closeIconColour
          closeTextColour
          showHide
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_ImageGalleryWall {
        fieldGroupName
        gallerySubtitle
        columnCount
        imageVerticalAlignment
        repeater {
          title
          selectOrientation
          faceDetection
          portraitModal
          imageHeight
          image {
            sourceUrl
            altText
            mediaDetails {
              height
              width
            }
          }
          fieldGroupName
          description
        }
        galleryTitle
        galleryWallComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${galleryWallSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay {
        title
        title_noTx: title
        layoutMode
        copy
        fieldGroupName
        image {
          mediaDetails {
            height
            width
          }
          sourceUrl
          altText
        }
        mediaBlock {
          ... on Media_block {
            id
            ${MediaBlocksFrag}
          }
        }
        ${clonedCropSettings}
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        mediaAndCopyOverlayComponentSettings {
          fieldGroupName
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          subtitleColor
          textAlignment
          textColor
          titleColor
          mediaAndCopyPadding
          horizontalDirection
          zoomOnHover
          hideAnimations
          animationDirection
          hideParallax
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          containerMaxWidth
        }
      }

      ... on Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar {
        componentSettings {
          backgroundPrimary
          backgroundSecondary
          backgroundTernary
          cellFreeGroup {
            backgroundColor
            cornerFlagColor
            textColor
          }
          cellFullGroup {
            backgroundColor
            cornerFlagColor
            textColor
          }
          cellPartialGroup {
            backgroundColor
            cornerFlagColor
            textColor
          }
          cellSelectedGroup {
            backgroundColor
            cornerFlagColor
            textColor
          }
          headerCopyAlign
          headerCopyColor
          headerTitleAlign
          headerTitleColor
          mainTextColor
          paddingBottom {
            paddingBottomDesktop
            paddingBottomMobile
            paddingBottomTablet
          }
          paddingTop {
            paddingTopDesktop
            paddingTopMobile
            paddingTopTablet
          }
          progressActive
          progressInactive
          radioButtonChecked
          radioButtonDisabled
          radioButtonUnchecked
          subheadingAlign
          subheadingColor
          cellDisabledGroup {
            backgroundColor
            textColor
            cornerFlagColor
          }
          emailAddress
          emailCopy
          emailTitle
          activeBackgroundColor
          activeTextColor
          inactiveBackgroundColor
          inactiveTextColor
        }
        introCopy
        title
      }

      ... on Page_Acfflexible_FlexibleComponents_HotelPolicies {
        fieldGroupName
        subtitle
        title
        copy
        displayHotelAccessibilityLink
        hotelAccessibilityGuideUrl
        hotelPoliciesComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${hotelPoliciesSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_HtmlMenu {
        header
        header_noTx: header
        menu {
          ... on Html_menu {
            id
          }
        }
        tabTypeButtonStyle
        menuRepeater {
          menuSections {
            fieldGroupName
            pricePosition
            sectionBackgroundColour
            sectionBorderColour
            sectionDisclaimer
            sectionDisclaimerLine1
            sectionDisclaimerLine1_noTx: sectionDisclaimerLine1
            sectionDisclaimerLine2
            sectionDisclaimerLine2_noTx: sectionDisclaimerLine2
            sectionItems {
              fieldGroupName
              itemDisclaimer
              itemLine1
              itemLine1_noTx: itemLine1
              itemLine2
              itemLine2_noTx: itemLine2
              itemPrice
              itemPrice_noTx: itemPrice
            }
            sectionLayout
            sectionSubtitle
            sectionSubtitle_noTx: sectionSubtitle
            sectionTitle
            sectionTitle_noTx: sectionTitle
          }
          fieldGroupName
          tabTitle
          tabTitle_noTx: tabTitle
        }
        htmlMenuSettings {
          fieldGroupName
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${htmlMenuSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_Restaurants {
        fieldGroupName
        readMoreLess
        hideList
        restaurants {
          cuisineType
          cuisineTypeSelect
          iconList
          fieldGroupName
          restaurantId
          buttons {
            link {
              target
              title
              url
            }
            buttonStyle
          }
          openingHours {
            hours
            title
          }
          image {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
        }
        restaurantsComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          hoursSeperatorColour
          hideTileBorder
          cuisineTypeTextColour
          cuisineTypeIconColour
          tileBorderColour
          topSectionBackgroundColour
        }
      }

      ... on Page_Acfflexible_FlexibleComponents_MeetingsGroup {
        fieldGroupName
        ${meetingGroupOverrides}
        meetingGroupComponentSettings {
          fieldGroupName
          showHide
          ${meetingGroupSettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }

      ... on Page_Acfflexible_FlexibleComponents_MeetingCapacity {
        fieldGroupName
        heading
        ${buttonClone}
        meetingCapacitySummaryComponentSettings {
          fieldGroupName
          showHide
          ${meetingCapacitySettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }

      ... on Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts {
        fieldGroupName
        title
        subtitle
        copy
        meetingCapacityChartsSettings {
          fieldGroupName
          showHide
          tableHeadings {
            backgroundColour
            fieldGroupName
            textColour
          }
          tableBody {
            rowTextColour2
            rowTextColour
            rowBackgroundColour
            fieldGroupName
            alternatingRowTextColour2
            alternatingRowTextColour
            alternatingRowBackgroundColour
          }
          unitTypeSwitch {
            toggleSwitchColour
            toggleOutlineColour
            toggleBackgroundColour
            textColourOfUnitTypeLabel
            textColourOfMeasurementType
            fieldGroupName
          }
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }

      ... on Page_Acfflexible_FlexibleComponents_Accordion {
        content
        fieldGroupName
        heading
        panel {
          fieldGroupName
          panelLabel
          panelRepeater {
            fieldGroupName
            rowText
            rowLabel
          }
        }
        subtitle
        accordionComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${accordionSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid {
        fieldGroupName
        eventsCalendarComponentSettings {
          fieldGroupName
          eventsCalendarSettings {
            fieldGroupName
            showHide
            ${eventsCalendarSettings}
          }
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_Cvent {
        cventCode
        cventComponentSettings {
          fieldGroupName
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_GallerySlider {
        columnCount
        fieldGroupName
        tileHeight
        tilePadding
        repeater {
          image {
            altText
            sourceUrl
          }
        }
        gallerySliderComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${gallerySliderComponentSettings}
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_InformationBar {
        fieldGroupName
        emailAddress
        hideIcons
        phoneNumber
        bookCta {
          fieldGroupName
          buttonStyle
          link {
            target
            title
            url
          }
        }
        details {
          fieldGroupName
          copy
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        location {
          fieldGroupName
          copy
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        hours {
          fieldGroupName
          copy
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        informationBarComponentSettings {
          fieldGroupName
          showHide
          ${informationBarComponentSettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }

      ... on Page_Acfflexible_FlexibleComponents_VideoMediaCopy {
        copy
        fieldGroupName
        videoMediaUrl
        videoTitle
        heading
        buttonsButtons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        imageLarge
        imageLargeAutoPosition
        imageLargeCropHeight
        imageLargeCropType
        imageSmall
        imageLargeYPositionAdvanced
        imageLargeYPosition
        imageLargeXPositionAdvanced
        imageLargeXPosition
        imageLargeEnableCropping
        imageLargeCropWidth
        imageSmallYPositionAdvanced
        imageSmallYPosition
        imageSmallXPositionAdvanced
        imageSmallXPosition
        imageSmallEnableCropping
        imageSmallCropWidth
        imageSmallCropType
        imageSmallCropHeight
        imageSmallAutoPosition
        largeImage {
          altText
          sourceUrl
        }
        smallImage {
          altText
          sourceUrl
        }
        componentSettingsVideoMediaCopySettings {
          fieldGroupName
          showHide
          ${videoMediaCopyComponentSettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          animationDirection
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_PatchworkGrid {
        repeater {
          horizontalDirection
          title
          copy
          buttons {
          buttonStyle
          fieldGroupName
            link {
              target
              title
              url
            }
          }
          image1 {
            altText
            sourceUrl
            title
          }
          image1Cropping
          image1CroppingAutoPosition
          image1CroppingCropHeight
          image1CroppingCropType
          image1CroppingCropWidth
          image1CroppingEnableCropping
          image1CroppingOrientation
          image1CroppingXPosition
          image1CroppingXPositionAdvanced
          image1CroppingYPosition
          image1CroppingYPositionAdvanced            
          image2 {
            altText
            sourceUrl
            title
          }
          image2Cropping
          image2CroppingAutoPosition
          image2CroppingCropHeight
          image2CroppingCropType
          image2CroppingCropWidth
          image2CroppingEnableCropping
          image2CroppingOrientation
          image2CroppingXPosition
          image2CroppingXPositionAdvanced
          image2CroppingYPosition
          image2CroppingYPositionAdvanced              
          image3 {
            altText
            sourceUrl
            title
          }          
          image3Cropping
          image3CroppingAutoPosition
          image3CroppingCropHeight
          image3CroppingCropType
          image3CroppingCropWidth
          image3CroppingEnableCropping
          image3CroppingOrientation
          image3CroppingXPosition
          image3CroppingXPositionAdvanced
          image3CroppingYPosition
          image3CroppingYPositionAdvanced                                 
        }
          patchworkGridSettings {
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          animationDirection
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
      ... on Page_Acfflexible_FlexibleComponents_MultiColumnList {
          fieldGroupName
          title
          copy
          column {
            columnHeading
            list {
              listItem
            }
          }
          multiColumnListComponentSettings {
            ${multiColumnListComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
        }    
    }
  }
}
`;

const AllWPPages = gql`
  fragment AllWPPages on RootQuery {
    allWPPages: pages(
      last: 50
      where: { orderby: { field: MENU_ORDER, order: DESC } }
    ) {
      nodes {
        uri
        title
        id
        acfFlexible {
          flexibleComponents {
            ... on Page_Acfflexible_FlexibleComponents_Masthead {
              image {
                sourceUrl
              }
              imageCarousel {
                sourceUrl
              }
            }
          }
        }
        slug
        parent {
          node {
            uri
            slug
          }
        }
      }
    }
  }
`;

export const WACQuery = gql`
  query WeddingAvailabilityCalendar($minTimestamp: Float) {
    weddingbookings(where: { minTimestamp: $minTimestamp }, first: 512) {
      edges {
        node {
          id
          title
          weddingCalendar {
            ceremonyTime
            date
            fieldGroupName
            receptionVenue
            status
            timestamp
          }
        }
      }
    }
    themeSettings {
      SettingsThemeSettings {
        venuesTimeSlots {
          timeSlot
        }
        weddingVenues {
          venue
        }
      }
    }
  }
`;

export const RoomsPostQuery = gql`
  query RoomsPosts {
    rooms(last: 100) {
      edges {
        node {
          title
          roomsContent {
            roomTypeCode
            view
            balconyDetail
            customView
            customBalcony
            squareFootage
            outdoorFeatures
            roomFeatures
            roomType
            bathroomAmenities
            locations
            image {
              sourceUrl
              altText
            }
            icon {
              altText
              sourceUrl
            }
            iconWidth
            carouselImages {
              image {
                sourceUrl(size: _1920X1080)
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
            showCustomFilter1 {
              customFilter1Select
              showCustomFilter1
            }
            showCustomFilter2 {
              customFilter2Select
              showCustomFilter2
            }
            balconyIconGroup {
              iconList
            }
            viewIconGroup {
              iconList
            }
            squareFootageIconGroup {
              iconList
            }
          }
        }
      }
    }
  }
`;

export const HTMLMenuQuery = gql`
  query HTMLMenus {
    htmlMenus(last: 100) {
      edges {
        node {
          id
          title
          htmlMenuContent {
            menuRepeater {
              menuSections {
                fieldGroupName
                pricePosition
                sectionBackgroundColour
                sectionBorderColour
                sectionDisclaimer
                sectionDisclaimerLine1
                sectionDisclaimerLine1_noTx: sectionDisclaimerLine1
                sectionDisclaimerLine2
                sectionDisclaimerLine2_noTx: sectionDisclaimerLine2
                sectionItems {
                  fieldGroupName
                  itemDisclaimer
                  itemLine1
                  itemLine1_noTx: itemLine1
                  itemLine2
                  itemLine2_noTx: itemLine2
                  itemPrice
                  itemPrice_noTx: itemPrice
                }
                sectionLayout
                sectionSubtitle
                sectionSubtitle_noTx: sectionSubtitle
                sectionTitle
                sectionTitle_noTx: sectionTitle
              }
              fieldGroupName
              tabTitle
              tabTitle_noTx: tabTitle
            }
          }
        }
      }
    }
  }
`;

export const WPPageQuery = gql`
  query WordpressPageInfo(
    $id: ID!
    $idType: PageIdType!
    $asPreview: Boolean
    $language: String
  ) {
    allSettings {
      generalSettingsUrl
      generalSettingsTitle
      generalSettingsDescription
    }
    page(id: $id, idType: $idType, asPreview: $asPreview) {
      ...PageFields
    }
    ...WPSettings
    ...NavFragment
    ...AllWPPages
  }
  ${PageFieldsFrag}
  ${WPThemeSettingsFragment}
  ${NavFragment}
  ${AllWPPages}
`;

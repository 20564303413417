import type {
  NextApiRequest,
  NextApiResponse,
  PreviewData as NextPreviewData,
} from 'next';
import gql from 'graphql-tag-transform.macro';
import { createFetch } from '@curated-property/utils';
import { PageIdType } from './generated/wp';

function redirect(res: NextApiResponse, location: string) {
  res.writeHead(307, {
    Location: location,
  });
  res.end();
}

const pageExistsQuery = gql`
  query PageExists($id: ID!, $idType: PageIdType) {
    page(id: $id, idType: $idType) {
      uri
      status
      databaseId
    }
  }
`;

interface Args {
  wpApiEndpoint: string;
  urlPrefix: string;
  redirectLang?: string;
}

export function getPreviewHandler({
  wpApiEndpoint,
  redirectLang = 'en',
  urlPrefix,
}: Args) {
  const wpFetcher = createFetch(wpApiEndpoint, { skipCache: true });

  async function getPreviewPage({
    id,
    authHeader,
    idType,
  }: {
    id: string;
    authHeader: string;
    idType?: PageIdType;
  }) {
    const variables = {
      id,
      idType,
    };

    try {
      const data = await wpFetcher<{
        page: { databaseId: number; uri: string; status: string };
      }>(pageExistsQuery, {
        authHeader: `Bearer ${authHeader}`,
        variables: variables,
      });

      return data?.page;
    } catch {
      // failed to get the page
      return null;
    }
  }

  return async function preview(req: NextApiRequest, res: NextApiResponse) {
    const { id } = req.query;
    const token = req.query.token || req.query.authToken;

    // check if page exists, and this user has permission to view it
    const page = await getPreviewPage({
      id: id as string,
      authHeader: token as string,
      idType: PageIdType.DatabaseId,
    });

    if (!page) {
      return res
        .status(401)
        .json({ message: 'Page not found or auth invalid' });
    }

    const previewData: PreviewData = {
      authHeader: token as string,
      page: {
        id: page?.databaseId,
        slug: page?.uri,
        status: page?.status,
      },
    };

    res.setPreviewData(previewData);

    redirect(res, `/${redirectLang}/${urlPrefix}${page?.uri}`);
  };
}

export type PreviewData = Partial<
  NextPreviewData & {
    authHeader: string;
    page: {
      id: number;
      slug?: string;
      status?: string;
    };
  }
>;

export function getExitPreviewHandler({
  urlPrefix,
  redirectLang = 'en',
}: Args) {
  return async function exitPreview(req: NextApiRequest, res: NextApiResponse) {
    res.clearPreviewData();
    redirect(res, `/${redirectLang}/${urlPrefix}/`);
  };
}

import Popover, { PopoverProps, positionDefault } from '@reach/popover';
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

interface PopupContentProps {
  targetRef: PopoverProps['targetRef'];
  children: PopoverProps['children'];
  onClose(): void;
  className?: string;
}

export function PopupContent({
  targetRef,
  children,
  onClose,
  className,
}: PopupContentProps) {
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [zindex, setZindex] = useState('z-50');
  const [height, setHeight] = useState<number>(0);
  const headerHeight = 150;

  useEffect(() => {
    function onMouseDown(e: MouseEvent | FocusEvent) {
      if (
        !innerRef?.current?.contains(e.target as Element) &&
        !targetRef?.current?.contains(e.target as Element)
      ) {
        onClose();
      }
    }
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onClose();
      }
    }

    function onScroll() {
      const offsetTop = innerRef?.current?.getBoundingClientRect().top || 0;
      if (offsetTop > headerHeight) {
        setZindex('z-50');
      } else {
        setZindex('z-30');
      }
    }

    function onResize() {
      // Calculate Popover Max Height
      const offsetTop = targetRef?.current?.getBoundingClientRect().bottom || 0;
      setHeight(window.innerHeight - offsetTop);
    }

    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('focusin', onMouseDown);
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('focusin', onMouseDown);
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, [onClose, targetRef]);

  return (
    <Popover
      targetRef={targetRef}
      position={positionDefault}
      className={cx(
        'p-2 border border-solid shadow-lg bg-bg border-border focus:outline-none',
        zindex,
        className
      )}
      style={{
        maxHeight: className?.includes('overflow-y-auto')
          ? height || 'unset'
          : 'unset',
      }}
    >
      <div className="w-full h-full" ref={innerRef}>
        {children}
      </div>
    </Popover>
  );
}

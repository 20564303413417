export interface MediaBlocksMediaLayoutsInterface {
  id?: string;
  mediaLayout?: string;
  mediaBlockLayouts?: {
    mediaLayout: Array<
      | MediaBlocksSingleImageInterface
      | MediaBlocksTwoHorizontalInterface
      | MediaBlocksTwoVerticalInterface
      | MediaBlocksThreeOneLeftTwoRightInterface
      | MediaBlocksThreeTwoLeftOneRightInterface
      | MediaBlocksThreeOneTopTwoBottomInterface
      | MediaBlocksThreeTwoTopOneBottomInterface
      | MediaBlocksFourImageInterface
      | MediaBlocksVideoInterface
      | null
    >;
  };
}

interface MediaBlocksImageInterface {
  sourceUrl: string;
  altText: string;
}

interface ImageCropFields {
  crop?: boolean;
  cropType: string;
  cropHeight: string;
  cropWidth: string;
  xPosition: string;
  xPositionAdvanced: string;
  yPosition: string;
  yPositionAdvanced: string;
  autoPosition: boolean;
  enableCropping?: boolean;
  fieldGroupName?: string;
}

interface ImageCropVariations {
  fieldGroupName?: string;
  imageCrop11AddCrop: ImageCropFields[];
  imageCrop21AddCrop: ImageCropFields[];
  imageCrop12AddCrop: ImageCropFields[];
}

interface ImageCropProperties {
  imageCropSingle: ImageCropVariations;
}

interface ImageCropLeftProperties {
  imageLeftCrop: ImageCropVariations;
}

interface ImageCropRightProperties {
  imageRightCrop: ImageCropVariations;
}

interface ImageCropTopProperties {
  imageTopCrop: ImageCropVariations;
}

interface ImageCropBottomProperties {
  imageBottomCrop: ImageCropVariations;
}

interface ImageCropBottomLeftProperties {
  imageBottomLeftCrop: ImageCropVariations;
}

interface ImageCropBottomRightProperties {
  imageBottomRightCrop: ImageCropVariations;
}

interface ImageCropTopLeftProperties {
  imageTopLeftCrop: ImageCropVariations;
}

interface ImageCropTopRightProperties {
  imageTopRightCrop: ImageCropVariations;
}

interface MediaBlocksSingleImage {
  fieldGroupName?: string;
  maxImageWidth?: number;
  image?: MediaBlocksImageInterface;
}

interface MediaBlocksVideo {
  fieldGroupName?: string;
  videoUrl?: string;
}

export type MediaBlocksVideoInterface = MediaBlocksVideo;

export type MediaBlocksSingleImageInterface = MediaBlocksSingleImage &
  ImageCropProperties;

interface MediaBlocksTwoHorizontal {
  fieldGroupName?: string;
  maxImageWidth?: number;
  imageTop: MediaBlocksImageInterface;
  imageBottom: MediaBlocksImageInterface;
}

export type MediaBlocksTwoHorizontalInterface = MediaBlocksTwoHorizontal &
  ImageCropTopProperties &
  ImageCropBottomProperties;

interface MediaBlocksTwoVertical {
  fieldGroupName?: string;
  maxImageWidth?: number;
  imageLeft: MediaBlocksImageInterface;
  imageRight: MediaBlocksImageInterface;
}

export type MediaBlocksTwoVerticalInterface = MediaBlocksTwoVertical &
  ImageCropLeftProperties &
  ImageCropRightProperties;

interface MediaBlocksThreeOneTopTwoBottom {
  fieldGroupName?: string;
  maxImageWidth?: number;
  imageTop: MediaBlocksImageInterface;
  imageBottomLeft: MediaBlocksImageInterface;
  imageBottomRight: MediaBlocksImageInterface;
}

export type MediaBlocksThreeOneTopTwoBottomInterface =
  MediaBlocksThreeOneTopTwoBottom &
    ImageCropTopProperties &
    ImageCropBottomLeftProperties &
    ImageCropBottomRightProperties;

interface MediaBlocksThreeTwoTopOneBottom {
  fieldGroupName?: string;
  maxImageWidth?: number;
  imageBottom: MediaBlocksImageInterface;
  imageTopLeft: MediaBlocksImageInterface;
  imageTopRight: MediaBlocksImageInterface;
}

export type MediaBlocksThreeTwoTopOneBottomInterface =
  MediaBlocksThreeTwoTopOneBottom &
    ImageCropTopLeftProperties &
    ImageCropTopRightProperties &
    ImageCropBottomProperties;

interface MediaBlocksThreeOneLeftTwoRight {
  fieldGroupName?: string;
  maxImageWidth?: number;
  imageLeft: MediaBlocksImageInterface;
  imageTopRight: MediaBlocksImageInterface;
  imageBottomRight: MediaBlocksImageInterface;
}

export type MediaBlocksThreeOneLeftTwoRightInterface =
  MediaBlocksThreeOneLeftTwoRight &
    ImageCropLeftProperties &
    ImageCropTopRightProperties &
    ImageCropBottomRightProperties;

interface MediaBlocksThreeTwoLeftOneRight {
  fieldGroupName?: string;
  maxImageWidth?: number;
  imageTopLeft: MediaBlocksImageInterface;
  imageBottomLeft: MediaBlocksImageInterface;
  imageRight: MediaBlocksImageInterface;
}

export type MediaBlocksThreeTwoLeftOneRightInterface =
  MediaBlocksThreeTwoLeftOneRight &
    ImageCropTopLeftProperties &
    ImageCropBottomLeftProperties &
    ImageCropRightProperties;

interface MediaBlocksFourImage {
  fieldGroupName?: string;
  maxImageWidth?: number;
  imageTopLeft: MediaBlocksImageInterface;
  imageTopRight: MediaBlocksImageInterface;
  imageBottomLeft: MediaBlocksImageInterface;
  imageBottomRight: MediaBlocksImageInterface;
}

export type MediaBlocksFourImageInterface = MediaBlocksFourImage &
  ImageCropTopLeftProperties &
  ImageCropTopRightProperties &
  ImageCropBottomLeftProperties &
  ImageCropBottomRightProperties;

import {
  MenuProps,
  Ifooter,
  BreadcrumbProps,
} from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/wdf0nrh.css"></link>
    </>
  );
};

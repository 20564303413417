import { RoomTypesProps } from '@curated-property/shared-components';
import {
  ExtendedHotelRoomType,
  PageProps,
} from '@curated-property/shared-pages';

type WPRooms = RoomTypesProps['rooms'];
type DxGQLRooms = ExtendedHotelRoomType[];
type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

/**
 * Merges room data from WordPress and Core+.
 *
 * This function takes room data from two different sources, WordPress and Core+,
 * and combines them into a single dataset. It performs the following steps.
 */
export function mergeWpRooms({
  dxGqlRooms,
  wpRooms,
}: {
  dxGqlRooms?: DxGQLRooms;
  wpRooms?: WPRooms;
}) {
  const dxGqlRoomsByRoomTypeCode = dxGqlRooms?.reduce((prev, curr) => {
    if (!curr.roomTypeCode) {
      return prev;
    }
    prev[curr.roomTypeCode] = curr;
    return prev;
  }, {} as Record<string, ArrayElement<typeof dxGqlRooms>>);

  return wpRooms
    ?.filter((wpRoom) => {
      // must have a room in Core+ to show up
      const roomCode = wpRoom.roomTypeCode;
      if (roomCode.includes('-')) {
        return true;
      } else {
        return !!dxGqlRoomsByRoomTypeCode?.[wpRoom?.roomTypeCode || ''];
      }
    })
    .map((wpRoom) => {
      const roomCode = wpRoom.roomTypeCode;
      if (roomCode.includes('-')) {
        const duplicateRoomArr = roomCode.split('-');
        // loop through dxgql rooms to find a room that matches roomCode & ctyhocn
        for (let i = 0; i < (dxGqlRooms?.length || 0); i++) {
          const dxCtyhocn = dxGqlRooms?.[i]?.hotelCtyhocn;
          const dxRoomCode = dxGqlRooms?.[i]?.roomTypeCode;
          if (
            duplicateRoomArr[0] === dxCtyhocn &&
            duplicateRoomArr[1] === dxRoomCode
          ) {
            return {
              ...dxGqlRooms?.[i],
              ...wpRoom,
            };
          }
        }
      } else {
        return {
          ...dxGqlRoomsByRoomTypeCode?.[roomCode || ''],
          ...wpRoom,
        };
      }
      return null;
    });
}

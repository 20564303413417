/* Signia Atlanta aka Signia by Hilton Atlanta World Congress Theater – atlwcsa */
import { Ifooter, BreadcrumbProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { AdditionalHeadProps } from '../pages/_app';

export const AdditionalHead = ({ pageSlug }: AdditionalHeadProps) => {
  switch (pageSlug) {
    case 'spa-signia':
      return (
        <>
          <style>
            {`
              :root {
                --font-headline: 'Galano Grotesque';
                --font-sans:'Galano Grotesque';
                --font-button:'Galano Grotesque';
            }

            @font-face {
              font-family: 'Galano Grotesque';
              font-display: swap;
              src: url('/modules/assets/fonts/galano-grotesque/GalanoGrotesqueAltRegular.otf') format('opentype');
            }

            body,p,span,div,ol,ul,li,h1,h2,h3,.font-headline,.font-black {
              font-family: 'Galano Grotesque' !important;
            }
              `}
          </style>
        </>
      );

    case 'capolinea':
      return (
        <>
          <link rel="stylesheet" href="https://use.typekit.net/czx6lwb.css" />
          <style>{`
            .font-headline { 
              font-family: ivypresto-display!important; 
            }
            p, a, li, h4:not(.font-headline), [data-reach-tab], .btn, [data-element-id="subtitle-wrapper"] { 
              font-family: sofia-pro!important; 
            }
          `}</style>
        </>
      );
    case 'lobby-bar':
      return (
        <>
          <link rel="stylesheet" href="https://use.typekit.net/pqu5jwz.css" />
          <style>{`
            .font-headline > *,
            .cp-htmlMenus > div > h2 { 
              font-family: bee!important; 
            }

            .cp-htmlMenus > div > h2 {
              font-size:72px;
            }

            p, a, li, h4:not(.font-headline), 
            [data-reach-tab], .btn, 
            [data-element-id="subtitle-wrapper"],
            .cp-htmlMenus h3,
            .cp-htmlMenus h4,
            .htmlMenuTitle,
            .htmlMenuLine2,
            .htmlMenuDisclaimer,
            .htmlMenuPrice { 
              font-family: bilo!important; 
            }
          `}</style>
        </>
      );
    case 'nest-on-four':
      return (
        <>
          <link rel="stylesheet" href="https://use.typekit.net/anz7hef.css" />
          <style>{`
            .font-headline { 
              font-family: aktiv-grotesk-condensed!important; 
            }
            p, a, li, h4:not(.font-headline), [data-reach-tab], .btn, [data-element-id="subtitle-wrapper"] {  
              font-family: aktiv-grotesk!important; 
            }
          `}</style>
        </>
      );
    case 'homespun':
      return (
        <>
          <link rel="stylesheet" href="https://use.typekit.net/lan1hff.css" />
          <style>{`
            .font-headline { 
              font-family: calluna-sans!important; 
            }
            p, a, li, h4:not(.font-headline), [data-reach-tab], .btn, [data-element-id="subtitle-wrapper"] { 
              font-family: calluna!important; 
            }
          `}</style>
        </>
      );
  }
};

const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((b) => b.BreadCrumbs)
);

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  const subpageCrumbStyles =
    props?.pageSlug === 'capolinea'
      ? {
          activeTextColour: '#0D2240',
          backgroundColour: '#E9A937',
          textColour: '#0D2240',
        }
      : {};

  return <SharedBreadCrumbs {...props} {...subpageCrumbStyles} />;
};

/**
 * This component will go away when we can consume dx-osc-ui/packages
 */
import React from 'react';
import { useReducer, useState } from 'react';
import { startOfToday, addDays, parse } from 'date-fns';
import { RoomInfoButton } from './room-info/room-info-button';
import { SpecialRatesButton } from './special-rates/special-rates-button';
import dynamic from 'next/dynamic';
import { roomInfoReducer, RoomInfoState } from './room-info/reducer';
import { IRateData, sections } from './special-rates/special-rates-form';
import { useTranslation } from 'next-i18next';

// code split calendar popout to not render on server since dates will be different between builds
const CalendarPopout: any = dynamic(() => import('./calendar-popout'), {
  ssr: false,
});

interface SubmitProps {
  arrivalDate: Date;
  departureDate?: Date;
  rooms: RoomInfoState[];
  specialRates: IRateData[];
}
interface BookingWidgetProps {
  locale: Locale;
  minArrivalDate: string;
  defaultRoomState?: RoomInfoState[];
  ageRange?: {
    min: number;
    max: number;
  };
  onSumbit(props: SubmitProps): void;
}

function parseDate(date: string, add?: number) {
  return addDays(parse(date, 'yyyy-MM-dd', startOfToday()), add || 0);
}

export function BookingWidget({
  locale,
  minArrivalDate,
  ageRange,
  onSumbit,
  defaultRoomState = [{ adults: 1, kids: 0, childAges: [] }],
}: BookingWidgetProps) {
  const [day, setDay] = useState(() => parseDate(minArrivalDate));

  const [endDay, setEndDay] = useState<Date | undefined>(() =>
    parseDate(minArrivalDate, 1)
  );
  const [rooms, updateRooms] = useReducer(roomInfoReducer, defaultRoomState);

  const [sectionState, setSections] = useState(() => sections);
  const [t] = useTranslation();

  return (
    <div className="grid items-center justify-center gap-2 md:flex">
      <CalendarPopout
        arrivalDate={day}
        departureDate={endDay}
        onArrivalChange={setDay}
        onDepartureDateChange={setEndDay}
        locale={locale}
        parseDate={parseDate}
      />
      <div className="flex items-center justify-center space-x-2">
        <RoomInfoButton
          updateRooms={updateRooms}
          rooms={rooms}
          ageRange={ageRange}
        />
        <SpecialRatesButton
          sections={sectionState}
          setRateValues={setSections}
        />
      </div>
      <button
        id="focusTrapEnd"
        onClick={() => {
          onSumbit({
            arrivalDate: day,
            departureDate: endDay,
            rooms,
            specialRates: sectionState.reduce((prev, curr) => {
              return prev.concat(curr);
            }, []),
          });
        }}
        className="md:ml-2 btn btn-primary btn-xl"
      >
        {t('book')}
      </button>
    </div>
  );
}

export type RoomInfoState = {
  adults: number;
  kids: number;
  childAges: (number | undefined)[];
};

export enum RoomInfoActionType {
  ADD_ROOM = 'ADD_ROOM',
  REMOVE_ROOM = 'REMOVE_ROOM',
  ADD_ADULT = 'ADD_ADULT',
  REMOVE_ADULT = 'REMOVE_ADULT',
  ADD_CHILD = 'ADD_CHILD',
  REMOVE_CHILD = 'REMOVE_CHILD',
  CHILD_AGE = 'CHILD_AGE',
}
export type RoomInfoAction = {
  type: RoomInfoActionType;
  index?: number;
  child?: number;
  age?: number;
};

export const roomInfoReducer = (
  state: RoomInfoState[],
  action: RoomInfoAction
): RoomInfoState[] => {
  switch (action.type) {
    case RoomInfoActionType.ADD_ADULT:
      return state.map((room, index) =>
        index === action.index ? { ...room, adults: room.adults + 1 } : room
      );
    case RoomInfoActionType.ADD_CHILD:
      return state.map((room, index) =>
        index === action.index
          ? {
              ...room,
              kids: room.kids + 1,
              childAges: [...room.childAges, undefined],
            }
          : room
      );
    case RoomInfoActionType.CHILD_AGE:
      return state.map((room, index) => {
        const childAges = [...room.childAges];
        childAges[action.child || 0] = action.age || 0;
        return index === action.index ? { ...room, childAges } : room;
      });
    case RoomInfoActionType.ADD_ROOM:
      return [...state, { adults: 1, kids: 0, childAges: [] }];
    case RoomInfoActionType.REMOVE_ADULT:
      return state.map((room, index) =>
        index === action.index ? { ...room, adults: room.adults - 1 } : room
      );
    case RoomInfoActionType.REMOVE_CHILD:
      return state.map((room, index) =>
        index === action.index
          ? {
              ...room,
              kids: room.kids - 1,
              childAges: [
                ...room.childAges
                  .sort((a, b) =>
                    typeof a === 'undefined' || typeof b === 'undefined' ? 1 : 0
                  )
                  .slice(0, -1),
              ],
            }
          : room
      );
    case RoomInfoActionType.REMOVE_ROOM:
      return state.filter((_, index) => index !== action.index);
    default:
      return state;
  }
};

/**
 * This component will go away when we can consume dx-osc-ui/packages
 */
import React, { useRef, useState } from 'react';
import { PopupContent } from '../popover-content';
import { Date } from './date';
import { formatDate } from './date-formatter';
import { Calendar } from './calendar/calendar';
import { startOfToday, addDays } from 'date-fns';

interface CalendarPopoutProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'className'
  > {
  locale: Locale;
  arrivalDate: Date;
  departureDate?: Date;
  onArrivalChange(arrival: Date): void;
  onDepartureDateChange(departure?: Date): void;
  parseDate?: any;
}

export default CalendarPopout;
export function CalendarPopout({
  locale,
  arrivalDate,
  departureDate,
  onArrivalChange,
  onDepartureDateChange,
  parseDate,
  ...buttonProps
}: CalendarPopoutProps) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<null | HTMLButtonElement>(null);

  const handleClose = () => {
    if (!departureDate) {
      const dateObj = formatDate(arrivalDate, locale, { date: { day: 'dd' } });
      const formatted =
        dateObj.date.year + '-' + dateObj.date.month + '-' + dateObj.date.day;
      const nextDay = parseDate(formatted, 1);
      onDepartureDateChange(nextDay);
    }
    setOpen(!open);
  };

  return (
    <>
      <button
        id={Object.keys(buttonProps).length === 0 ? 'calendarButton' : ''}
        className="flex justify-center mb-4 divide-x appearance-none md:mr-2 flex-basis-full divide-border md:mb-0 md:flex-basis-auto hover:duration-100 focus:outline-none focus:shadow-outline focus:duration-100"
        ref={buttonRef}
        onClick={handleClose}
        {...buttonProps}
      >
        <Date day={formatDate(arrivalDate, locale, { date: { day: 'dd' } })} />
        <Date
          day={formatDate(
            departureDate || addDays(arrivalDate || startOfToday(), 1),
            locale,
            {
              date: { day: 'dd' },
            }
          )}
        />
      </button>

      {open && (
        <PopupContent
          onClose={handleClose}
          targetRef={buttonRef}
          className="md:-ml-[169px]"
        >
          <Calendar
            locale={locale}
            onDayChange={(d) => d && onArrivalChange(d)}
            onEndDayChange={(d) => {
              onDepartureDateChange(d);
              if (d) {
                setOpen(false);
                buttonRef?.current?.focus();
              }
            }}
            allowSameDay={true}
            day={arrivalDate}
            endDay={departureDate}
          />
        </PopupContent>
      )}
    </>
  );
}

import React from 'react';

/**
 * This file contains functions to help with the inclusion of styles pulled from wordpress
 * - While these will help with customisation of components from the WP side,
 *   they should ALWAYS be used alongside non-wordpress fallbacks
 */

/**
 * componentStyles
 * Shallow merge two style objects
 * global - styles set in themesettings
 * instance - styles set on individual component
 */

export interface StyleObject {
  [name: string]: any;
  componentBackgroundColor?: string;
  componentBackgroundSize?: string;
  componentBackgroundRepeat?: string;
  componentBackgroundPosition?: string;
  contentBackgroundColor?: string;
  contentBackgroundSize?: string;
  contentBackgroundRepeat?: string;
  contentBackgroundPosition?: string;
  contentBackgroundColorLayerTwo?: string;
  subtitleColor?: string;
  textAlignment?: string;
  textColor?: string;
  titleColor?: string;
  componentBackgroundImage?: {
    sourceUrl?: string;
    link?: string;
  };
  contentBackgroundImage?: {
    sourceUrl?: string;
    link?: string;
  };
  paddingTop?: {
    paddingTopDesktop?: string;
    paddingTopTablet?: string;
    paddingTopMobile?: string;
  };
  paddingBottom?: {
    paddingBottomDesktop?: string;
    paddingBottomTablet?: string;
    paddingBottomMobile?: string;
  };
  showHide?: boolean;
  hideAnimations?: string;
  hideParallax?: string;
  animationDirection?: string;
  containerMaxWidth?: string;
}

export function GIS_merge(
  global: StyleObject | undefined,
  instance: StyleObject | undefined
) {
  const return_obj: StyleObject = {};
  if (global instanceof Object && instance instanceof Object) {
    for (const [k, v] of Object.entries(global)) {
      if (Object.prototype.hasOwnProperty.call(instance, k)) {
        if (instance[k] instanceof Object && global[k] instanceof Object) {
          return_obj[k] = GIS_merge(global[k], instance[k]);
          continue;
        }
        if (instance[k] !== null) {
          return_obj[k] = instance[k];
          continue;
        }
      }
      return_obj[k] = v;
    }
  }
  return return_obj;
}

/**
 * componentStyles
 * global - styles set in themesettings
 * instance - styles set on individual component
 */
export function GIS_Array(
  global: StyleObject | undefined,
  instance: StyleObject | undefined
) {
  // Background Settings for the whole component
  const componentBackgroundImage = instance?.componentBackgroundImage?.sourceUrl
    ? instance?.componentBackgroundImage?.sourceUrl
    : global?.componentBackgroundImage?.sourceUrl;

  const componentBackgroundColor = instance?.componentBackgroundColor
    ? instance?.componentBackgroundColor
    : global?.componentBackgroundColor;

  const componentBackgroundSize = instance?.componentBackgroundSize
    ? instance?.componentBackgroundSize
    : global?.componentBackgroundSize;

  const componentBackgroundRepeat = instance?.componentBackgroundRepeat
    ? instance?.componentBackgroundRepeat
    : global?.componentBackgroundRepeat;

  const componentBackgroundPosition = instance?.componentBackgroundPosition
    ? instance?.componentBackgroundPosition
    : global?.componentBackgroundPosition;

  // Background Settings for the content
  const contentBackgroundImage = instance?.contentBackgroundImage?.sourceUrl
    ? instance?.contentBackgroundImage?.sourceUrl
    : global?.contentBackgroundImage?.sourceUrl;

  const contentBackgroundColor = instance?.contentBackgroundColor
    ? instance?.contentBackgroundColor
    : global?.contentBackgroundColor;

  const contentBackgroundSize = instance?.contentBackgroundSize
    ? instance?.contentBackgroundSize
    : global?.contentBackgroundSize;

  const contentBackgroundRepeat = instance?.contentBackgroundRepeat
    ? instance?.contentBackgroundRepeat
    : global?.contentBackgroundRepeat;

  const contentBackgroundPosition = instance?.contentBackgroundPosition
    ? instance?.contentBackgroundPosition
    : global?.contentBackgroundPosition;

  // Alignment
  const textAlignment = instance?.textAlignment
    ? instance?.textAlignment
    : global?.textAlignment;

  const buttonAlignment = instance?.buttonAlignment
    ? instance?.buttonAlignment
    : global?.buttonAlignment;

  const containerMaxWidth = instance?.containerMaxWidth
    ? instance?.containerMaxWidth
    : global?.containerMaxWidth;

  // Title/Subtitle/Text Color
  const titleColor = instance?.titleColor
    ? instance?.titleColor
    : global?.titleColor;

  const subtitleColor = instance?.subtitleColor
    ? instance?.subtitleColor
    : global?.subtitleColor;

  const textColor = instance?.textColor
    ? instance?.textColor
    : global?.textColor;

  // Show / Hide
  const showHide = instance?.showHide ? instance?.showHide : global?.showHide;

  // No Translate
  const HeadingsNoTranslate = instance?.headingsNoTranslate
    ? instance?.headingsNoTranslate
    : global?.headingsNoTranslate;

  // Show / Hide Animations
  const hideAnimations = instance?.hideAnimations
    ? instance?.hideAnimations
    : global?.hideAnimations;

  // Show / Hide Parallax
  const hideParallax = instance?.hideParallax
    ? instance?.hideParallax
    : global?.hideParallax;

  // Animation Direction
  const animationDirection = instance?.animationDirection
    ? instance?.animationDirection
    : global?.animationDirection;

  // Image Slider Animations
  const sliderAnimations = instance?.sliderAnimations
    ? instance?.sliderAnimations
    : global?.sliderAnimations;

  // Zoom on Hover Animation
  const zoomOnHover = instance?.zoomOnHover
    ? instance?.zoomOnHover
    : global?.zoomOnHover;

  // Padding Settings
  const paddingBottomDesktop = instance?.paddingBottom?.paddingBottomDesktop
    ? instance?.paddingBottom?.paddingBottomDesktop
    : global?.paddingBottom?.paddingBottomDesktop;

  const paddingBottomTablet = instance?.paddingBottom?.paddingBottomTablet
    ? instance?.paddingBottom?.paddingBottomTablet
    : global?.paddingBottom?.paddingBottomTablet;

  const paddingBottomMobile = instance?.paddingBottom?.paddingBottomMobile
    ? instance?.paddingBottom?.paddingBottomMobile
    : global?.paddingBottom?.paddingBottomMobile;

  const paddingTopDesktop = instance?.paddingTop?.paddingTopDesktop
    ? instance?.paddingTop?.paddingTopDesktop
    : global?.paddingTop?.paddingTopDesktop;

  const paddingTopTablet = instance?.paddingTop?.paddingTopTablet
    ? instance?.paddingTop?.paddingTopTablet
    : global?.paddingTop?.paddingTopTablet;

  const paddingTopMobile = instance?.paddingTop?.paddingTopMobile
    ? instance?.paddingTop?.paddingTopMobile
    : global?.paddingTop?.paddingTopMobile;

  // Content Box Positioning and Opacity
  const contentBoxBackgroundOpacity = instance?.contentBoxBackgroundOpacity
    ? instance?.contentBoxBackgroundOpacity
    : global?.contentBoxBackgroundOpacity;

  const contentBoxHorizontal = instance?.contentBoxHorizontal
    ? instance?.contentBoxHorizontal
    : global?.contentBoxHorizontal;

  const contentBoxVertical = instance?.contentBoxVertical
    ? instance?.contentBoxVertical
    : global?.contentBoxVertical;

  // Gradient Position + Toggle
  const gradientPosition = instance?.gradientPosition
    ? instance?.gradientPosition
    : global?.gradientPosition;

  const gradientToggle = instance?.gradientToggle
    ? instance?.gradientToggle
    : global?.gradientToggle;

  // Introduction - Layout settings
  const layoutDirection = instance?.layoutDirection
    ? instance?.layoutDirection
    : global?.layoutDirection;

  const offset = instance?.offset ? instance?.offset : global?.offset;

  const padding = instance?.padding ? instance?.padding : global?.padding;

  // Masthead Options
  const mastheadHeight = instance?.mastheadHeight
    ? instance?.mastheadHeight
    : global?.mastheadHeight;

  const mastheadSpacer = instance?.mastheadSpacer
    ? instance?.mastheadSpacer
    : global?.mastheadSpacer;

  const mastheadSpacerColour = instance?.mastheadSpacerColour
    ? instance?.mastheadSpacerColour
    : global?.mastheadSpacerColour;

  const overlayBackgroundColour = instance?.overlayBackgroundColour
    ? instance?.overlayBackgroundColour
    : global?.overlayBackgroundColour;

  const overlayBackgroundOpacity = instance?.overlayBackgroundOpacity
    ? instance?.overlayBackgroundOpacity
    : global?.overlayBackgroundOpacity;

  const overlayXPosition = instance?.overlayXPosition
    ? instance?.overlayXPosition
    : global?.overlayXPosition;

  const overlayYPosition = instance?.overlayYPosition
    ? instance?.overlayYPosition
    : global?.overlayYPosition;

  const mastheadGradient = instance?.mastheadGradient
    ? instance?.mastheadGradient
    : global?.mastheadGradient;

  // Contact Bar Options
  const borderBottomColour = instance?.borderBottomColour
    ? instance?.borderBottomColour
    : global?.borderBottomColour;

  const borderTopColour = instance?.borderTopColour
    ? instance?.borderTopColour
    : global?.borderTopColour;

  const seperatorColour = instance?.seperatorColor
    ? instance?.seperatorColor
    : global?.seperatorColor;

  // 2:1 Media & Copy layout settings
  const horizontalDirection = instance?.horizontalDirection
    ? instance?.horizontalDirection
    : global?.horizontalDirection;

  const verticalDirection = instance?.verticalDirection
    ? instance?.verticalDirection
    : global?.verticalDirection;

  // Media + Copy Options
  const mediaAndCopyPadding = instance?.mediaAndCopyPadding
    ? instance?.mediaAndCopyPadding
    : global?.mediaAndCopyPadding;

  const mediaAndCopyRowPadding = instance?.mediaAndCopyRowPadding
    ? instance?.mediaAndCopyRowPadding
    : global?.mediaAndCopyRowPadding;

  const smallImageBorderColour = instance?.smallImageBorderColour
    ? instance?.smallImageBorderColour
    : global?.smallImageBorderColour;

  const mediaAndCopyCarouselHeaderTitleColour =
    instance?.mediaAndCopyCarouselHeaderTitleColour
      ? instance?.mediaAndCopyCarouselHeaderTitleColour
      : global?.mediaAndCopyCarouselHeaderTitleColour;

  const mediaAndCopyCarouselHeaderCopyColour =
    instance?.mediaAndCopyCarouselHeaderCopyColour
      ? instance?.mediaAndCopyCarouselHeaderCopyColour
      : global?.mediaAndCopyCarouselHeaderCopyColour;

  const mediaAndCopyCarouselUiPrimary = instance?.uiPrimaryColour
    ? instance?.uiPrimaryColour
    : global?.uiPrimaryColour;

  const mediaAndCopyCarouselUiSecondary = instance?.uiSecondaryColour
    ? instance?.uiSecondaryColour
    : global?.uiSecondaryColour;

  // Rooms Options
  const filterBackgroundColor = instance?.filterBackgroundColor
    ? instance?.filterBackgroundColor
    : global?.filterBackgroundColor;

  const filterTitleColor = instance?.filterTitleColor
    ? instance?.filterTitleColor
    : global?.filterTitleColor;

  const filterRuleColor = instance?.filterRuleColor
    ? instance?.filterRuleColor
    : global?.filterRuleColor;

  const filterResultsColor = instance?.filterResultsColor
    ? instance?.filterResultsColor
    : global?.filterResultsColor;

  const rateBackgroundColor = instance?.rateBackgroundColor
    ? instance?.rateBackgroundColor
    : global?.rateBackgroundColor;

  const rateFromColor = instance?.rateFromColor
    ? instance?.rateFromColor
    : global?.rateFromColor;

  const rateFigureColor = instance?.rateFigureColor
    ? instance?.rateFigureColor
    : global?.rateFigureColor;

  const rateErrorColor = instance?.rateErrorColor
    ? instance?.rateErrorColor
    : global?.rateErrorColor;

  const rateCurrencyColor = instance?.rateCurrencyColor
    ? instance?.rateCurrencyColor
    : global?.rateCurrencyColor;

  const popunderTitleColor = instance?.popunderTitleColor
    ? instance?.popunderTitleColor
    : global?.popunderTitleColor;

  const popunderTextColor = instance?.popunderTextColor
    ? instance?.popunderTextColor
    : global?.popunderTextColor;

  const popunderSubtitleColor = instance?.popunderSubtitleColor
    ? instance?.popunderSubtitleColor
    : global?.popunderSubtitleColor;

  const popunderBackgroundColor = instance?.popunderBackgroundColor
    ? instance?.popunderBackgroundColor
    : global?.popunderBackgroundColor;

  const popunderRuleColor = instance?.popunderRuleColor
    ? instance?.popunderRuleColor
    : global?.popunderRuleColor;

  const popunderCtaBackgroundColor = instance?.popunderCtaBackgroundColor
    ? instance?.popunderCtaBackgroundColor
    : global?.popunderCtaBackgroundColor;

  const popunderCtaBackgroundColorHover =
    instance?.popunderCtaBackgroundColorHover
      ? instance?.popunderCtaBackgroundColorHover
      : global?.popunderCtaBackgroundColorHover;

  const popunderCtaBorderColor = instance?.popunderCtaBorderColor
    ? instance?.popunderCtaBorderColor
    : global?.popunderCtaBorderColor;

  const popunderCtaBorderColorHover = instance?.popunderCtaBorderColorHover
    ? instance?.popunderCtaBorderColorHover
    : global?.popunderCtaBorderColorHover;

  const popunderCtaTextColor = instance?.popunderCtaTextColor
    ? instance?.popunderCtaTextColor
    : global?.popunderCtaTextColor;

  const popunderCtaTextColorHover = instance?.popunderCtaTextColorHover
    ? instance?.popunderCtaTextColorHover
    : global?.popunderCtaTextColorHover;

  const paginationTextColor = instance?.paginationTextColor
    ? instance?.paginationTextColor
    : global?.paginationTextColor;

  const paginationSeperatorColor = instance?.paginationSeperatorColor
    ? instance?.paginationSeperatorColor
    : global?.paginationSeperatorColor;

  const paginationBackgroundColor = instance?.paginationBackgroundColor
    ? instance?.paginationBackgroundColor
    : global?.paginationBackgroundColor;

  const controlsIconColor = instance?.controlsIconColor
    ? instance?.controlsIconColor
    : global?.controlsIconColor;

  const controlsBackgroundColor = instance?.controlsBackgroundColor
    ? instance?.controlsBackgroundColor
    : global?.controlsBackgroundColor;

  const activeTileSeperatorColor = instance?.activeTileSeperatorColor
    ? instance?.activeTileSeperatorColor
    : global?.activeTileSeperatorColor;

  const activeTileColor = instance?.activeTileColor
    ? instance?.activeTileColor
    : global?.activeTileColor;

  const viewDetailsColor = instance?.viewDetailsColor
    ? instance?.viewDetailsColor
    : global?.viewDetailsColor;

  const activeTileTextColor = instance?.activeTileTextColor
    ? instance?.activeTileTextColor
    : global?.activeTileTextColor;

  const viewDetailsTextColor = instance?.viewDetailsTextColor
    ? instance?.viewDetailsTextColor
    : global?.viewDetailsTextColor;

  const viewDetailsBorderColor = instance?.viewDetailsBorderColor
    ? instance?.viewDetailsBorderColor
    : global?.viewDetailsBorderColor;

  const viewDetailsBackgroundColor = instance?.viewDetailsBackgroundColor
    ? instance?.viewDetailsBackgroundColor
    : global?.viewDetailsBackgroundColor;

  const activeViewDetailsBackgroundColor =
    instance?.activeViewDetailsBackgroundColor
      ? instance?.activeViewDetailsBackgroundColor
      : global?.activeViewDetailsBackgroundColor;

  const activeViewDetailsBorderColor = instance?.activeViewDetailsBorderColor
    ? instance?.activeViewDetailsBorderColor
    : global?.activeViewDetailsBorderColor;

  const activeViewDetailsTextColor = instance?.activeViewDetailsTextColor
    ? instance?.activeViewDetailsTextColor
    : global?.activeViewDetailsTextColor;

  const bookCtaBackgroundColor = instance?.bookCtaBackgroundColor
    ? instance?.bookCtaBackgroundColor
    : global?.bookCtaBackgroundColor;

  const bookCtaBorderColor = instance?.bookCtaBorderColor
    ? instance?.bookCtaBorderColor
    : global?.bookCtaBorderColor;

  const bookCtaTextColor = instance?.bookCtaTextColor
    ? instance?.bookCtaTextColor
    : global?.bookCtaTextColor;

  const activeBookCtaBackgroundColor = instance?.activeBookCtaBackgroundColor
    ? instance?.activeBookCtaBackgroundColor
    : global?.activeBookCtaBackgroundColor;

  const activeBookCtaBorderColor = instance?.activeBookCtaBorderColor
    ? instance?.activeBookCtaBorderColor
    : global?.activeBookCtaBorderColor;

  const activeBookCtaTextColor = instance?.activeBookCtaTextColor
    ? instance?.activeBookCtaTextColor
    : global?.activeBookCtaTextColor;

  const disableDropShadow = instance?.disableDropShadow
    ? instance?.disableDropShadow
    : global?.disableDropShadow;

  const feeTransparencyDefaultColor = instance?.feeTransparencyDefaultColor
    ? instance?.feeTransparencyDefaultColor
    : global?.feeTransparencyDefaultColor;

  const feeTransparencyActiveColor = instance?.feeTransparencyActiveColor
    ? instance?.feeTransparencyActiveColor
    : global?.feeTransparencyActiveColor;

  const feeTransparencyTextInExpandedDropdown =
    instance?.feeTransparencyTextInExpandedDropdown
      ? instance?.feeTransparencyTextInExpandedDropdown
      : global?.feeTransparencyTextInExpandedDropdown;

  // Key info grid
  const contentBackgroundOpacity = instance?.contentBackgroundOpacity
    ? instance?.contentBackgroundOpacity
    : global?.contentBackgroundOpacity;

  const columnBoxBackgroundColor = instance?.columnBoxBackgroundColor
    ? instance?.columnBoxBackgroundColor
    : global?.columnBoxBackgroundColor;

  const columnBoxTextColor = instance?.columnBoxTextColor
    ? instance?.columnBoxTextColor
    : global?.columnBoxTextColor;

  const columnBoxIconColor = instance?.columnBoxIconColor
    ? instance?.columnBoxIconColor
    : global?.columnBoxIconColor;

  // Icon Block
  const labelBlockColour = instance?.labelBlockColour
    ? instance?.labelBlockColour
    : global?.labelBlockColour;

  const iconBlockColour = instance?.iconBlockColour
    ? instance?.iconBlockColour
    : global?.iconBlockColour;

  // Icon Core+
  const labelCoreColour = instance?.labelCoreColour
    ? instance?.labelCoreColour
    : global?.labelCoreColour;

  const iconCoreColour = instance?.iconCoreColour
    ? instance?.iconCoreColour
    : global?.iconCoreColour;

  const twoColumnIconColour = instance?.twoColumnIconColour
    ? instance?.twoColumnIconColour
    : global?.twoColumnIconColour;

  const twoColumnLabelColour = instance?.twoColumnLabelColour
    ? instance?.twoColumnLabelColour
    : global?.twoColumnLabelColour;

  const hotelPoliciesBorderColour = instance?.borderColour
    ? instance?.borderColour
    : global?.borderColour;

  const hotelPoliciesIconIndicatorColour = instance?.iconIndicatorColour
    ? instance?.iconIndicatorColour
    : global?.iconIndicatorColour;

  const hotelPoliciesIconIndicatorColourExpanded =
    instance?.iconIndicatorColourExpanded
      ? instance?.iconIndicatorColourExpanded
      : global?.iconIndicatorColourExpanded;

  const hotelOpenPanelBackgroundColour = instance?.openPanelBackgroundColour
    ? instance?.openPanelBackgroundColour
    : global?.openPanelBackgroundColour;

  // Accordion
  const accordionBorderColour = instance?.borderColour
    ? instance?.borderColour
    : global?.borderColour;

  const accordionOpenPanelBackgroundColour = instance?.openPanelBackgroundColour
    ? instance?.openPanelBackgroundColour
    : global?.openPanelBackgroundColour;

  const accordionIconIndicatorColour = instance?.iconIndicatorColour
    ? instance?.iconIndicatorColour
    : global?.iconIndicatorColour;

  const accordionIconIndicatorColourExpanded =
    instance?.iconIndicatorColourExpanded
      ? instance?.iconIndicatorColourExpanded
      : global?.iconIndicatorColourExpanded;

  // Tabbed Table Data
  const activeTabAccentColour = instance?.activeTabAccentColour
    ? instance?.activeTabAccentColour
    : global?.activeTabAccentColour;

  const activeTabTextColour = instance?.activeTabTextColour
    ? instance?.activeTabTextColour
    : global?.activeTabTextColour;

  const tableAltRowBackgroundColour = instance?.tableAltRowBackgroundColour
    ? instance?.tableAltRowBackgroundColour
    : global?.tableAltRowBackgroundColour;

  const tableAltRowTextColour = instance?.tableAltRowTextColour
    ? instance?.tableAltRowTextColour
    : global?.tableAltRowTextColour;

  const tableHeaderBackgroundColour = instance?.tableHeaderBackgroundColour
    ? instance?.tableHeaderBackgroundColour
    : global?.tableHeaderBackgroundColour;

  const tableHeaderTextColour = instance?.tableHeaderTextColour
    ? instance?.tableHeaderTextColour
    : global?.tableHeaderTextColour;

  const tableRowBackgroundColour = instance?.tableRowBackgroundColour
    ? instance?.tableRowBackgroundColour
    : global?.tableRowBackgroundColour;

  const tableRowTextColour = instance?.tableRowTextColour
    ? instance?.tableRowTextColour
    : global?.tableRowTextColour;

  const galleryWallCaptionColour = instance?.galleryWallCaptionColour
    ? instance?.galleryWallCaptionColour
    : global?.galleryWallCaptionColour;

  const htmlMenuTabBackgroundColour = instance?.tabBackgroundColour
    ? instance?.tabBackgroundColour
    : global?.tabBackgroundColour;

  const htmlMenuTabTextColour = instance?.tabTextColour
    ? instance?.tabTextColour
    : global?.tabTextColour;

  const htmlMenuTabUnderlineColour = instance?.tabUnderlineColour
    ? instance?.tabUnderlineColour
    : global?.tabUnderlineColour;

  const htmlMenuActiveTabBackgroundColour = instance?.activeTabBackgroundColour
    ? instance?.activeTabBackgroundColour
    : global?.activeTabBackgroundColour;

  const htmlMenuActiveTabTextColour = instance?.activeTabTextColour
    ? instance?.activeTabTextColour
    : global?.activeTabTextColour;

  const htmlMenuActiveTabUnderlineColour = instance?.activeTabUnderlineColour
    ? instance?.activeTabUnderlineColour
    : global?.activeTabUnderlineColour;

  const htmlMenuItemLine1Colour = instance?.menuItemLine1Colour
    ? instance?.menuItemLine1Colour
    : global?.menuItemLine1Colour;

  const htmlMenuTitleWeight = instance?.titleWeight
    ? instance?.titleWeight
    : global?.titleWeight;

  const htmlMenuSubtitleWeight = instance?.subtitleWeight
    ? instance?.subtitleWeight
    : global?.subtitleWeight;

  const htmlMenuItemLine1Weight = instance?.line1Weight
    ? instance?.line1Weight
    : global?.line1Weight;

  const htmlMenuItemLine2Weight = instance?.line2Weight
    ? instance?.line2Weight
    : global?.line2Weight;

  const htmlMenuItemPriceWeight = instance?.priceWeight
    ? instance?.priceWeight
    : global?.priceWeight;

  const htmlMenuItemDisclaimerWeight = instance?.disclaimerWeight
    ? instance?.disclaimerWeight
    : global?.disclaimerWeight;

  const htmlMenuItemLine2Colour = instance?.menuItemLine2Colour
    ? instance?.menuItemLine2Colour
    : global?.menuItemLine2Colour;

  const itemLine1NoTx = instance?.menuItemsLine1
    ? instance?.menuItemsLine1
    : global?.menuItemsLine1;

  const itemLine2NoTx = instance?.menuItemsLine2
    ? instance?.menuItemsLine2
    : global?.menuItemsLine2;

  const itemPriceNoTx = instance?.menuItemsPrice
    ? instance?.menuItemsPrice
    : global?.menuItemsPrice;

  const itemDisclaimerNoTx = instance?.menuItemsDisclaimer
    ? instance?.menuItemsDisclaimer
    : global?.menuItemsDisclaimer;

  const sectionDisclaimerLine2NoTx = instance?.sectionDisclaimerText
    ? instance?.sectionDisclaimerText
    : global?.sectionDisclaimerText;

  const sectionDisclaimerLine1NoTx = instance?.sectionDisclaimerTitle
    ? instance?.sectionDisclaimerTitle
    : global?.sectionDisclaimerTitle;

  const sectionTitleNoTx = instance?.sectionTitle
    ? instance?.sectionTitle
    : global?.sectionTitle;

  const sectionSubtitleNoTx = instance?.sectionSubtitle
    ? instance?.sectionSubtitle
    : global?.sectionSubtitle;

  const tabTitleNoTx = instance?.tabTitle
    ? instance?.tabTitle
    : global?.tabTitle;

  const heroCarouselArrowColour = instance?.heroCarouselArrowColour
    ? instance?.heroCarouselArrowColour
    : global?.heroCarouselArrowColour;

  const heroCarouselBackgroundColour = instance?.heroCarouselBackgroundColour
    ? instance?.heroCarouselBackgroundColour
    : global?.heroCarouselBackgroundColour;

  const heroCarouselTextColour = instance?.heroCarouselTextColour
    ? instance?.heroCarouselTextColour
    : global?.heroCarouselTextColour;

  const closeTextColour = instance?.closeTextColour
    ? instance?.closeTextColour
    : global?.closeTextColour;

  const closeIconColour = instance?.closeIconColour
    ? instance?.closeIconColour
    : global?.closeIconColour;

  // Meeting Capacity Summary
  const dimensionColor = instance?.dimensionColor
    ? instance?.dimensionColor
    : global?.dimensionColor;
  const statisticNumber = instance?.statisticNumber
    ? instance?.statisticNumber
    : global?.statisticNumber;
  const statLabelNumber = instance?.statLabelNumber
    ? instance?.statLabelNumber
    : global?.statLabelNumber;
  const separatorColor = instance?.separatorColor
    ? instance?.separatorColor
    : global?.separatorColor;
  const titleAlignment = instance?.titleAlignment
    ? instance?.titleAlignment
    : global?.titleAlignment;

  // Meeting Group
  const iconColor = instance?.iconColor
    ? instance?.iconColor
    : global?.iconColor;

  const mtgButtonStyle = instance?.buttonStyle
    ? instance?.buttonStyle
    : global?.buttonStyle;

  const ctaBackgroundColor = instance?.ctaBackgroundColor
    ? instance?.ctaBackgroundColor
    : global?.ctaBackgroundColor;

  const ctaBackgroundColorHover = instance?.ctaBackgroundColorHover
    ? instance?.ctaBackgroundColorHover
    : global?.ctaBackgroundColorHover;

  const ctaTextColor = instance?.ctaTextColor
    ? instance?.ctaTextColor
    : global?.ctaTextColor;

  const ctaTextColorHover = instance?.ctaTextColorHover
    ? instance?.ctaTextColorHover
    : global?.ctaTextColorHover;

  // Meeting Capacity Charts
  const mtgChartTableHeadingBgColour = instance?.tableHeadings?.backgroundColour
    ? instance?.tableHeadings?.backgroundColour
    : global?.tableHeadings?.backgroundColour;
  const mtgChartTableHeadingTextColour = instance?.tableHeadings?.textColour
    ? instance?.tableHeadings?.textColour
    : global?.tableHeadings?.textColour;
  const mtgChartTableBodyRowTextColour = instance?.tableBody?.rowTextColour
    ? instance?.tableBody?.rowTextColour
    : global?.tableBody?.rowTextColour;
  const mtgChartTableBodyRowTextColourTwo = instance?.tableBody?.rowTextColour2
    ? instance?.tableBody?.rowTextColour2
    : global?.tableBody?.rowTextColour2;
  const mtgChartTableBodyRowBgColour = instance?.tableBody?.rowBackgroundColour
    ? instance?.tableBody?.rowBackgroundColour
    : global?.tableBody?.rowBackgroundColour;

  const mtgChartTableBodyAltRowTextColour = instance?.tableBody
    ?.alternatingRowTextColour
    ? instance?.tableBody?.alternatingRowTextColour
    : global?.tableBody?.alternatingRowTextColour;
  const mtgChartTableBodyAltRowTextColourTwo = instance?.tableBody
    ?.alternatingRowTextColour2
    ? instance?.tableBody?.alternatingRowTextColour2
    : global?.tableBody?.alternatingRowTextColour2;
  const mtgChartTableBodyAltRowBgColour = instance?.tableBody
    ?.alternatingRowBackgroundColour
    ? instance?.tableBody?.alternatingRowBackgroundColour
    : global?.tableBody?.alternatingRowBackgroundColour;

  const mtgChartToggleSwitchColour = instance?.unitTypeSwitch
    ?.toggleSwitchColour
    ? instance?.unitTypeSwitch?.toggleSwitchColour
    : global?.unitTypeSwitch?.toggleSwitchColour;

  const mtgChartToggleOutlineColour = instance?.unitTypeSwitch
    ?.toggleOutlineColour
    ? instance?.unitTypeSwitch?.toggleOutlineColour
    : global?.unitTypeSwitch?.toggleOutlineColour;

  const mtgChartToggleBackgroundColour = instance?.unitTypeSwitch
    ?.toggleBackgroundColour
    ? instance?.unitTypeSwitch?.toggleBackgroundColour
    : global?.unitTypeSwitch?.toggleBackgroundColour;

  const mtgChartTextColourOfUnitTypeLabel = instance?.unitTypeSwitch
    ?.textColourOfUnitTypeLabel
    ? instance?.unitTypeSwitch?.textColourOfUnitTypeLabel
    : global?.unitTypeSwitch?.textColourOfUnitTypeLabel;

  const mtgChartTextColourOfMeasurementType = instance?.unitTypeSwitch
    ?.textColourOfMeasurementType
    ? instance?.unitTypeSwitch?.textColourOfMeasurementType
    : global?.unitTypeSwitch?.textColourOfMeasurementType;

  // Events Calendar

  // Base Ui consts to make props more manageable
  const eventsCalInst = instance?.calendarUiSettings;
  const eventsCalGlbl = global?.calendarUiSettings;

  // Regular Event Type
  const instRegEventCalBtn = eventsCalInst?.regularEventButtons;
  const globRegEventCalBtn = eventsCalGlbl?.regularEventButtons;

  const eventsCalRegularBtnBg = instRegEventCalBtn?.backgroundColor
    ? instRegEventCalBtn?.backgroundColor
    : globRegEventCalBtn?.backgroundColor;
  const eventsCalRegularBtnBgHover = instRegEventCalBtn?.backgroundColorOnHover
    ? instRegEventCalBtn?.backgroundColorOnHover
    : globRegEventCalBtn?.backgroundColorOnHover;
  const eventsCalRegularBtnBorderColor = instRegEventCalBtn?.borderColor
    ? instRegEventCalBtn?.borderColor
    : globRegEventCalBtn?.borderColor;
  const eventsCalRegularBtnBorderColorHover =
    instRegEventCalBtn?.borderColorOnHover
      ? instRegEventCalBtn?.borderColorOnHover
      : globRegEventCalBtn?.borderColorOnHover;
  const eventsCalRegularBtnText = instRegEventCalBtn?.textColor
    ? instRegEventCalBtn?.textColor
    : globRegEventCalBtn?.textColor;
  const eventsCalRegularBtnTextHover = instRegEventCalBtn?.textColorOnHover
    ? instRegEventCalBtn?.textColorOnHover
    : globRegEventCalBtn?.textColorOnHover;

  // Special Event Type
  const instSpecialEventCalBtn = eventsCalInst?.specialEventButtons;
  const globSpecialEventCalBtn = eventsCalGlbl?.specialEventButtons;

  const eventsCalSpecialBtnBg = instSpecialEventCalBtn?.backgroundColor
    ? instSpecialEventCalBtn?.backgroundColor
    : globSpecialEventCalBtn?.backgroundColor;
  const eventsCalSpecialBtnBgHover =
    instSpecialEventCalBtn?.backgroundColorOnHover
      ? instSpecialEventCalBtn?.backgroundColorOnHover
      : globSpecialEventCalBtn?.backgroundColorOnHover;
  const eventsCalSpecialBtnBorderColor = instSpecialEventCalBtn?.borderColor
    ? instSpecialEventCalBtn?.borderColor
    : globSpecialEventCalBtn?.borderColor;
  const eventsCalSpecialBtnBorderColorHover =
    instSpecialEventCalBtn?.borderColorOnHover
      ? instSpecialEventCalBtn?.borderColorOnHover
      : globSpecialEventCalBtn?.borderColorOnHover;
  const eventsCalSpecialBtnText = instSpecialEventCalBtn?.textColor
    ? instSpecialEventCalBtn?.textColor
    : globSpecialEventCalBtn?.textColor;
  const eventsCalSpecialBtnTextHover = instSpecialEventCalBtn?.textColorOnHover
    ? instSpecialEventCalBtn?.textColorOnHover
    : globSpecialEventCalBtn?.textColorOnHover;

  // Additional Button Type
  const instAddlCalBtn = eventsCalInst?.additionalCalendarButtons;
  const globAddlCalBtn = eventsCalGlbl?.additionalCalendarButtons;

  const eventsCalAdditionalBtnBg = instAddlCalBtn?.backgroundColor
    ? instAddlCalBtn?.backgroundColor
    : globAddlCalBtn?.backgroundColor;
  const eventsCalAdditionalBtnBgHover = instAddlCalBtn?.backgroundColorOnHover
    ? instAddlCalBtn?.backgroundColorOnHover
    : globAddlCalBtn?.backgroundColorOnHover;
  const eventsCalAdditionalBtnBorderColor = instAddlCalBtn?.borderColor
    ? instAddlCalBtn?.borderColor
    : globAddlCalBtn?.borderColor;
  const eventsCalAdditionalBtnBorderColorHover =
    instAddlCalBtn?.borderColorOnHover
      ? instAddlCalBtn?.borderColorOnHover
      : globAddlCalBtn?.borderColorOnHover;
  const eventsCalAdditionalBtnText = instAddlCalBtn?.textColor
    ? instAddlCalBtn?.textColor
    : globAddlCalBtn?.textColor;
  const eventsCalAdditionalBtnTextHover = instAddlCalBtn?.textColorOnHover
    ? instAddlCalBtn?.textColorOnHover
    : globAddlCalBtn?.textColorOnHover;

  // Events Cal Backgrounds
  const eventsCalendarComponentBgColor = instance
    ?.componentAndContentBackgroundColors?.calendarComponentBgColor
    ? instance?.componentAndContentBackgroundColors?.calendarComponentBgColor
    : global?.componentAndContentBackgroundColors?.calendarComponentBgColor;
  const eventsCalendarDesktopGridBgColor = instance
    ?.componentAndContentBackgroundColors?.calendarDesktopGridBgColor
    ? instance?.componentAndContentBackgroundColors?.calendarDesktopGridBgColor
    : global?.componentAndContentBackgroundColors?.calendarDesktopGridBgColor;
  const eventsCalendarDatePickerBgColor = instance
    ?.componentAndContentBackgroundColors?.calendarDatePickerBgColor
    ? instance?.componentAndContentBackgroundColors?.calendarDatePickerBgColor
    : global?.componentAndContentBackgroundColors?.calendarDatePickerBgColor;
  const eventsCalDailyEventsSectionBgColor =
    eventsCalInst?.dailyEventsSectionBgColor
      ? eventsCalInst?.dailyEventsSectionBgColor
      : eventsCalGlbl?.dailyEventsSectionBgColor;

  // Events Cal Other Settings
  const eventsCalAdditionalTextFilterColor =
    eventsCalInst?.additionalTextFilterColor
      ? eventsCalInst?.additionalTextFilterColor
      : eventsCalGlbl?.additionalTextFilterColor;
  const eventsCalBaseIconColor = eventsCalInst?.baseIconColor
    ? eventsCalInst?.baseIconColor
    : eventsCalGlbl?.baseIconColor;
  const eventsCalBaseTextColor = eventsCalInst?.baseTextColor
    ? eventsCalInst?.baseTextColor
    : eventsCalGlbl?.baseTextColor;
  const eventsCalDatePickerResetButtonBg =
    eventsCalInst?.datePickerResetButtonBg
      ? eventsCalInst?.datePickerResetButtonBg
      : eventsCalGlbl?.datePickerResetButtonBg;
  const eventsCalDatePickerResetButtonText =
    eventsCalInst?.datePickerResetButtonText
      ? eventsCalInst?.datePickerResetButtonText
      : eventsCalGlbl?.datePickerResetButtonText;
  const eventsCalfilterCheckboxColor = eventsCalInst?.filterCheckboxColor
    ? eventsCalInst?.filterCheckboxColor
    : eventsCalGlbl?.filterCheckboxColor;
  const eventsCalSelectedDatePickerDateBg =
    eventsCalInst?.selectedDatePickerDateBg
      ? eventsCalInst?.selectedDatePickerDateBg
      : eventsCalGlbl?.selectedDatePickerDateBg;
  const eventsCalSelectedDatePickerDateRangeBg =
    eventsCalInst?.selectedDatePickerDateRangeBg
      ? eventsCalInst?.selectedDatePickerDateRangeBg
      : eventsCalGlbl?.selectedDatePickerDateRangeBg;
  const eventsCalSelectedDatepickerDateText =
    eventsCalInst?.selectedDatepickerDateText
      ? eventsCalInst?.selectedDatepickerDateText
      : eventsCalGlbl?.selectedDatepickerDateText;
  const eventsCalUsesRegularEventButtonColors =
    eventsCalInst?.usesRegularEventButtonColors
      ? eventsCalInst?.usesRegularEventButtonColors
      : eventsCalGlbl?.usesRegularEventButtonColors;

  // Restaurants Other Settings

  const topSectionBackgroundColour = instance?.topSectionBackgroundColour
    ? instance?.topSectionBackgroundColour
    : global?.topSectionBackgroundColour;

  const hoursSeperatorColour = instance?.hoursSeperatorColour
    ? instance?.hoursSeperatorColour
    : global?.hoursSeperatorColour;

  const hideTileBorder = instance?.hideTileBorder
    ? instance?.hideTileBorder
    : global?.hideTileBorder;

  const cuisineTypeTextColour = instance?.cuisineTypeTextColour
    ? instance?.cuisineTypeTextColour
    : global?.cuisineTypeTextColour;

  const cuisineTypeIconColour = instance?.cuisineTypeIconColour
    ? instance?.cuisineTypeIconColour
    : global?.cuisineTypeIconColour;

  const tileBorderColour = instance?.tileBorderColour
    ? instance?.tileBorderColour
    : global?.tileBorderColour;

  // Interactive Map V2

  const mapControlsBackgroundColor = instance?.mapControlsBackgroundColor
    ? instance?.mapControlsBackgroundColor
    : global?.mapControlsBackgroundColor;

  const mapControlsColor = instance?.mapControlsColor
    ? instance?.mapControlsColor
    : global?.mapControlsColor;

  const mapControlsPlacement = instance?.mapControlsPlacement
    ? instance?.mapControlsPlacement
    : global?.mapControlsPlacement;

  const selectedTabBackgroundColor = instance?.selectedTabBackgroundColor
    ? instance?.selectedTabBackgroundColor
    : global?.selectedTabBackgroundColor;

  const selectedTabTextColor = instance?.selectedTabTextColor
    ? instance?.selectedTabTextColor
    : global?.selectedTabTextColor;

  const tabBackgroundColor = instance?.tabBackgroundColor
    ? instance?.tabBackgroundColor
    : global?.tabBackgroundColor;

  const tabTextColor = instance?.tabTextColor
    ? instance?.tabTextColor
    : global?.tabTextColor;

  const pinColor = instance?.pinColor ? instance?.pinColor : global?.pinColor;

  const pinAccentColor = instance?.pinAccentColor
    ? instance?.pinAccentColor
    : global?.pinAccentColor;

  const mobileFilterDropdownBackgroundColor =
    instance?.mobileFilterDropdownBackgroundColor
      ? instance?.mobileFilterDropdownBackgroundColor
      : global?.mobileFilterDropdownBackgroundColor;

  const mobileFilterDropdownTextColor = instance?.mobileFilterDropdownTextColor
    ? instance?.mobileFilterDropdownTextColor
    : global?.mobileFilterDropdownTextColor;

  // Social Media Component
  const socialMediaIconColor = instance?.socialMediaIconColor
    ? instance?.socialMediaIconColor
    : global?.socialMediaIconColor;

  /* ==== Add all vars to an object ==== */
  const obj = {
    componentBackgroundImage: componentBackgroundImage,
    componentBackgroundColor: componentBackgroundColor,
    componentBackgroundSize: componentBackgroundSize,
    componentBackgroundRepeat: componentBackgroundRepeat,
    componentBackgroundPosition: componentBackgroundPosition,
    contentBackgroundImage: contentBackgroundImage,
    contentBackgroundColor: contentBackgroundColor,
    contentBackgroundSize: contentBackgroundSize,
    contentBackgroundRepeat: contentBackgroundRepeat,
    contentBackgroundPosition: contentBackgroundPosition,
    textAlignment: textAlignment,
    buttonAlignment: buttonAlignment,
    containerMaxWidth: containerMaxWidth,
    titleColor: titleColor,
    subtitleColor: subtitleColor,
    textColor: textColor,
    showHide: showHide,
    headingsNoTranslate: HeadingsNoTranslate,
    hideAnimations: hideAnimations,
    hideParallax: hideParallax,
    animationDirection: animationDirection,
    sliderAnimations: sliderAnimations,
    zoomOnHover: zoomOnHover,
    contentBoxBackgroundOpacity: contentBoxBackgroundOpacity,
    columnBoxBackgroundColor: columnBoxBackgroundColor,
    columnBoxTextColor: columnBoxTextColor,
    columnBoxIconColor: columnBoxIconColor,
    contentBoxHorizontal: contentBoxHorizontal,
    contentBoxVertical: contentBoxVertical,
    gradientPosition: gradientPosition,
    gradientToggle: gradientToggle,
    mastheadHeight: mastheadHeight,
    mastheadSpacer: mastheadSpacer,
    mastheadSpacerColour: mastheadSpacerColour,
    overlayBackgroundColour: overlayBackgroundColour,
    overlayBackgroundOpacity: overlayBackgroundOpacity,
    overlayXPosition: overlayXPosition,
    overlayYPosition: overlayYPosition,
    mastheadGradient: mastheadGradient,
    layoutDirection: layoutDirection,
    offset: offset,
    padding: padding,
    borderBottomColour: borderBottomColour,
    borderTopColour: borderTopColour,
    seperatorColour: seperatorColour,
    horizontalDirection: horizontalDirection,
    verticalDirection: verticalDirection,
    mediaAndCopyPadding: mediaAndCopyPadding,
    mediaAndCopyRowMargin: mediaAndCopyRowPadding,
    smallImageBorderColor: smallImageBorderColour,
    mediaAndCopyCarouselHeaderTitleColour:
      mediaAndCopyCarouselHeaderTitleColour,
    mediaAndCopyCarouselHeaderCopyColour: mediaAndCopyCarouselHeaderCopyColour,
    mediaAndCopyCarouselUiPrimary: mediaAndCopyCarouselUiPrimary,
    mediaAndCopyCarouselUiSecondary: mediaAndCopyCarouselUiSecondary,
    filterBackgroundColor: filterBackgroundColor,
    filterTitleColor: filterTitleColor,
    filterRuleColor: filterRuleColor,
    filterResultsColor: filterResultsColor,
    rateBackgroundColor: rateBackgroundColor,
    rateFromColor: rateFromColor,
    rateFigureColor: rateFigureColor,
    rateErrorColor: rateErrorColor,
    rateCurrencyColor: rateCurrencyColor,
    popunderTitleColor: popunderTitleColor,
    popunderTextColor: popunderTextColor,
    popunderSubtitleColor: popunderSubtitleColor,
    popunderBackgroundColor: popunderBackgroundColor,
    popunderRuleColor: popunderRuleColor,
    popunderCtaBackgroundColor: popunderCtaBackgroundColor,
    popunderCtaBackgroundColorHover: popunderCtaBackgroundColorHover,
    popunderCtaBorderColor: popunderCtaBorderColor,
    popunderCtaBorderColorHover: popunderCtaBorderColorHover,
    popunderCtaTextColor: popunderCtaTextColor,
    popunderCtaTextColorHover: popunderCtaTextColorHover,
    paginationTextColor: paginationTextColor,
    paginationSeperatorColor: paginationSeperatorColor,
    paginationBackgroundColor: paginationBackgroundColor,
    controlsIconColor: controlsIconColor,
    controlsBackgroundColor: controlsBackgroundColor,
    activeTileColor: activeTileColor,
    activeTileSeperatorColor: activeTileSeperatorColor,
    activeTileTextColor: activeTileTextColor,
    viewDetailsColor: viewDetailsColor,
    viewDetailsTextColor: viewDetailsTextColor,
    viewDetailsBorderColor: viewDetailsBorderColor,
    viewDetailsBackgroundColor: viewDetailsBackgroundColor,
    activeViewDetailsBackgroundColor: activeViewDetailsBackgroundColor,
    activeViewDetailsBorderColor: activeViewDetailsBorderColor,
    activeViewDetailsTextColor: activeViewDetailsTextColor,
    bookCtaBackgroundColor: bookCtaBackgroundColor,
    bookCtaBorderColor: bookCtaBorderColor,
    bookCtaTextColor: bookCtaTextColor,
    activeBookCtaBackgroundColor: activeBookCtaBackgroundColor,
    activeBookCtaBorderColor: activeBookCtaBorderColor,
    activeBookCtaTextColor: activeBookCtaTextColor,
    disableDropShadow: disableDropShadow,
    feeTransparencyDefaultColor: feeTransparencyDefaultColor,
    feeTransparencyActiveColor: feeTransparencyActiveColor,
    feeTransparencyTextInExpandedDropdown:
      feeTransparencyTextInExpandedDropdown,
    contentBackgroundOpacity: contentBackgroundOpacity,
    paddingBottom: {
      paddingBottomDesktop: paddingBottomDesktop,
      paddingBottomTablet: paddingBottomTablet,
      paddingBottomMobile: paddingBottomMobile,
    },
    paddingTop: {
      paddingTopDesktop: paddingTopDesktop,
      paddingTopTablet: paddingTopTablet,
      paddingTopMobile: paddingTopMobile,
    },
    iconBlockColour: iconBlockColour,
    labelBlockColour: labelBlockColour,
    iconCoreColour: iconCoreColour,
    labelCoreColour: labelCoreColour,
    twoColumnIconColour: twoColumnIconColour,
    twoColumnLabelColour: twoColumnLabelColour,
    hotelPoliciesBorderColour: hotelPoliciesBorderColour,
    hotelPoliciesIconIndicatorColour: hotelPoliciesIconIndicatorColour,
    hotelPoliciesIconIndicatorColourExpanded:
      hotelPoliciesIconIndicatorColourExpanded,
    hotelOpenPanelBackgroundColour: hotelOpenPanelBackgroundColour,
    accordionBorderColour: accordionBorderColour,
    accordionIconIndicatorColour: accordionIconIndicatorColour,
    accordionIconIndicatorColourExpanded: accordionIconIndicatorColourExpanded,
    accordionOpenPanelBackgroundColour: accordionOpenPanelBackgroundColour,
    activeTabAccentColour: activeTabAccentColour,
    activeTabTextColour: activeTabTextColour,
    tableAltRowBackgroundColour: tableAltRowBackgroundColour,
    tableAltRowTextColour: tableAltRowTextColour,
    tableHeaderBackgroundColour: tableHeaderBackgroundColour,
    tableHeaderTextColour: tableHeaderTextColour,
    tableRowBackgroundColour: tableRowBackgroundColour,
    tableRowTextColour: tableRowTextColour,
    galleryWallCaptionColour: galleryWallCaptionColour,
    htmlMenuTabBackgroundColour: htmlMenuTabBackgroundColour,
    htmlMenuTabTextColour: htmlMenuTabTextColour,
    htmlMenuTabUnderlineColour: htmlMenuTabUnderlineColour,
    htmlMenuActiveTabBackgroundColour: htmlMenuActiveTabBackgroundColour,
    htmlMenuActiveTabTextColour: htmlMenuActiveTabTextColour,
    htmlMenuActiveTabUnderlineColour: htmlMenuActiveTabUnderlineColour,
    htmlMenuItemLine1Colour: htmlMenuItemLine1Colour,
    htmlMenuItemLine2Colour: htmlMenuItemLine2Colour,
    htmlMenuTitleWeight: htmlMenuTitleWeight,
    htmlMenuSubtitleWeight: htmlMenuSubtitleWeight,
    htmlMenuItemLine1Weight: htmlMenuItemLine1Weight,
    htmlMenuItemLine2Weight: htmlMenuItemLine2Weight,
    htmlMenuItemPriceWeight: htmlMenuItemPriceWeight,
    htmlMenuItemDisclaimerWeight: htmlMenuItemDisclaimerWeight,
    itemLine1NoTx: itemLine1NoTx,
    itemLine2NoTx: itemLine2NoTx,
    itemPriceNoTx: itemPriceNoTx,
    itemDisclaimerNoTx: itemDisclaimerNoTx,
    sectionDisclaimerLine2NoTx: sectionDisclaimerLine2NoTx,
    sectionDisclaimerLine1NoTx: sectionDisclaimerLine1NoTx,
    sectionTitleNoTx: sectionTitleNoTx,
    sectionSubtitleNoTx: sectionSubtitleNoTx,
    tabTitleNoTx: tabTitleNoTx,
    heroCarouselArrowColour: heroCarouselArrowColour,
    heroCarouselBackgroundColour: heroCarouselBackgroundColour,
    heroCarouselTextColour: heroCarouselTextColour,
    closeTextColour: closeTextColour,
    closeIconColour: closeIconColour,
    dimensionColor: dimensionColor,
    statisticNumber: statisticNumber,
    statLabelNumber: statLabelNumber,
    separatorColor: separatorColor,
    titleAlignment: titleAlignment,
    iconColor: iconColor,
    mtgButtonStyle: mtgButtonStyle,
    ctaBackgroundColor: ctaBackgroundColor,
    ctaBackgroundColorHover: ctaBackgroundColorHover,
    ctaTextColor: ctaTextColor,
    ctaTextColorHover: ctaTextColorHover,
    mtgChartTableHeadingBgColour: mtgChartTableHeadingBgColour,
    mtgChartTableHeadingTextColour: mtgChartTableHeadingTextColour,
    mtgChartTableBodyRowTextColour: mtgChartTableBodyRowTextColour,
    mtgChartTableBodyRowTextColourTwo: mtgChartTableBodyRowTextColourTwo,
    mtgChartTableBodyRowBgColour: mtgChartTableBodyRowBgColour,
    mtgChartTableBodyAltRowTextColour: mtgChartTableBodyAltRowTextColour,
    mtgChartTableBodyAltRowTextColourTwo: mtgChartTableBodyAltRowTextColourTwo,
    mtgChartTableBodyAltRowBgColour: mtgChartTableBodyAltRowBgColour,
    mtgChartToggleSwitchColour: mtgChartToggleSwitchColour,
    mtgChartToggleOutlineColour: mtgChartToggleOutlineColour,
    mtgChartToggleBgColour: mtgChartToggleBackgroundColour,
    mtgChartUnitTypeLabelColour: mtgChartTextColourOfUnitTypeLabel,
    mtgChartMeasureTypeColour: mtgChartTextColourOfMeasurementType,
    eventsCalendarComponentBgColor: eventsCalendarComponentBgColor,
    eventsCalendarDesktopGridBgColor: eventsCalendarDesktopGridBgColor,
    eventsCalendarDatePickerBgColor: eventsCalendarDatePickerBgColor,
    eventsCalAdditionalBtnBg: eventsCalAdditionalBtnBg,
    eventsCalAdditionalBtnBgHover: eventsCalAdditionalBtnBgHover,
    eventsCalAdditionalBtnBorderColor: eventsCalAdditionalBtnBorderColor,
    eventsCalAdditionalBtnBorderColorHover:
      eventsCalAdditionalBtnBorderColorHover,
    eventsCalAdditionalBtnText: eventsCalAdditionalBtnText,
    eventsCalAdditionalBtnTextHover: eventsCalAdditionalBtnTextHover,

    eventsCalRegularBtnBg: eventsCalRegularBtnBg,
    eventsCalRegularBtnBgHover: eventsCalRegularBtnBgHover,
    eventsCalRegularBtnBorderColor: eventsCalRegularBtnBorderColor,
    eventsCalRegularBtnBorderColorHover: eventsCalRegularBtnBorderColorHover,
    eventsCalRegularBtnText: eventsCalRegularBtnText,
    eventsCalRegularBtnTextHover: eventsCalRegularBtnTextHover,

    eventsCalSpecialBtnBg: eventsCalSpecialBtnBg,
    eventsCalSpecialBtnBgHover: eventsCalSpecialBtnBgHover,
    eventsCalSpecialBtnBorderColor: eventsCalSpecialBtnBorderColor,
    eventsCalSpecialBtnBorderColorHover: eventsCalSpecialBtnBorderColorHover,
    eventsCalSpecialBtnText: eventsCalSpecialBtnText,
    eventsCalSpecialBtnTextHover: eventsCalSpecialBtnTextHover,

    eventsCalDailyEventsSectionBgColor: eventsCalDailyEventsSectionBgColor,
    eventsCalAdditionalTextFilterColor: eventsCalAdditionalTextFilterColor,
    eventsCalBaseIconColor: eventsCalBaseIconColor,
    eventsCalBaseTextColor: eventsCalBaseTextColor,
    eventsCalDatePickerResetButtonBg: eventsCalDatePickerResetButtonBg,
    eventsCalDatePickerResetButtonText: eventsCalDatePickerResetButtonText,
    eventsCalfilterCheckboxColor: eventsCalfilterCheckboxColor,
    eventsCalSelectedDatePickerDateBg: eventsCalSelectedDatePickerDateBg,
    eventsCalSelectedDatePickerDateRangeBg:
      eventsCalSelectedDatePickerDateRangeBg,
    eventsCalSelectedDatepickerDateText: eventsCalSelectedDatepickerDateText,
    eventsCalUsesRegularEventButtonColors:
      eventsCalUsesRegularEventButtonColors,
    topSectionBackgroundColour: topSectionBackgroundColour,
    hoursSeperatorColour: hoursSeperatorColour,
    hideTileBorder: hideTileBorder,
    cuisineTypeTextColour: cuisineTypeTextColour,
    cuisineTypeIconColour: cuisineTypeIconColour,
    tileBorderColour: tileBorderColour,
    mapControlsBackgroundColor: mapControlsBackgroundColor,
    mapControlsColor: mapControlsColor,
    mapControlsPlacement: mapControlsPlacement,
    selectedTabBackgroundColor: selectedTabBackgroundColor,
    selectedTabTextColor: selectedTabTextColor,
    tabBackgroundColor: tabBackgroundColor,
    tabTextColor: tabTextColor,
    pinColor: pinColor,
    pinAccentColor: pinAccentColor,
    mobileFilterDropdownTextColor: mobileFilterDropdownTextColor,
    mobileFilterDropdownBackgroundColor: mobileFilterDropdownBackgroundColor,
    socialMediaIconColor: socialMediaIconColor,
  };

  return obj;
}

/**
 * Returns tailwind gradient classes along with additional styling
 * bg-gradient-to-r | bg-gradient-to-l | bg-gradient-to-t | bg-gradient-to-b
 * using data from wp
 */
export function GIS_Gradient(gradientPosition: string) {
  let gradientClasses;
  switch (gradientPosition) {
    case 'bg-gradient-to-r':
      gradientClasses = `${gradientPosition} h-full w-full left-0`;
      break;
    case 'bg-gradient-to-l':
      gradientClasses = `${gradientPosition} h-full w-full right-0`;
      break;
    case 'bg-gradient-to-t':
      gradientClasses = `${gradientPosition} h-3/5 w-full bottom-0`;
      break;
    case 'bg-gradient-to-b':
      gradientClasses = `${gradientPosition} h-3/5 w-full top-0`;
      break;
    default:
      gradientClasses = 'bg-gradient-to-r h-full w-full left-0';
      break;
  }
  return gradientClasses;
}

/**
 * Returns - left | right | center
 * based on flex alignments - start | end | center
 */
export function GIS_TextAlignment(alignment: string | undefined) {
  let textAlignment;
  switch (alignment) {
    case 'flex-start':
      textAlignment = 'left' as const;
      break;
    case 'center':
      textAlignment = 'center' as const;
      break;
    case 'flex-end':
      textAlignment = 'right' as const;
      break;
  }
  return textAlignment;
}

export function GIS_FontSizer(size: string) {
  let fontSize;
  switch (size) {
    case 'small':
      fontSize = 'text-lg md:text-xl';
      break;
    case 'medium':
      fontSize = 'text-xl md:text-2xl';
      break;
    case 'large':
      fontSize = 'text-2xl md:text-3xl';
      break;
  }
  return fontSize;
}

interface PaddValues {
  desktopTop: string | undefined;
  tabletTop: string | undefined;
  mobileTop: string | undefined;
  desktopBottom: string | undefined;
  tabletBottom: string | undefined;
  mobileBottom: string | undefined;
}

/**
 * Converts WP padding styles into single string - defaults to 16px
 *
 * @param paddingTop
 * @param paddingBottom
 * @returns A tailwind string
 */

//DO NOT DELETE THIS NEXT LINE - Goes with GIS_Padder
// className='pt-0 pt-0.5 pb-0 pb-0.5 md:pt-0 md:pt-0.5 md:pb-0 md:pb-0.5 lg:pt-0 lg:pt-0.5 lg:pb-0 lg:pb-0.5 pt-1 pt-1.5 pb-1 pb-1.5 md:pt-1 md:pt-1.5 md:pb-1 md:pb-1.5 lg:pt-1 lg:pt-1.5 lg:pb-1 lg:pb-1.5 pt-2 pt-2.5 pb-2 pb-2.5 md:pt-2 md:pt-2.5 md:pb-2 md:pb-2.5 lg:pt-2 lg:pt-2.5 lg:pb-2 lg:pb-2.5 pt-3 pt-3.5 pb-3 pb-3.5 md:pt-3 md:pt-3.5 md:pb-3 md:pb-3.5 lg:pt-3 lg:pt-3.5 lg:pb-3 lg:pb-3.5 pt-4 pb-4 md:pt-4 md:pb-4 lg:pt-4 lg:pb-4 pt-5 pb-5 md:pt-5 md:pb-5 lg:pt-5 lg:pb-5 pt-6 pb-6 md:pt-6 md:pb-6 lg:pt-6 lg:pb-6 pt-7 pb-7 md:pt-7 md:pb-7 lg:pt-7 lg:pb-7 pt-8 pb-8 md:pt-8 md:pb-8 lg:pt-8 lg:pb-8 pt-9 pb-9 md:pt-9 md:pb-9 lg:pt-9 lg:pb-9 pt-10 pb-10 md:pt-10 md:pb-10 lg:pt-10 lg:pb-10 pt-11 pb-11 md:pt-11 md:pb-11 lg:pt-11 lg:pb-11 pt-12 pb-12 md:pt-12 md:pb-12 lg:pt-12 lg:pb-12 pt-14 pb-14 md:pt-14 md:pb-14 lg:pt-14 lg:pb-14 pt-16 pb-16 md:pt-16 md:pb-16 lg:pt-16 lg:pb-16 pt-20 pb-20 md:pt-20 md:pb-20 lg:pt-20 lg:pb-20 pt-24 pb-24 md:pt-24 md:pb-24 lg:pt-24 lg:pb-24 pt-28 pb-28 md:pt-28 md:pb-28 lg:pt-28 lg:pb-28 pt-32 pb-32 md:pt-32 md:pb-32 lg:pt-32 lg:pb-32 pt-36 pb-36 md:pt-36 md:pb-36 lg:pt-36 lg:pb-36 pt-40 pb-40 md:pt-40 md:pb-40 lg:pt-40 lg:pb-40 pt-44 pb-44 md:pt-44 md:pb-44 lg:pt-44 lg:pb-44 pt-48 pb-48 md:pt-48 md:pb-48 lg:pt-48 lg:pb-48 pt-52 pb-52 md:pt-52 md:pb-52 lg:pt-52 lg:pb-52 pt-56 pb-56 md:pt-56 md:pb-56 lg:pt-56 lg:pb-56 pt-60 pb-60 md:pt-60 md:pb-60 lg:pt-60 lg:pb-60 pt-64 pb-64 md:pt-64 md:pb-64 lg:pt-64 lg:pb-64 pt-72 pb-72 md:pt-72 md:pb-72 lg:pt-72 lg:pb-72 pt-80 pb-80 md:pt-80 md:pb-80 lg:pt-80 lg:pb-80 pt-96 pb-96 md:pt-96 md:pb-96 lg:pt-96 lg:pb-96'

export function GIS_Padder(
  paddingTop: StyleObject['paddingTop'],
  paddingBottom: StyleObject['paddingBottom']
): string {
  const pVals: PaddValues = {
    desktopTop: paddingTop?.paddingTopDesktop,
    tabletTop: paddingTop?.paddingTopTablet,
    mobileTop: paddingTop?.paddingTopMobile,
    desktopBottom: paddingBottom?.paddingBottomDesktop,
    tabletBottom: paddingBottom?.paddingBottomTablet,
    mobileBottom: paddingBottom?.paddingBottomMobile,
  };

  const noneToZero = (values: PaddValues): PaddValues => {
    for (const [key, value] of Object.entries(values)) {
      if (value === 'none') values[key as keyof PaddValues] = '0';
    }
    return values;
  };
  noneToZero(pVals);

  return `pt-${
    pVals?.mobileTop || pVals?.tabletTop || pVals?.desktopTop || '11'
  } pb-${
    pVals?.mobileBottom || pVals?.tabletBottom || pVals?.desktopBottom || '11'
  }${pVals?.tabletTop ? ` md:pt-${pVals?.tabletTop}` : ''}${
    pVals?.tabletBottom ? ` md:pb-${pVals?.tabletBottom}` : ''
  }${pVals?.desktopTop ? ` lg:pt-${pVals?.desktopTop}` : ''}${
    pVals?.desktopBottom ? ` lg:pb-${pVals?.desktopBottom}` : ''
  }`;
}

import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import { Maybe } from '@dx-ui/gql-types';
import { Accordion } from '@dx-ui/osc-accordion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { GenericModal, GenericModalProps } from '../../global/generic-modal';
import { ImageSlider } from '../../image-slider';
import { HeadingStyle } from '../../includes/heading-style';
import contentStyles from '../../css/contentStyle.module.css';
import cx from 'classnames';
import { ArrayElement, getIcon, GuestsState, Icon, mergeRooms } from '../utils';
import { RoomsCTA, RoomsCTAProps } from './rooms-cta';
import { HandleWYSIWYGContent } from '../../functions/helper';
import { sanitize } from '@curated-property/utils';
import { sendReward } from '@dx-ui/framework-conductrics';

export interface RoomDetailsModalProps extends GenericModalProps {
  room?: ArrayElement<ReturnType<typeof mergeRooms>>;
  className?: string;
  hasPrices?: boolean;
  rate?: string;
  arrivalDate?: string;
  departureDate?: Maybe<string>;
  ctyhocn?: string;
  textAlignment?: any;
  currency?: any;
  resEnabled?: boolean;
  associatedHotels?: string[];
  guests?: GuestsState;
  icons?: Icon[];
  ctaData?: RoomsCTAProps;
  inlineStyles?: {
    [name: string]: any;
    roomDetailsModalBackgroundColor?: string;
    roomDetailsModalTextColor?: string;
    roomDetailsModalAccentColor?: string;
    roomDetailsModalIconColor?: string;
  };
}

export const RoomDetailsModal = (props: RoomDetailsModalProps) => {
  const {
    room,
    ctaData,
    resEnabled,
    modalTitle,
    associatedHotels,
    inlineStyles,
    icons,
  } = props;

  const { t } = useTranslation();

  const preloadImages = [];

  if (!room) return null;

  const wpImages = room?.carouselImages?.map((i) => ({
    url: i?.image?.sourceUrl || '',
    alt: i?.image?.altText || '',
  }));

  const corePlusImages = room?.carousel?.map((img, i) => {
    img?.variants?.map((innerImg, o) => {
      // If a medium variant exists, use that and tweak the image size
      if (innerImg?.size === 'md') {
        const newImgUrl = innerImg?.url
          .replace('rw=528', 'rw=807')
          .replace('rh=316', 'rh=484');
        // reset url to use the variant image
        img = { url: newImgUrl, altText: img?.altText };
      }
    });

    preloadImages.push(
      <img
        key={i}
        className="hidden"
        aria-hidden="true"
        src={img?.url}
        alt=""
      />
    );

    return {
      url: img?.url || '',
      alt: img?.altText || '',
    };
  });

  const featureGroups = {
    comfort: {
      label: t('rooms.forYourComfort'),
      features: room?.features?.filter((feat) => feat?.category == 'comfort'),
    },
    convenience: {
      label: t('rooms.forYourConvenience'),
      features: room?.features?.filter(
        (feat) => feat?.category == 'convenience'
      ),
    },
    confidence: {
      label: t('rooms.forYourConfidence'),
      features: room?.features?.filter(
        (feat) => feat?.category == 'confidence'
      ),
    },
  } as {
    confidence: {
      label: string;
      features: ArrayElement<ReturnType<typeof mergeRooms>>[];
    };
    comfort: {
      label: string;
      features: ArrayElement<ReturnType<typeof mergeRooms>>[];
    };
    convenience: {
      label: string;
      features: ArrayElement<ReturnType<typeof mergeRooms>>[];
    };
  };

  return (
    <GenericModal
      modalTitle={modalTitle}
      {...props}
      modalChild={
        <div
          className="px-4 py-16 container max-w-screen-2xl w-fit"
          style={{
            backgroundColor:
              inlineStyles?.roomDetailsModalBackgroundColor || undefined,
          }}
        >
          <style>
            {`
            .rooms-modal-accordion svg path, .rooms-modal-accordion svg polyline {
              stroke: ${inlineStyles?.roomDetailsModalIconColor}
            }
          `}
          </style>
          <div className="flex flex-col lg:flex-row">
            {/* Copy */}
            <div className="lg:px-8 w-full lg:w-1/2">
              <HeadingStyle styledAs="h3" text={props?.room?.roomTypeName} />

              <div
                className={cx(
                  'mt-8 text-base wp-content',
                  contentStyles.listStyle,
                  contentStyles.paragraphStyle
                )}
                style={{
                  color: inlineStyles?.roomDetailsModalTextColor || undefined,
                }}
                dangerouslySetInnerHTML={{
                  __html: HandleWYSIWYGContent(props?.room?.desc || ''),
                }}
              ></div>
              <div className="mt-8">
                {props?.hasPrices && !props?.room?.moreRatesFromRate && (
                  <button
                    disabled={true}
                    className="popunder-cta w-full py-3 rtl:mr-0 rtl:ml-3  font-bold text-center btn btn-primary"
                  >
                    {t('roomNotAvailable')}
                  </button>
                )}
                {/* If none of the rooms have prices we don't really know if this room is available or not, show a button that just says "check check availability" */}
                {/* todo: OHW link builder to go to rooms page (or rates page) */}
                {resEnabled || associatedHotels?.length > 0 ? (
                  <RoomsCTA
                    {...ctaData}
                    inlineStyles={props?.inlineStyles}
                    onClick={() => sendReward('EngageCheckRatesCTAOnModal')}
                  />
                ) : null}
              </div>
              <div>
                {room?.moreRatesFromRate?.serviceChargeDetails && (
                  <div
                    className="mt-4 text-left text-xs lg:text-sm pb-2 my-0 text-primary"
                    data-testid="feeTransparencyInPopunder"
                    style={{
                      color: inlineStyles?.feeTransparencyTextInExpandedDropdown
                        ? inlineStyles?.feeTransparencyTextInExpandedDropdown
                        : null,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        room?.moreRatesFromRate?.serviceChargeDetails || ''
                      ),
                    }}
                  />
                )}
              </div>
            </div>
            {/* Image Slider */}
            <div className="w-full lg:w-1/2 flex justify-center mt-8 lg:mt-0">
              {!corePlusImages.length &&
              (!wpImages || wpImages === undefined) ? (
                ''
              ) : (
                <div className="max-w-3xl w-full relative self-start">
                  <ImageSlider
                    images={wpImages ? wpImages : corePlusImages}
                    sectionHeading={room?.roomTypeName}
                    controlLayout="overlayed"
                    styleOptions={{
                      uiBackgroundColour: inlineStyles?.controlsBackgroundColor,
                      uiControlsColour: inlineStyles?.controlsIconColor,
                      paginationBackgroundColour:
                        inlineStyles?.paginationBackgroundColor,
                      paginationTextColour: inlineStyles?.paginationTextColor,
                      paginationSeperatorColour:
                        inlineStyles?.paginationSeperatorColor,
                      sliderAnimations: inlineStyles?.sliderAnimations,
                    }}
                  />
                </div>
              )}
              {preloadImages.map((i) => {
                return i;
              })}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between mt-8">
            {/* bottom left */}
            <div className="w-full sm:w-1/2">
              <div className="px-8">
                <HeadingStyle
                  styledAs="h6"
                  className="font-bold"
                  text={t('rooms.roomHighlights')}
                />
                <div className="mt-4 bg-pink">
                  <ul className="list-none">
                    {icons.map((icon) => {
                      const iconData = getIcon(icon);
                      if (!iconData.name) return;
                      return (
                        <li
                          className="flex border-b-border-alt border-b h-12"
                          style={{
                            color:
                              inlineStyles?.roomDetailsModalTextColor ||
                              undefined,
                            borderBottomColor:
                              inlineStyles?.roomDetailsModalAccentColor ||
                              undefined,
                          }}
                        >
                          <div className="flex justify-left items-center">
                            {iconData.Icon ? (
                              <iconData.Icon
                                fillColor={
                                  inlineStyles?.roomDetailsModalIconColor ||
                                  undefined
                                }
                                className="w-7 h-7 mr-4"
                              ></iconData.Icon>
                            ) : undefined}
                            <span
                              style={{
                                color:
                                  inlineStyles?.roomDetailsModalTextColor ||
                                  undefined,
                              }}
                            >
                              {iconData.name}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* bottom right */}
            <div className="w-full sm:w-1/2 mt-8 sm:mt-0">
              <div className="flex flex-col px-8">
                <HeadingStyle
                  styledAs="h6"
                  className="font-bold"
                  text={t('rooms.moreRoomDetails')}
                />
                {[featureGroups].length > 0 && (
                  <div className="w-full mt-4" data-testid="modal-accordion">
                    {Object.entries(featureGroups).map(([featGroup]) => (
                      <Accordion
                        expandedButtonLabel={featureGroups[featGroup].label}
                        collapsedButtonLabel={featureGroups[featGroup].label}
                        className="rooms-modal-accordion accordion-stacked flex items-center justify-between w-full border-b-border-alt border-b h-12 aria-expanded:border-b-[1px] [&_span]:flex [&_span]:items-center [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&>span_svg]:aria-expanded:-rotate-180"
                        style={{
                          color:
                            inlineStyles?.roomDetailsModalTextColor ||
                            undefined,
                          borderBottomColor:
                            inlineStyles?.roomDetailsModalAccentColor ||
                            undefined,
                        }}
                      >
                        <ul className="px-4 py-4 list-disc">
                          {featureGroups[featGroup]?.features?.map((feat) => (
                            <li
                              className="ml-8 my-1"
                              style={{
                                color:
                                  inlineStyles?.roomDetailsModalTextColor ||
                                  undefined,
                              }}
                            >
                              {feat.name}
                            </li>
                          ))}
                        </ul>
                      </Accordion>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

import { Ifooter } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

// this override footer
const SharedFooter = dynamic<Ifooter>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: Ifooter) => {
  return (
    <SharedFooter
      {...props}
      additionalLogosSection={
        <img
          alt="AAA Four Diamonds - Inspected Clean"
          width={125}
          className="mx-auto xl:mx-8 mt-6 md:mt-auto"
          src="https://assets.hiltonstatic.com/images/v1658150515/dx/wp/dfwanhh-hilton-anatole/media-library/AAA_InspectedClean_4Diamond/AAA_InspectedClean_4Diamond.png?_i=AA"
        />
      }
    />
  );
};

import { Search, User, Home, Telephone } from '@curated-property/icon-list';
import Head from 'next/head';

export function NotFoundPage() {
  const navArray = [
    {
      label: 'Find a hotel',
      url: 'https://www.hilton.com/en/search/find-hotels/',
      icon: <Search className="h-6" />,
    },
    {
      label: 'Account',
      url: 'https://www.hilton.com/en/hilton-honors/login/',
      icon: <User className="h-6" />,
    },
    {
      label: 'Home',
      url: 'https://www.hilton.com',
      icon: <Home className="h-6" />,
    },
  ];

  return (
    <>
      <Head>
        <title>404 - Page not found</title>
        <meta
          name="description"
          content="Sorry, we couldn’t find the page you are looking for."
        />
      </Head>
      <main>
        <section className="flex flex-col py-10 px-8 md:p-20 lg:h-screen lg:items-center lg:justify-center bg-bg">
          <div className="lg:text-center">
            <h1 className="mb-4 text-3xl font-black text-primary md:text-6xl uppercase">
              WE'RE SORRY!
            </h1>
            <h2 className="text-sm md:text-base lg:text-xl">
              We couldn’t find the page you are looking for, but maybe these
              links will help.
            </h2>
          </div>
          <nav className="flex flex-row lg:items-center py-8 lg:py-16">
            <ul className="flex flex-col items-start lg:flex-row lg:items-center lg:space-x-20">
              {navArray.map((link, key) => {
                return (
                  <li className="lg:mx-6 hover:underline" key={key}>
                    <a
                      className="flex flex-row lg:justify-center items-center py-2"
                      href={link?.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <span className="pr-2">{link?.icon}</span> {link?.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className="flex flex-col lg:flex-row">
            <PhoneNumberDisplay
              brand="Hilton Honors"
              USAndCanadaPhone="+1 855-672-1138"
              internationalPhone="+800 4445 8667"
            />
          </div>
        </section>
      </main>
    </>
  );
}

interface PhoneNumberProps {
  brand?: string;
  USAndCanadaPhone?: string;
  internationalPhone?: string;
  className?: string;
}

function PhoneNumberDisplay(props: PhoneNumberProps) {
  return (
    <div className={`flex flex-row py-4 lg:px-12 ${props?.className}`}>
      <span aria-hidden>
        <Telephone className="h-8 mr-3" />
      </span>
      <div>
        <div className="flex flex-row">
          <h3 className="mb-2 font-bold md:text-lg lg:text-xl">
            Call {props?.brand}
          </h3>
        </div>
        <p className="font-medium leading-loose">
          US and Canada | {props?.USAndCanadaPhone}
        </p>
        <p className="font-medium leading-loose">
          International | {props?.internationalPhone}
        </p>
      </div>
    </div>
  );
}

import { useEffect } from 'react';
import { AccordionAddOnProps } from './accordion-add-on.types';
import { AccordionAddOnSectionRow } from './accordion-add-on-section-row';
import { Accordion } from '@dx-ui/osc-accordion';
import cx from 'classnames';
import { makeRandomInt } from '@curated-property/utils';

export function AccordionAddOn({
  accordionPanel,
  accordionAddOnStyles,
}: AccordionAddOnProps) {
  const styleRandomInt = makeRandomInt().toString();
  const styleIdPrefix = 'accordionAddOn';
  const componentStyleID = `${styleIdPrefix}${styleRandomInt}`;
  const styleElementID = `${styleIdPrefix}Style${styleRandomInt}`;
  const mainSelector = `div#${componentStyleID}`;
  // This will be injected into <head> instance-by-instance
  let styleString = '';

  const accordionBorderColour =
    accordionAddOnStyles?.accordionBorderColor || '';

  accordionBorderColour &&
    (styleString += `${mainSelector} button.accordion-stacked { border-color:${accordionBorderColour} !important; } `);

  accordionAddOnStyles?.accordionRowTextColor &&
    (styleString += `${mainSelector} button.accordion-stacked,${mainSelector} button.accordion-stacked + section div {color: ${accordionAddOnStyles?.accordionRowTextColor}; }`);

  const accordionOpenPanelBackgroundColour =
    accordionAddOnStyles?.accordionPanelBackgroundColor || '';
  accordionOpenPanelBackgroundColour &&
    (styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] + section  {background-color:${accordionOpenPanelBackgroundColour};} `);

  // Default icon color is black.
  const accordionIconIndicatorColour =
    accordionAddOnStyles?.accordionIconColor || '#000';

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg g path { stroke:${accordionIconIndicatorColour} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg > g polyline { color:${accordionIconIndicatorColour} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked > span svg > g { fill: none !important; }`;

  // The expanded state setting uses the unexpanded state setting absent a specific setting of its own.
  const accordionIconIndicatorColourExpanded =
    !accordionAddOnStyles?.accordionIconColorActive
      ? accordionIconIndicatorColour
      : accordionAddOnStyles?.accordionIconColorActive;

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg g { fill:${accordionIconIndicatorColourExpanded} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg g path { stroke:${accordionIconIndicatorColourExpanded} !important; } !important; }`;
  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg > g > g  { stroke:'#fff' !important; }`;

  // Adds style to <head>
  useEffect(() => {
    const $style = document.createElement('style');
    $style.setAttribute('id', styleElementID);
    document.head.appendChild($style);
    $style.innerHTML = styleString;
  }, [styleElementID, styleString]);

  //limit to 3 accordion panels
  if (accordionPanel && accordionPanel?.length > 3) {
    accordionPanel = accordionPanel.slice(0, 3);
  } else if (accordionPanel && accordionPanel?.length <= 3) {
    accordionPanel = accordionPanel.slice(0, accordionPanel.length);
  }

  return (
    <div className="w-full pt-8" id={componentStyleID}>
      {accordionPanel?.map((panel, i) => {
        return (
          <Accordion
            key={`accordionAddOn` + i}
            className={cx(
              'accordion-stacked w-full flex py-4 px-2 justify-between text-lg font-extrabold align-center border-text border-b-[1px] aria-expanded:border-b-[1px] [&_span]:flex [&_span]:items-center [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&>span_svg]:aria-expanded:-rotate-180'
            )}
            collapsedButtonLabel={panel.panelLabel}
            expandedButtonLabel={panel.panelLabel}
          >
            <div
              className="accordion-section p-2 text-lg"
              data-testid="accordion-add-on-section"
            >
              <ul>
                {panel.panelRepeater?.map((p, i) => {
                  return p?.rowLabel || p?.rowText ? (
                    <AccordionAddOnSectionRow
                      key={p.rowLabel + i.toString()}
                      rowLabel={p?.rowLabel}
                      rowText={p?.rowText}
                    />
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>
          </Accordion>
        );
      })}
    </div>
  );
}

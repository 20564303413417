import React from 'react';
import { format, startOfWeek, addDays } from 'date-fns';
import en from 'date-fns/locale/en-US';

export type DateFormat = {
  text: {
    day: string;
    month: string;
    year: string;
    weekday: string;
  };
  date: {
    day: string;
    month: string;
    year: string;
  };
};
type DateFormatOptions = {
  day?: string;
  month?: string;
  year?: string;
};
type WidthOption = 'narrow' | 'abbreviated' | 'wide';
type TextFormatOptions = { day?: WidthOption; month?: string };
type FormatDateOptions = {
  text?: TextFormatOptions;
  date?: DateFormatOptions;
};

export const languageConverter = (language: string): string => {
  const map: { [key: string]: string } = {
    en: 'en-US',
    ar: 'ar-SA',
    no: 'nb',
    'zh-hans': 'zh-CN',
    'zh-hant': 'zh-TW',
  };
  return map[language] || language;
};

/**
 * React hook to get the locale for the current langauge
 * @param language - language to use to lookup date-fns locale
 */
export const useDateFnsLocale = (language: string) => {
  const [locale, setLocale] = React.useState<Locale>(en);
  React.useEffect(() => {
    let isLoading = false;
    const getLocale = async () => {
      if (!isLoading) {
        const loc = await import(
          /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */ `date-fns/locale/${languageConverter(
            language
          )}/index.js`
        );
        setLocale(loc.default);
      }
    };
    if (language) {
      getLocale();
    }
    return () => {
      isLoading = true;
    };
  }, [language]);

  return { locale, language };
};

export const getDaysOfWeek = (locale: Locale) => {
  const startDate = startOfWeek(new Date(), {
    locale,
    weekStartsOn: locale.options?.weekStartsOn,
  });
  const days = [...Array(7)].map((n, i) => {
    const day = addDays(startDate, i);
    return {
      abbr: format(day, 'eee', { locale }),
      long: format(day, 'eeee', { locale }),
    };
  });
  return days;
};

export const formatDate = (
  date: Date,
  locale: Locale,
  options?: FormatDateOptions
): DateFormat => {
  const textDefaults = isArabic(locale)
    ? { month: 'MMMM' }
    : { day: 'abbreviated' as WidthOption, month: 'MMM' };
  const textFormats = {
    ...textDefaults,
    ...options?.text,
  };
  const dateDefaults = { day: 'd', month: 'M', year: 'yyyy' };
  const dateFormats = {
    ...dateDefaults,
    ...options?.date,
  };
  return {
    date: dateInfo(date, locale, dateFormats),
    text: textInfo(date, locale, textFormats),
  };
};

export const textInfo = (
  date: Date,
  locale: Locale,
  options: TextFormatOptions
) => {
  if (isKanji(locale)) {
    const long = locale.formatLong?.date({ width: 'long' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [full, year, month, day] = long.match(/y'?(.).?M'?(.).?d'?(.)/);
    return {
      weekday: format(date, 'EEE', { locale }),
      year,
      month,
      day,
    };
  }
  return {
    weekday: locale.localize?.day(date.getDay(), { width: options?.day }),
    year: `${date.getFullYear()}`,
    month: format(date, options?.month || 'MMM', { locale }),
    day: locale.localize?.day(date.getDay(), { width: options?.day }),
  };
};

export const dateInfo = (
  date: Date,
  locale: Locale,
  options: DateFormatOptions
) => ({
  day: format(date, options.day || '', { locale }),
  month: format(date, options.month || '', { locale }),
  year: format(date, options.year || '', { locale }),
});

export const isKanji = (locale?: Locale) =>
  locale?.code && ['ja', 'ko', 'zh-CN', 'zh-TW'].includes(locale?.code);
export const isArabic = (locale?: Locale) =>
  locale?.code && ['ar-SA'].includes(locale?.code);

import React, { useState } from 'react';
import cx from 'classnames';
import { SliderControlsWrapper } from '../global/slider-controls-wrapper';
import {
  GIS_merge,
  GIS_Padder,
  StyleObject,
} from '../functions/global-instance-styles';
import { HeadingStyle } from '../includes/heading-style';
import contentStyles from '../css/contentStyle.module.css';
import { useTranslation } from 'next-i18next';
import { HandleWYSIWYGContent, HandleAnimations } from '../functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';
import { AnchorLink } from '../global/anchor-link';
import {
  NextImage,
  SingleItemSliderProps,
  Sizes,
} from './media-and-copy-carousel.types';
import { ComponentContent } from './component-content';
import { WordpressPageInfoQuery } from '@curated-property/shared-pages';

export function SingleItemSliderPropMapper(
  componentData: SingleItemSliderProps & {
    offsetImage?: string;
    singleItemSliderComponentSettings?: StyleObject;
  },
  globalData: NonNullable<
    NonNullable<
      WordpressPageInfoQuery['componentStyles']
    >['globalComponentSettings']
  >['globalComponentSettings']
) {
  return {
    imageSizes: componentData.imageSizes,
    layoutDirection: componentData.layoutDirection,
    layoutMode: componentData.layoutMode,
    offset: componentData.offsetImage,
    header: componentData.header,
    headingValue: componentData.headingValue,
    copy: componentData.copy,
    buttons: componentData.buttons,
    repeater: componentData.repeater,
    globalStyles: globalData?.singleItemSliderComponentSettings,
    instanceStyles: componentData?.singleItemSliderComponentSettings,
  };
}

export function SingleItemSlider({
  offset,
  imageSizes,
  layoutDirection,
  layoutMode,
  header,
  headingValue,
  copy,
  buttons,
  repeater,
  globalStyles,
  instanceStyles,
}: SingleItemSliderProps) {
  repeater = repeater?.filter((item) => !item?.showHide);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [nextImage, setNextImage] = useState<NextImage>({
    image: repeater?.[currentIndex]?.image
      ? repeater?.[currentIndex]?.image
      : undefined,
    ind: currentIndex,
  });
  const [nextTimeout, setNextTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [navAnimationDir, setNavAnimationDir] = useState<boolean>(true);
  const slideCount = repeater?.length || 0;
  const { t } = useTranslation();
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  const getNextIndex = () =>
    currentIndex + 1 > slideCount - 1 ? 0 : currentIndex + 1;
  const getPrevIndex = () =>
    currentIndex - 1 < 0 ? slideCount - 1 : currentIndex - 1;

  const sizes: Sizes = { width: 0, height: 0 };
  switch (imageSizes) {
    case '895x581':
      sizes.width = 895;
      sizes.height = 581;
      break;
    case '1110x886':
      sizes.width = 1110;
      sizes.height = 886;
      break;
    default:
      sizes.width = 1110;
      sizes.height = 886;
      break;
  }

  function next() {
    if (!repeater || repeater?.length <= 1) {
      return;
    }
    if (!navAnimationDir) setNavAnimationDir(true);
    if (!nextTimeout) {
      const nextInd = getNextIndex();
      setNextImage({
        image: repeater?.[nextInd]?.image,
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setCurrentIndex(getNextIndex);
          setNextTimeout(null);
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      const skipTransInd =
        currentIndex === repeater?.length - 1
          ? 1
          : currentIndex + 2 > repeater?.length - 1
          ? 0
          : currentIndex + 2;
      setCurrentIndex(skipTransInd);
      setNextImage({
        image: repeater?.[skipTransInd]?.image,
        ind: skipTransInd,
      });
    }
  }

  function prev() {
    if (!repeater || repeater?.length <= 1) {
      return;
    }
    if (navAnimationDir) setNavAnimationDir(false);
    if (!nextTimeout) {
      const nextInd = getPrevIndex();
      setNextImage({
        image: repeater?.[nextInd]?.image,
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setCurrentIndex(getPrevIndex);
          setNextTimeout(null);
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      const skipTransInd =
        currentIndex === 0
          ? repeater?.length - 1
          : currentIndex - 2 < 0
          ? repeater?.length - 1
          : currentIndex - 2;
      setCurrentIndex(skipTransInd);
      setNextImage({
        image: repeater?.[skipTransInd]?.image,
        ind: skipTransInd,
      });
    }
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : layoutDirection?.substring(0, 4) === 'copy'
      ? 'lg:translate-x-8'
      : 'lg:-translate-x-8',
    delayOne: 'delay-300',
    delayTwo: 'delay-500',
    delayThree: 'delay-700',
  });

  return (
    <section
      ref={animations?.ref}
      className={cx(
        inlineStyles?.showHide && 'hidden',
        'relative bg-right-top bg-no-repeat z-2 py-px',
        paddingStyles
      )}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url(${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )})`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'left center',
        backgroundColor: inlineStyles?.componentBackgroundColor,
      }}
      data-element-id="media-and-copy-carousel-wrapper"
    >
      <div className={cx('container')}>
        {(header || copy) && (
          <div className="md:px-10 mb-8">
            <HeadingStyle
              text={header}
              styledAs="h2"
              type={headingValue ?? 'h2'}
              className={cx('mb-6 text-inverse', animations?.one)}
              textColorInline={
                inlineStyles?.mediaAndCopyCarouselHeaderTitleColour
              }
            />
            <div
              className={cx(
                'mb-6 text-lg',
                animations?.two,
                contentStyles.listStyle,
                contentStyles.paragraphStyle
              )}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(
                  copy,
                  inlineStyles?.mediaAndCopyCarouselHeaderCopyColour
                ),
              }}
              style={{
                color: inlineStyles?.mediaAndCopyCarouselHeaderCopyColour,
              }}
            ></div>
            {buttons ? (
              <div className={cx('mt-0', animations?.three)}>
                {buttons?.map((link, key) => {
                  return (
                    <AnchorLink
                      key={key}
                      url={link?.link?.url}
                      title={link?.link?.title}
                      target={link?.link?.target}
                      buttonStyle={link?.buttonStyle ?? 'primary'}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
        <div className="container--1680">
          <SliderControlsWrapper
            className="flex w-full"
            sectionHeading={repeater?.[nextImage?.ind]?.title}
            next={next}
            prev={prev}
            current={nextImage?.ind}
            count={slideCount}
            styleOptions={{
              primaryColour: inlineStyles?.uiPrimaryColour,
              secondaryColour: inlineStyles?.uiSecondaryColour,
            }}
          >
            {ComponentContent({
              repeater,
              offset,
              layoutDirection,
              inlineStyles,
              layoutMode,
              sizes,
              header,
              headingValue,
              nextImage,
              currentIndex,
              navAnimationDir,
              nextTimeout,
              getNextIndex,
              getPrevIndex,
            })}
          </SliderControlsWrapper>
        </div>
      </div>
    </section>
  );
}

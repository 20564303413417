import React from 'react';
import { useTranslation } from 'next-i18next';
import { RoomInfoAction, RoomInfoActionType, RoomInfoState } from './reducer';
import cx from 'classnames';
import { Stepper } from '../../stepper';
import { InfoPopup } from '../../info-icon-popup';

export interface RoomInfoControlProps {
  rooms: RoomInfoState[];
  updateRooms: React.Dispatch<RoomInfoAction>;
  ageRange?: {
    min: number;
    max: number;
  };
  formId?: string;
}

export function RoomInfoControls({
  ageRange,
  formId = '',
  rooms,
  updateRooms,
}: RoomInfoControlProps) {
  const [t] = useTranslation();
  const agesRequired = Boolean(ageRange);
  return (
    <div role="application" className="p-2 max-h-80">
      <div className="w-full">
        <div className="flex" aria-hidden>
          <span className="w-1/3 text-sm font-bold ltr:text-left rtl:text-right">
            {t('occupancy.roomHeader')}
          </span>
          <span className="w-1/3 text-sm font-bold text-center">
            {t('occupancy.adultHeader')}
            {ageRange && (
              <span className="pl-1 text-xs font-normal">
                ({ageRange.max}+)
              </span>
            )}
          </span>
          <span className="w-1/3 text-sm font-bold text-center">
            {t('occupancy.childHeader')}
          </span>
        </div>
      </div>
      <div className="w-full space-y-2">
        {rooms.map(({ adults, kids, childAges }, index) => {
          const roomId = index + 1;
          return (
            <div
              key={`room-info-room-${roomId}`}
              data-testid={`room-info-room-${roomId}`}
            >
              <div className="sr-only" aria-live="polite">
                <span>{`${
                  rooms.length > 1
                    ? `${t('occupancy.roomHas', { num: roomId })} `
                    : ''
                } ${t('occupancy.adult', {
                  count: adults,
                })}, ${t('occupancy.child', { count: kids })}`}</span>
              </div>
              <div className="flex items-center py-2 space-x-2">
                <div className="flex items-center w-1/3">
                  {rooms.length > 1 && (
                    <button
                      type="button"
                      className="mr-2 rounded-full appearance-none stroke-primary hover:stroke-primary-alt hover:duration-100"
                      onClick={() =>
                        updateRooms({
                          type: RoomInfoActionType.REMOVE_ROOM,
                          index,
                        })
                      }
                      data-testid={`room-info-remove-room-${roomId}`}
                    >
                      <span className="sr-only">
                        {t('occupancy.removeRoom', { num: roomId })}
                      </span>
                      <svg
                        role="img"
                        aria-hidden
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        className="stroke-primary"
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          transform="translate(-4.4 -4.4)"
                        >
                          <ellipse
                            transform="rotate(45 17.836 17.836)"
                            cx="17.836"
                            cy="17.836"
                            rx="12.5"
                            ry="12.724"
                          />
                          <path d="M13.585 13.572l8.515 8.515m-8.592.047l8.668-8.668" />
                        </g>
                      </svg>
                    </button>
                  )}
                  <span>{t('occupancy.room', { num: roomId })}</span>
                </div>
                <div className="w-1/3">
                  <Stepper
                    inputId={`${formId}-room${roomId}NumAdults`}
                    inputName={`room${roomId}NumAdults`}
                    min={1}
                    max={4}
                    value={adults}
                    valueLabel={t('occupancy.adult', { count: adults })}
                    plusLabel={t('occupancy.addAdult', { num: roomId })}
                    minusLabel={t('occupancy.removeAdult', { num: roomId })}
                    onMinusClick={() =>
                      updateRooms({
                        type: RoomInfoActionType.REMOVE_ADULT,
                        index,
                      })
                    }
                    onPlusClick={() =>
                      updateRooms({ type: RoomInfoActionType.ADD_ADULT, index })
                    }
                  />
                </div>
                <div className="w-1/3">
                  <Stepper
                    inputId={`${formId}-room${roomId}NumChildren`}
                    inputName={`room${roomId}NumChildren`}
                    min={0}
                    max={4}
                    value={kids}
                    valueLabel={t('occupancy.child', { count: kids })}
                    plusLabel={t('occupancy.addChild', { num: roomId })}
                    minusLabel={t('occupancy.removeChild', { num: roomId })}
                    onMinusClick={() =>
                      updateRooms({
                        type: RoomInfoActionType.REMOVE_CHILD,
                        index,
                      })
                    }
                    onPlusClick={() =>
                      updateRooms({ type: RoomInfoActionType.ADD_CHILD, index })
                    }
                  />
                </div>
              </div>
              {agesRequired && kids > 0 && (
                <div className="py-2 pb-4" data-testid="room-info-age-based">
                  <h2 className="flex items-center pb-2 space-x-2 text-sm font-bold">
                    <span>{t('occupancy.agesLabel')}</span>
                    <InfoPopup label={t('occupancy.ageBasedHelp')}>
                      <p className="whitespace-nowrap">
                        {t('occupancy.ageBasedHelp')}
                      </p>
                    </InfoPopup>
                  </h2>
                  <div className="flex flex-col space-y-2">
                    <input
                      id={`${formId}-room${roomId}ChildAges`}
                      type="hidden"
                      key={`${index}`}
                      name={`room${roomId}ChildAges`}
                      value={childAges.join(',')}
                    />
                    {/* eslint-disable-next-line prefer-spread */}
                    {Array.apply(null, Array(kids)).map((_, i) => {
                      const childAge = childAges ? childAges[i] : undefined;
                      const childAgeRange = ageRange || { min: 0, max: 18 };
                      const isError = agesRequired && childAge === undefined;
                      return (
                        <div key={`child-${i}-age`}>
                          <label
                            className={cx('label label-inline w-full block', {
                              'text-danger': isError,
                            })}
                            key={`child-${i}-age`}
                          >
                            <span className="inline-block w-16 text-right">
                              {t('occupancy.kidLabel', { num: i + 1 })}
                            </span>
                            <select
                              id={`room-${roomId}-child-${i}-age`}
                              className={cx('form-select', {
                                'form-error': isError,
                              })}
                              aria-required={agesRequired}
                              aria-describedby={
                                isError ? `child-${i}-age-error` : undefined
                              }
                              value={
                                childAge !== undefined &&
                                childAge <= childAgeRange.max
                                  ? childAge
                                  : ''
                              }
                              onBlur={(e) =>
                                updateRooms({
                                  type: RoomInfoActionType.CHILD_AGE,
                                  index,
                                  child: i,
                                  age: parseInt(e.target.value, 10),
                                })
                              }
                              onChange={(e) =>
                                !Number.isNaN(parseInt(e.target.value, 10)) &&
                                updateRooms({
                                  type: RoomInfoActionType.CHILD_AGE,
                                  index,
                                  child: i,
                                  age: parseInt(e.target.value, 10),
                                })
                              }
                            >
                              <option disabled value=""></option>
                              {Array.from({
                                length: childAgeRange.max - childAgeRange.min,
                              }).map((__, j) => {
                                const value = j + childAgeRange.min;
                                return (
                                  <option key={j} value={value}>
                                    {value === 0
                                      ? t('occupancy.underOne')
                                      : value}
                                  </option>
                                );
                              })}
                            </select>
                          </label>
                          {isError && (
                            <div className="my-2 text-danger">
                              <a
                                className="flex items-center"
                                href={`#child-${i}-age`}
                                id={`room-${roomId}child-${i}-age-error`}
                              >
                                <svg
                                  role="img"
                                  aria-hidden
                                  className="mr-2"
                                  height="16"
                                  width="16"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="currentcolor">
                                    <circle cx="12" cy="12" r="12" />
                                  </g>
                                  <g
                                    transform="translate(8.000000, 8.000000)"
                                    stroke="#FFFFFF"
                                    strokeLinecap="square"
                                  >
                                    <path
                                      d="M1,1 L7.00679887,7.00679887"
                                      strokeWidth="2"
                                    />
                                    <path
                                      d="M1,7 L7.00679887,0.993201131"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                                <span>{t('occupancy.ageBasedError')}</span>
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="py-2 space-y-2 border-t border-solid border-border">
        <button
          type="button"
          data-testid="room-info-add-room"
          className="flex items-center font-bold appearance-none stroke-primary hover:stroke-primary-alt hover:duration-100 disabled:text-text-disabled disabled:stroke-text-disabled"
          onClick={() => updateRooms({ type: RoomInfoActionType.ADD_ROOM })}
          disabled={rooms.length >= 9}
        >
          <svg
            role="img"
            aria-hidden
            width="27"
            height="27"
            viewBox="0 0 27 27"
            className="ltr:mr-2 rtl:ml-2"
          >
            <g fill="none" fillRule="evenodd" transform="translate(1 .68)">
              <ellipse cx="12.5" cy="12.724" rx="12.5" ry="12.724"></ellipse>
              <path
                strokeLinecap="square"
                d="M6.5 12.724h12.042M12.5 18.832V6.574"
              ></path>
            </g>
          </svg>
          {t('occupancy.addRoom')}
        </button>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import {
  customLoader,
  CroppedImage,
  CarouselTouchSwipe,
} from '@curated-property/utils';
import { ArrowRight, ArrowLeft } from '@curated-property/icons';
import { useTranslation } from 'next-i18next';
import { HandleAnimations } from '../functions/helper';
import { useAdobeCarouselEvent } from '../global/adobe-analytics';
import cx from 'classnames';
import { HeroImageSliderProps, SliderControlsProps } from './hero-image.types';

export function HeroImageSlider({
  images,
  imageSize,
  styleOptions,
  imageChangeDetect,
  headerDisplacement,
  bookingWidgetPosition,
  componentInstance,
  hideAnimations,
  animationDirection,
}: HeroImageSliderProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextImage, setNextImage] = useState({
    image: images[currentIndex],
    ind: currentIndex,
  });
  const [nextTimeout, setNextTimeout] = useState(null);
  const slideAnimation: string = styleOptions?.sliderAnimations || 'fade';
  const [navAnimationDir, setNavAnimationDir] = useState(true);
  const sliderId = `hero-slider-${componentInstance}`;
  const currentImage = images[currentIndex];
  const count = images ? images.length : 0;
  const getNextIndex = () =>
    currentIndex + 1 > count - 1 ? 0 : currentIndex + 1;
  const getPrevIndex = () =>
    currentIndex - 1 < 0 ? count - 1 : currentIndex - 1;

  CarouselTouchSwipe({ next, prev, targetId: sliderId });

  useAdobeCarouselEvent({
    sectionHeading: 'Hero Image Carousel',
    currentIndex: currentIndex + 1,
    totalCount: images?.length,
  });

  const controlsProps = {
    currentIndex: nextImage?.ind,
    images,
    prev,
    next,
    headerDisplacement,
    bookingWidgetPosition,
    styleOptions,
    hideAnimations,
    animationDirection,
  };

  const handleImageChange = (isNext?: boolean) => {
    if (!images || images.length <= 1) {
      return;
    }
    imageChangeDetect();
    if (isNext && !navAnimationDir) {
      setNavAnimationDir(true);
    } else if (!isNext && navAnimationDir) setNavAnimationDir(false);
    if (!nextTimeout) {
      const nextInd = isNext ? getNextIndex() : getPrevIndex();
      setNextImage({
        image: images[nextInd],
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setCurrentIndex(isNext ? getNextIndex() : getPrevIndex());
          setNextTimeout(null);
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      let skipTransInd =
        currentIndex === count - 1
          ? 1
          : currentIndex + 2 > count - 1
          ? 0
          : currentIndex + 2;
      if (!isNext)
        skipTransInd =
          currentIndex === 0
            ? 1
            : currentIndex - 2 < 0
            ? count - 1
            : currentIndex - 2;
      setCurrentIndex(skipTransInd);
      setNextImage({
        image: images[skipTransInd],
        ind: skipTransInd,
      });
    }
  };

  function next() {
    handleImageChange(true);
  }

  function prev() {
    handleImageChange();
  }

  const width = imageSize ? imageSize.w : 2560;
  const height = imageSize ? imageSize.h : 1808;
  const src = currentImage?.url;
  const srcUrl = customLoader({ src, width, height });
  const nextImgSrcUrl = customLoader({
    src: nextImage?.image?.url,
    width,
    height,
  });

  const getPrevSlideInd = () => {
    const ind =
      slideAnimation === 'slide-ltr' && navAnimationDir
        ? getNextIndex()
        : getPrevIndex();
    return ind;
  };

  const slideStyles = {
    '-translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-ltr' &&
      (navAnimationDir || slideAnimation === 'slide-rtl'),
    'translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-rtl' &&
      (!navAnimationDir || slideAnimation === 'slide-ltr'),
  };

  return slideAnimation !== 'fade' ? (
    <div
      id={`hero-slider-${componentInstance}`}
      className="flex flex-row h-full w-full justify-center relative overflow-hidden"
    >
      <div
        className={cx(
          'absolute h-full w-full transform transition-all duration-500 ease-in-out',
          {
            'translate-x-0': nextTimeout && slideAnimation !== 'slide-rtl',
            '-translate-x-full': !nextTimeout || slideAnimation === 'slide-rtl',
            'transition-none': !nextTimeout,
          }
        )}
      >
        <CroppedImage
          width={1920}
          height={1080}
          quality={70}
          src={images[getPrevSlideInd()]?.url}
          alt={`${images[getPrevSlideInd()]?.alt}-previous-transition`}
          objectPosition={styleOptions?.imagePositioning || null}
          objectFit="cover"
          layout="fill"
          draggable={false}
        />
      </div>
      <div
        className={cx(
          'absolute h-full w-full order-3 transform transition-all duration-500 ease-in-out',
          {
            'translate-x-0':
              (slideAnimation === 'slide-rtl' && nextTimeout) ||
              (nextTimeout &&
                slideAnimation !== 'slide-ltr' &&
                navAnimationDir),
            'translate-x-full':
              !nextTimeout ||
              (slideAnimation !== 'slide-rtl' && !navAnimationDir) ||
              slideAnimation === 'slide-ltr',
            'transition-none': !nextTimeout,
          }
        )}
      >
        <CroppedImage
          width={1920}
          height={1080}
          quality={70}
          src={nextImgSrcUrl}
          alt={`${nextImage?.image?.alt}-next-transition`}
          objectPosition={styleOptions?.imagePositioning || null}
          objectFit="cover"
          layout="fill"
          draggable={false}
        />
      </div>
      <div
        className={cx(
          'w-full order-2 transform transition-all duration-500 ease-in-out',
          {
            'transition-none': !nextTimeout,
          },
          slideStyles
        )}
      >
        <CroppedImage
          width={1920}
          height={1080}
          quality={70}
          src={srcUrl}
          alt={currentImage?.alt}
          objectPosition={styleOptions?.imagePositioning || null}
          objectFit="cover"
          layout="fill"
          draggable={false}
        />
      </div>
      {<ControlsOverlayed {...controlsProps} />}
    </div>
  ) : (
    <div id={`hero-slider-${componentInstance}`}>
      <CroppedImage
        width={1920}
        height={1080}
        quality={70}
        src={nextImgSrcUrl}
        alt={`${nextImage?.image?.alt}-transition`}
        objectPosition={styleOptions?.imagePositioning || null}
        objectFit="cover"
        layout="fill"
        className={cx(
          'z-10 transform transition-all duration-500 ease-in-out',
          {
            'animate-fade-in-slow': !hideAnimations,
          }
        )}
        draggable={false}
      />
      <CroppedImage
        data-testid="sliderImage"
        width={1920}
        height={1080}
        quality={70}
        src={srcUrl}
        alt={currentImage?.alt}
        objectPosition={styleOptions?.imagePositioning || null}
        objectFit="cover"
        layout="fill"
        className={cx(
          'z-10 transform transition-all duration-500 ease-in-out',
          {
            'animate-fade-in-slow': !hideAnimations,
            'opacity-0': nextTimeout ? true : false,
            'opacity-100': nextTimeout ? false : true,
          }
        )}
        draggable={false}
      />
      {<ControlsOverlayed {...controlsProps} />}
    </div>
  );
}

function ControlsOverlayed({
  currentIndex,
  images,
  prev,
  next,
  headerDisplacement,
  bookingWidgetPosition,
  styleOptions,
  hideAnimations,
  animationDirection,
}: SliderControlsProps) {
  const { t } = useTranslation();
  const bookingWidgetPadding =
    bookingWidgetPosition === 'Bottom'
      ? 'mb-4 lg:mb-32'
      : bookingWidgetPosition === 'Overlapping'
      ? 'mb-4 lg:mb-20'
      : 'mb-4';

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${animationDirection ?? 'translate-x'}-8`,
    delayOne: 'delay-500',
  });

  return (
    <div
      ref={animations?.ref}
      className={cx(
        'absolute flex justify-center items-end h-full w-full z-30 pointer-events-none',
        animations?.one
      )}
    >
      <div
        style={{
          paddingTop: headerDisplacement,
        }}
        className="absolute h-full w-full flex justify-between items-center px-4 2xl:px-0 max-w-screen-2xl"
      >
        <button
          data-testid="heroSliderPrevious"
          aria-label={`${t('goToPrevious')}, ${currentIndex + 1} ${t('of')} ${
            images.length
          }`}
          onClick={prev}
          className="flex items-center justify-center rounded-full w-8 h-8 pointer-events-auto"
          style={{
            backgroundColor: styleOptions?.uiBackgroundColour ?? '#262626',
          }}
        >
          <ArrowLeft
            className="h-4 w-4 mr-0.5"
            fillColor={styleOptions?.uiControlsColour ?? '#F5F5F5'}
          ></ArrowLeft>
        </button>
        <button
          data-testid="heroSliderNext"
          aria-label={`${t('goToNext')}, ${currentIndex + 1} ${t('of')} ${
            images.length
          }`}
          onClick={next}
          className="flex items-center justify-center rounded-full w-8 h-8 pointer-events-auto"
          style={{
            backgroundColor: styleOptions?.uiBackgroundColour ?? '#262626',
          }}
        >
          <ArrowRight
            className="h-4 w-4 ml-0.5"
            fillColor={styleOptions?.uiControlsColour ?? '#F5F5F5'}
          ></ArrowRight>
        </button>
      </div>
      <ol className={cx('z-30', bookingWidgetPadding, animations?.one)}>
        <li>
          <span className="sr-only">{`${currentIndex + 1} ${t('of')} ${
            images?.length
          }`}</span>
          <div className="flex">
            {[...Array(images?.length)].map((_, i) => (
              <span
                key={i}
                className={cx(
                  'block h-2.5 w-2.5 mx-1.5 rounded-full border border-bg transition-all',
                  {
                    'bg-bg': currentIndex === i,
                  }
                )}
                style={{
                  borderColor: styleOptions?.heroCarouselCounterColour,
                  backgroundColor:
                    currentIndex === i &&
                    styleOptions?.heroCarouselCounterColour,
                }}
              />
            ))}
          </div>
        </li>
      </ol>
    </div>
  );
}

import React from 'react';

export function SaleIcon({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 20 20"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m13.5 10.5c.28 0 .5-.22.5-.5 0-1.38 1.12-2.5 2.5-2.5h.5c1.93 0 3.5-1.57 3.5-3.5 0-.28-.22-.5-.5-.5s-.5.22-.5.5c0 1.38-1.12 2.5-2.5 2.5h-.5c-.09 0-.18.02-.27.03l-.88-.88c-.09-.09-.22-.15-.35-.15h-4c-.13 0-.26.05-.35.15l-7 7c-.2.2-.2.51 0 .71l7 7c.1.1.23.15.35.15s.26-.05.35-.15l7-7c.09-.09.15-.22.15-.35v-4c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.79l-6.5 6.5-6.29-6.29 6.5-6.5h3.59l.3.3c-.6.26-1.11.69-1.48 1.22-.04 0-.07-.02-.12-.02-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5c0-.71.49-1.3 1.15-1.45-.09.31-.15.62-.15.95 0 .28.22.5.5.5z" />
    </svg>
  );
}

import { formatDate, getDaysOfWeek, isKanji } from '../date-formatter';
import cx from 'classnames';
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  getDaysInMonth,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  lastDayOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import en from 'date-fns/locale/en-US';
import React, { useMemo } from 'react';
import CalendarDay from './calendar.day';
import { v4 as uuidv4 } from 'uuid';

export type ICalendarMonth = {
  /**
   * selected day or start day when a range is allowed
   */
  day?: Date;
  /**
   * selected end day when a range is allowed
   */
  endDay?: Date;
  /**
   * furthest date available to book
   */
  maxDays?: number;
  /**
   * month to display, should be passed as the first day of the month, can use `startOfMonth(someDate)` from the date-fns lib
   */
  month?: Date;
  /**
   * locale for i18n
   */
  locale?: Locale;
  /**
   * callback when a day is selected
   */
  onDayChange: (day?: Date) => void;
  focusedDay: number;
  setFocusedDay: (d: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const keyCode = Object.freeze({
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
});

const CalendarMonth: React.FC<ICalendarMonth> = ({
  day,
  endDay,
  maxDays = 720, // temporary fix to allow for booking 2 years in advance. The correct way to set this would be to consume hotel.shopAvailOptions.maxArrivalDate
  month = startOfMonth(new Date()),
  onDayChange,
  locale = en,
  className,
  focusedDay,
  setFocusedDay,
}) => {
  const TODAY = new Date();

  const weeks = useMemo(() => {
    const days = eachDayOfInterval({
      start: subDays(month, getDay(month)),
      end: addDays(
        endOfMonth(month),
        42 - (getDay(month) + getDaysInMonth(month))
      ),
    });
    const w: Date[][] = [];
    while (days.length > 0) {
      w.push(days.splice(0, 7));
    }
    return w;
  }, [month]);

  const week = useMemo(() => getDaysOfWeek(locale), [locale]);
  const { date, text } = formatDate(month, locale, { text: { month: 'MMMM' } });
  const header = isKanji(locale)
    ? `${date.year}${text.year} ${date.month}${text.month}`
    : `${text.month} ${date.year}`;

  const monthId = useMemo(() => `calendar-month-${uuidv4()}`, []);

  return (
    <div
      className={cx('w-full select-none', {
        [className as string]: !!className,
      })}
    >
      <span
        className="block text-lg font-extrabold text-center"
        id={monthId}
        aria-live="polite"
      >
        {header}
      </span>
      <table role="grid" className="w-full" aria-labelledby={monthId}>
        <thead>
          <tr>
            {week.map(({ abbr, long }) => (
              <th
                scope="col"
                key={abbr}
                className="text-xs text-center uppercase"
                aria-hidden
              >
                <span className="sr-only">{long}</span>
                <span aria-hidden>{abbr}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((w, i) => (
            <tr key={i}>
              {w.map((d) => {
                const selected =
                  (day && isSameDay(d, day)) ||
                  (endDay && isSameDay(d, endDay));
                const disabled =
                  isBefore(d, startOfDay(TODAY)) ||
                  isAfter(d, addDays(TODAY, maxDays));
                const highlighted =
                  day && endDay
                    ? isWithinInterval(d, { start: day, end: endDay })
                    : false;

                const selectDay = () => onDayChange(d);
                const onKeyDown = (
                  e: React.KeyboardEvent<HTMLButtonElement>
                ) => {
                  let flag = false;
                  switch (e.keyCode) {
                    case keyCode.SPACE:
                    case keyCode.ENTER:
                      selectDay();
                      flag = true;
                      break;
                    case keyCode.RIGHT:
                      setFocusedDay(addDays(d, 1).getTime());
                      break;
                    case keyCode.LEFT:
                      setFocusedDay(subDays(d, 1).getTime());
                      flag = true;
                      break;
                    case keyCode.DOWN:
                      setFocusedDay(addDays(d, 7).getTime());
                      flag = true;
                      break;
                    case keyCode.UP:
                      setFocusedDay(subDays(d, 7).getTime());
                      flag = true;
                      break;
                    case keyCode.HOME:
                      setFocusedDay(startOfWeek(d).getTime());
                      flag = true;
                      break;
                    case keyCode.END:
                      setFocusedDay(lastDayOfWeek(d).getTime());
                      flag = true;
                      break;
                    default:
                      break;
                  }
                  if (flag) {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                };

                return (
                  <td key={d.getTime()}>
                    {isSameMonth(d, month) ? (
                      <CalendarDay
                        id={`day-${d.getTime()}`}
                        disabled={disabled}
                        selected={selected}
                        highlighted={highlighted}
                        onKeyDown={onKeyDown}
                        onClick={selectDay}
                        onFocus={() => setFocusedDay(d.getTime())}
                        tabIndex={selected ? 0 : -1}
                        aria-label={
                          focusedDay === d.getTime()
                            ? `selected date is ${format(d, 'PPPP')}`
                            : ''
                        }
                        className="h-[37px] min-w-[37px]"
                      >
                        {format(d, 'd')}
                      </CalendarDay>
                    ) : (
                      <span
                        aria-hidden
                        className="block py-1"
                        key={d.getTime()}
                      >
                        &nbsp;
                      </span>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CalendarMonth;

import cx from 'classnames';
import styles from './css/contentStyle.module.css';
import { HeadingStyle } from './includes/heading-style';
import { AnchorLink, ACFAnchorProps } from './global/anchor-link';
import { HandleWYSIWYGContent, HandleAnimations } from './functions/helper';
import {
  CropProps,
  CroppedImage,
  customLoader,
  ParallaxLayer,
  isReducedMotion,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import {
  GIS_Array,
  GIS_Padder,
  StyleObject,
  GIS_TextAlignment,
} from './functions/global-instance-styles';
import {
  MediaBlocksMediaLayoutsInterface,
  mediaBlocksFactory,
} from './media-blocks';

export function MediaAndCopyOverlayPropMapper(
  componentData?: Props &
    CropProps & {
      image?: { sourceUrl?: string; altText?: string };
      mediaAndCopyOverlayComponentSettings?: StyleObject;
    },
  globalData?: StyleObject
) {
  return {
    title: componentData?.title || '',
    copy: componentData?.copy || '',
    imgSrc: componentData?.image?.sourceUrl || '',
    imgAlt: componentData?.image?.altText || '',
    mediaBlock: componentData?.mediaBlock,
    enableCropping: componentData?.enableCropping || false,
    cropType: componentData?.cropType || '',
    xPosition: componentData?.xPosition || '',
    xPositionAdvanced: componentData?.xPositionAdvanced || '',
    yPosition: componentData?.yPosition || '',
    yPositionAdvanced: componentData?.yPositionAdvanced || '',
    cropWidth: componentData?.cropWidth || '',
    cropHeight: componentData?.cropHeight || '',
    autoPosition: componentData?.autoPosition || false,
    buttons: componentData?.buttons,
    layoutMode: componentData?.layoutMode || '',
    globalStyles: globalData?.mediaAndCopyOverlayComponentSettings,
    instanceStyles: componentData?.mediaAndCopyOverlayComponentSettings,
  };
}

interface Props {
  title?: string;
  title_noTx?: string;
  copy?: string;
  imgSrc?: string;
  imgAlt?: string;
  mediaBlock?: MediaBlocksMediaLayoutsInterface;
  buttons?: Array<ACFAnchorProps>;
  layoutMode?: string;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

interface CombinedProps extends CropProps, Props {}

export function MediaAndCopyOverlay({
  title,
  title_noTx,
  copy,
  buttons,
  layoutMode,
  imgSrc,
  imgAlt,
  enableCropping,
  cropType,
  xPosition,
  xPositionAdvanced,
  yPosition,
  yPositionAdvanced,
  cropWidth,
  cropHeight,
  autoPosition,
  mediaBlock,
  instanceStyles,
  globalStyles,
}: CombinedProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const zoomOnHover = inlineStyles?.zoomOnHover === 'show' && !isReducedMotion;
  let direction: string, positioning: string;

  switch (layoutMode) {
    case 'Copy / Media':
      direction = 'justify-end';
      positioning = 'lg:left-0';
      break;
    case 'Media / Copy':
      direction = 'justify-start';
      positioning = 'lg:right-0';
      break;
    default:
      direction = 'justify-end';
      positioning = 'lg:left-0';
  }

  //override layout mode with the new horizontal direction field
  switch (inlineStyles?.horizontalDirection) {
    case 'regular':
      direction = 'justify-end';
      positioning = 'lg:left-0';
      break;
    case 'reverse':
      direction = 'justify-start';
      positioning = 'lg:right-0';
      break;
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : inlineStyles?.horizontalDirection === 'regular'
      ? 'lg:-translate-x-8'
      : 'lg:translate-x-8',
    delayOne: 'delay-300',
    delayTwo: 'delay-500',
  });
  const parallaxSpeed = -55;
  const imageArea = mediaBlock ? (
    <div className="w-full aspect-video">
      {mediaBlocksFactory(mediaBlock, 1200)}
    </div>
  ) : (
    <CroppedImage
      loader={() => {
        return customLoader({
          src: imgSrc,
          crop: enableCropping,
          width: '1200',
          height: '675',
          cropType: cropType,
          cropHeight: cropHeight,
          cropWidth: cropWidth,
          xPosition: xPosition,
          xPositionAdvanced: xPositionAdvanced,
          yPosition: yPosition,
          yPositionAdvanced: yPositionAdvanced,
          autoPosition: autoPosition,
        });
      }}
      src={imgSrc || ''}
      alt={imgAlt || ''}
      layout="responsive"
      className={cx('block transform transition-all duration-500', {
        'hover:scale-125': zoomOnHover,
      })}
      width="1200"
      height="675"
    />
  );

  return (
    <div
      className={inlineStyles?.showHide ? 'hidden' : ''}
      style={{
        background: inlineStyles?.componentBackgroundImage
          ? `url(${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )})`
          : inlineStyles.componentBackgroundColor,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'top left',
        backgroundColor: inlineStyles.componentBackgroundColor,
      }}
      data-element-id="media-and-copy-overlay-wrapper"
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          maxWidth:
            inlineStyles?.containerMaxWidth &&
            `${inlineStyles?.containerMaxWidth}px`,
        }}
      >
        <div
          className={cx('relative flex flex-col lg:flex-row', direction)}
          data-testid="media-copy-direction"
        >
          <div
            ref={animations?.ref}
            className={cx(
              'z-10 w-full lg:w-3/7 lg:absolute lg:top-1/2 lg:-translate-y-1/2',
              positioning
            )}
          >
            <ParallaxLayer
              hideAnimations={
                animations?.hideAnimations ||
                inlineStyles?.hideParallax === 'hide'
              }
              inView={animations?.inView}
              parallaxSpeed={parallaxSpeed}
            >
              <div
                className="transform p-4 lg:p-16 bg-bg-alt order-2"
                style={{
                  background: inlineStyles?.contentBackgroundImage
                    ? `url(${appliedCloudinaryParams(
                        inlineStyles?.contentBackgroundImage,
                        inlineStyles?.contentBackgroundRepeat
                      )})`
                    : inlineStyles.contentBackgroundColor,
                  backgroundSize:
                    inlineStyles?.contentBackgroundSize || 'cover',
                  backgroundRepeat:
                    inlineStyles?.contentBackgroundRepeat || 'no-repeat',
                  backgroundPosition:
                    inlineStyles?.contentBackgroundPosition || 'left center',
                  backgroundColor: inlineStyles?.contentBackgroundColor,
                  alignItems: inlineStyles?.textAlignment || 'flex-start',
                  justifyContent: inlineStyles?.textAlignment,
                  textAlign: textAlignment,
                }}
              >
                <HeadingStyle
                  text={inlineStyles?.headingsNoTranslate ? title_noTx : title}
                  type="h2"
                  className={cx(
                    animations?.one,
                    'mb-6 text-2xl font-black leading-none md:text-4xl text-inverse font-headline',
                    {
                      OneLinkNoTx: inlineStyles?.headingsNoTranslate,
                    }
                  )}
                  textColorInline={inlineStyles?.titleColor}
                />
                <div
                  className={cx(
                    animations?.two,
                    'text-lg',
                    styles.listStyle,
                    styles.paragraphStyle
                  )}
                  style={{ color: inlineStyles?.textColor }}
                  dangerouslySetInnerHTML={{
                    __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
                  }}
                ></div>
                {buttons ? (
                  <div className="mt-6">
                    {buttons?.map((link, key) => {
                      if (!link || !link.link) {
                        return null;
                      }
                      return (
                        <AnchorLink
                          key={key}
                          url={link.link?.url || ''}
                          title={link.link?.title || ''}
                          target={link.link?.target || ''}
                          buttonStyle={link?.buttonStyle ?? 'primary'}
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </ParallaxLayer>
          </div>
          <div className="lg:w-10/12 order-1 relative overflow-hidden">
            {imageArea}
          </div>
        </div>
      </div>
    </div>
  );
}

import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  addMonths,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import React from 'react';
import CalendarMonth, { ICalendarMonth } from './calendar.month';
import { useLanguage } from '@curated-property/utils';
import cx from 'classnames';

export type ICalendar = {
  allowSameDay?: boolean;
  onEndDayChange?: (day?: Date) => void;
  children?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement> &
  Pick<ICalendarMonth, 'day' | 'endDay' | 'onDayChange' | 'maxDays' | 'locale'>;

const Calendar: React.FC<ICalendar> = ({
  allowSameDay,
  children,
  locale,
  day,
  endDay,
  maxDays,
  onDayChange,
  onEndDayChange,
  ...rest
}) => {
  const TODAY = new Date();
  const ref = useRef<HTMLDivElement>(null);
  const [focusedDay, setFocusedDay] = useState<number>(
    startOfDay(day || TODAY).getTime()
  );
  const previousMonthRef = useRef<HTMLButtonElement>(null);
  const nextMonthRef = useRef<HTMLButtonElement>(null);
  const [month, setMonth] = useState(startOfMonth(day || TODAY));
  const lang = useLanguage();

  const onChangeDay = (d?: Date) => {
    if (onEndDayChange && d) {
      if (day && !endDay) {
        if (allowSameDay) {
          /**
           * Handle cases where `allowSameDay` is true
           */
          if (isSameDay(d, day) || isAfter(d, day)) {
            onEndDayChange(d);
          } else if (isBefore(d, day)) {
            onDayChange(d);
          }
        } else {
          /**
           * Handle cases where `allowSameDay` is false
           */
          // eslint-disable-next-line no-lonely-if
          if (isAfter(d, day)) {
            onEndDayChange(d);
          } else if (isBefore(d, day)) {
            onDayChange(d);
          }
        }
      } else {
        onDayChange(d);
        onEndDayChange(undefined);
      }
    } else {
      onDayChange(d);
    }
  };

  const prevDisabled = isSameMonth(TODAY, month);

  useEffect(() => {
    if (ref.current) {
      requestAnimationFrame(() => {
        const element = ref.current?.querySelector<HTMLButtonElement>(
          `#day-${focusedDay}`
        );
        if (element) {
          element.focus();
        }
      });
    }
  }, [focusedDay, ref]);

  useEffect(() => {
    function monthButtonPress(e: KeyboardEvent) {
      const calendarDays = document?.querySelectorAll(
        '[id^="day-"]'
      ) as NodeListOf<HTMLButtonElement>;
      const availDays = Array.from(calendarDays)?.filter((d) => !d?.disabled);
      const firstAvailDay = availDays?.[0];
      const lastAvailDay = availDays?.at(-1);
      if (e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
        e.preventDefault();
        firstAvailDay?.focus();
      } else if (e.key === 'ArrowRight') {
        e.preventDefault();
        lastAvailDay?.focus();
      }
    }
    nextMonthRef?.current?.addEventListener('keydown', monthButtonPress);
    previousMonthRef?.current?.addEventListener('keydown', monthButtonPress);
    return () => {
      nextMonthRef?.current?.removeEventListener('keydown', monthButtonPress);
      previousMonthRef?.current?.removeEventListener(
        'keydown',
        monthButtonPress
      );
    };
  }, [nextMonthRef, previousMonthRef]);

  return (
    <div ref={ref} className="relative inline-flex">
      <div className="flex space-x-8" {...rest}>
        <CalendarMonth
          onDayChange={onChangeDay}
          locale={locale}
          day={day}
          endDay={endDay}
          month={month}
          maxDays={maxDays}
          focusedDay={focusedDay}
          setFocusedDay={setFocusedDay}
        />
        <CalendarMonth
          onDayChange={onChangeDay}
          locale={locale}
          day={day}
          endDay={endDay}
          maxDays={maxDays}
          month={addMonths(month, 1)}
          className={cx('hidden md:block', lang === 'ar' && 'pr-5')}
          focusedDay={focusedDay}
          setFocusedDay={setFocusedDay}
        />
        {children}
      </div>
      <button
        className="cp-calendar-monthArrows absolute top-0 left-0 p-2 pt-1 hover:duration-100 focus:outline-none focus:shadow-outline focus:duration-100 disabled:cursor-not-allowed"
        onClick={() => setMonth(subMonths(month, 1))}
        ref={previousMonthRef}
        disabled={prevDisabled}
      >
        <span className="sr-only">Previous Month</span>
        <span aria-hidden>
          <svg viewBox="0 0 15 31" width="16" height="16">
            <path
              className={`cp-calendar-monthArrowsSVG
                ${prevDisabled ? 'stroke-text-disabled' : 'stroke-primary'}
              `}
              d="M14 1L2 15l12 14"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeWidth={2}
            />
          </svg>
        </span>
      </button>
      <button
        className="cp-calendar-monthArrows absolute top-0 right-0 p-2 pt-1 hover:duration-100 focus:outline-none focus:shadow-outline focus:duration-100 disabled:cursor-not-allowed"
        onClick={() => setMonth(addMonths(month, 1))}
        ref={nextMonthRef}
      >
        <span className="sr-only">Next Month</span>
        <span aria-hidden>
          <svg viewBox="0 0 15 31" width="16" height="16">
            <path
              className="cp-calendar-monthArrowsSVG stroke-primary"
              d="M1.6 1.73l12 14-12 14"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeWidth={2}
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export { Calendar };
export default Calendar;

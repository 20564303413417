import React, { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { PopupContent } from '../../popover-content';
import { SpecialRatesForm, SpecialRatesFormProps } from './special-rates-form';

type Props = SpecialRatesFormProps;

export function SpecialRatesButton({ sections, setRateValues }: Props) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<null | HTMLButtonElement>(null);
  const [t] = useTranslation();
  return (
    <div>
      <button
        id="specialRatesButton"
        className="truncate btn btn-primary-outline btn-lg"
        ref={buttonRef}
        onClick={() => setOpen((s) => !s)}
      >
        {t('rates.header')}
      </button>
      {open && (
        <PopupContent
          targetRef={buttonRef}
          onClose={() => setOpen(false)}
          className="overflow-y-auto"
        >
          <SpecialRatesForm setRateValues={setRateValues} sections={sections} />
        </PopupContent>
      )}
    </div>
  );
}

import React, { useState } from 'react';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  useTabsContext,
} from '@reach/tabs';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ArrowHead, Refresh } from '@curated-property/icon-list';
import { Action } from '../room-types';
import { slugify } from '../../functions/helper';

interface FilterComponentProps {
  active: boolean;
  tabTitle: string;
  tabId: string;
  tabIcon: React.ReactNode;
  tabPanelTitle: string;
  component: React.ReactNode;
}

interface FYBRProps {
  filterData: {
    guests: FilterComponentProps;
    beds: FilterComponentProps;
    view?: FilterComponentProps;
    showerTub?: FilterComponentProps;
  };
  dispatchFilterState: (value: Action | { clearAll: boolean }) => void;
  setShowerState?: (val: boolean) => void;
}

export function FindYourBestRoom(props: FYBRProps) {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const filterDataArray = Object.values(props?.filterData).filter(
    (filter) => filter.active === true
  );
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <div className="max-w-2xl w-full">
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList className="bg-transparent flex justify-center items-center p-3 border-b border-overlay">
          {filterDataArray?.map((item, key) => {
            return (
              <>
                <FYBRTab label={`tab-${slugify(item?.tabId)}`} id={key}>
                  {item?.tabIcon} <Text>{item?.tabTitle}</Text>
                </FYBRTab>
                {key < filterDataArray.length - 1 && <TabSeperator />}
              </>
            );
          })}
        </TabList>
        <TabPanels>
          {filterDataArray?.map((item, key) => {
            return (
              <FYBRPanel label={`panel-${slugify(item?.tabId)}`} id={key}>
                <h2 className="block">
                  <Text>{item?.tabPanelTitle}</Text>
                </h2>
                {item?.component}
              </FYBRPanel>
            );
          })}
        </TabPanels>
      </Tabs>
      <TabNavigationControls
        handleTabsChange={handleTabsChange}
        tabIndex={tabIndex}
        total={filterDataArray?.length}
        reset={props?.dispatchFilterState}
        setShowerState={props?.setShowerState}
      />
    </div>
  );
}

interface TabAndPanelProps {
  children: React.ReactNode;
  label: string;
  id?: number;
}

interface FYBRTabProps extends TabAndPanelProps {
  id: number;
}

// interface FYBRPanelProps extends TabAndPanelProps {}

function FYBRTab(props: FYBRTabProps) {
  const { selectedIndex } = useTabsContext();
  const currentTab = props.id === selectedIndex ? true : false;
  const previousTabs = props.id < selectedIndex ? true : false;
  const nextTabs = props.id > selectedIndex ? true : false;
  const tabHoverFocus = 'hover:opacity-80 focus:hover-80';

  return (
    <Tab
      key={props?.id}
      className={cx(
        'text-black bg-transparent flex items-center content-center border-none px-0 transition-opacity ease-in-out',
        currentTab && 'opacity-100',
        previousTabs && `opacity-60 ${tabHoverFocus}`,
        nextTabs && `opacity-30 ${tabHoverFocus}`
      )}
      data-testid={props?.label}
    >
      {props.children}
    </Tab>
  );
}

function FYBRPanel(props: TabAndPanelProps) {
  return (
    <TabPanel
      key={props?.id}
      tabIndex={-1}
      className="flex flex-col justify-center items-center py-8 px-2 exclude-focus"
      data-testid={props?.label}
    >
      {props.children}
    </TabPanel>
  );
}

function Text(props: { children: React.ReactNode }) {
  return (
    <span className="text-base sm:text-lg leading-6 block">
      <strong>{props.children}</strong>
    </span>
  );
}

function TabSeperator() {
  return (
    <hr className="border-black w-[16px] sm:w-[38px] h-[1px] ml-4 mr-4 sm:ml-[20px] sm:mr-[18px]" />
  );
}

interface TabNavProps {
  handleTabsChange: (i: number) => void;
  tabIndex: number;
  total: number;
  reset: FYBRProps['dispatchFilterState'];
  setShowerState?: (val: boolean) => void;
}

function TabNavigationControls({
  handleTabsChange,
  tabIndex,
  total,
  reset,
  setShowerState,
}: TabNavProps) {
  const [t] = useTranslation();
  const buttonClasses = 'flex items-center';
  const disabledClasses = 'opacity-50 cursor-not-allowed';

  return (
    <nav className="flex py-2 border-b border-overlay">
      <button
        className={`mr-auto text-primary ${buttonClasses} ${
          tabIndex === 0 && disabledClasses
        }`}
        onClick={() => tabIndex > 0 && handleTabsChange(--tabIndex)}
        aria-label={t('fybrNavPrev')}
        data-testid="fybr-nav-prev"
        data-element-id="fybr-nav-prev"
      >
        <ArrowHead className="transform rotate-90 w-6" />
        <span className="font-bold underline">{t('previous')}</span>
      </button>
      <button
        className={`ml-auto text-primary ${buttonClasses}`}
        onClick={() => {
          handleTabsChange(0);
          reset({ clearAll: true });
          setShowerState(false);
        }}
        aria-label={t('fybrNavReset')}
        data-testid="fybr-nav-reset"
        data-element-id="fybr-nav-reset"
      >
        <Refresh className="w-6 transform fill-current" />
        <span className="font-bold underline">{t('startAgain')}</span>
      </button>
      <button
        className={`ml-4 text-primary ${buttonClasses} ${
          tabIndex === total - 1 && disabledClasses
        }`}
        onClick={() => tabIndex < total - 1 && handleTabsChange(++tabIndex)}
        aria-label={t('fybrNavNext')}
        data-testid="fybr-nav-next"
        data-element-id="fybr-nav-next"
      >
        <span className="font-bold underline">{t('next')}</span>
        <ArrowHead className="transform -rotate-90 w-6" />
      </button>
    </nav>
  );
}

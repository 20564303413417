/**
 * NOW KNOWN AS COPY BLOCK
 */
import { useEffect, useState } from 'react';
import { AnchorLink, ACFAnchorProps } from './global/anchor-link';
import cx from 'classnames';
import {
  GIS_Array,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { WrappedHeading } from '@curated-property/utils';
import Route from 'next/router';
import styles from './css/contentStyle.module.css';
import {
  useWindowSize,
  HandleWYSIWYGContent,
  HandleAnimations,
} from './functions/helper';
import { inlineBackgroundStyles } from '@curated-property/utils';
import { sanitize } from '@curated-property/utils';

let componentInstance: number | null = null;

export interface IntroTextProps {
  componentIndex: number;
  isHomepage: boolean;
  propertyHasAlert: boolean;
  headline?: string;
  headline_noTx?: string;
  headlineAccent?: string;
  headlineAccent_noTx?: string;
  combinedTitleSubtitle?: string;
  pullQuote?: boolean;
  titleSize?: string;
  subtitleSize?: string;
  text?: string;
  imageSize?: string;
  image?: {
    altText?: string;
    sourceUrl?: string;
  };
  buttons?: Array<ACFAnchorProps>;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  badge?: {
    link: string;
    imageSource: string;
    imageAltText: string;
  };
}

export function IntroText({
  componentIndex,
  isHomepage,
  propertyHasAlert,
  headline,
  headline_noTx,
  headlineAccent,
  headlineAccent_noTx,
  combinedTitleSubtitle,
  pullQuote,
  titleSize,
  subtitleSize,
  text,
  image,
  imageSize,
  buttons,
  globalStyles,
  instanceStyles,
}: IntroTextProps) {
  const [headerHeight, setHeaderHeight] = useState('');
  // check for route change and reset component instance
  const currentRoute = Route?.router?.asPath;
  useEffect(() => {
    componentInstance = null;
  }, [currentRoute]);
  // set this variable to the component key once so that we know what instance of the component we're on - on the current page
  if (componentInstance === null) {
    componentInstance = componentIndex;
  }
  //set h1 if this is the first componentinstance
  const hasH1 =
    componentInstance === componentIndex && isHomepage === false ? 'h1' : 'h2';
  const windowSize = useWindowSize();
  const defaultPaddingSize = propertyHasAlert ? '164px' : '128px';

  useEffect(() => {
    if (typeof document !== 'undefined' && componentIndex === 0) {
      setHeaderHeight(
        `${document?.getElementsByTagName('header')?.[0]?.offsetHeight}px`
      );
    }
  }, [headerHeight, windowSize]);
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const flexDirection =
    inlineStyles?.layoutDirection === 'col'
      ? 'lg:flex-col lg:px-16 xl:px-16'
      : 'lg:flex-row md:px-16 xl:px-16';
  const titleWidth =
    inlineStyles?.layoutDirection === 'col' ? 'w-full' : 'lg:w-5/12';
  const copyWidth =
    inlineStyles?.layoutDirection === 'col' ? 'w-full max-w-4xl' : 'lg:w-7/12';
  const headerStyles = inlineStyles?.layoutDirection === 'col' ? 'mb-6' : '';
  const copyStyles = inlineStyles?.layoutDirection === 'col' ? '' : 'lg:pl-2';
  const copyContainer = 'container';

  let alignItems: string, imageAlignStyles: string;
  const imageHeight =
    imageSize === 'medium' ? 'h-24' : imageSize === 'large' ? 'h-32' : 'h-16';

  switch (inlineStyles?.textAlignment) {
    case 'flex-start':
      alignItems = 'items-start';
      imageAlignStyles = 'ml-0';
      break;
    case 'center':
      alignItems = 'items-center';
      imageAlignStyles = 'm-auto';
      break;
    case 'flex-end':
      alignItems = 'items-end';
      imageAlignStyles = 'ml-auto';
      break;
    default:
      alignItems = 'items-start';
      imageAlignStyles = 'ml-0';
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : alignItems === 'items-center'
      ? '-translate-y-8'
      : alignItems === 'items-end'
      ? 'translate-x-8'
      : '-translate-x-8',
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
    delayFive: 'delay-700',
  });

  return (
    <div
      className={cx(inlineStyles?.showHide && 'hidden')}
      style={{
        paddingTop:
          !headerHeight && componentIndex === 0
            ? defaultPaddingSize
            : headerHeight,
        transition: 'padding-top .5s ease',
      }}
    >
      <div
        className={cx('copy-block', paddingStyles)}
        style={{
          ...inlineBackgroundStyles(inlineStyles, 'component'),
          backgroundRepeat:
            inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.componentBackgroundPosition || 'left center',
        }}
      >
        <div
          ref={animations?.ref}
          data-testid="offsetAndDirectionContainer"
          className={cx(
            'z-[21] flex flex-col px-4 bg-bottom bg-right bg-no-repeat transform relative',
            alignItems,
            copyContainer,
            flexDirection
          )}
          style={{
            ...inlineBackgroundStyles(inlineStyles, 'content'),
            backgroundRepeat:
              inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition:
              inlineStyles?.contentBackgroundPosition || 'left center',
            textAlign: textAlignment,
            justifyContent: inlineStyles?.textAlignment,
          }}
        >
          {headline || headlineAccent ? (
            <div className={cx('pr-2', titleWidth, animations?.one)}>
              <WrappedHeading
                header={hasH1}
                className={cx(
                  'text-4xl font-black leading-none font-headline md:text-5xl',
                  headerStyles,
                  {
                    OneLinkNoTx: inlineStyles?.headingsNoTranslate || pullQuote,
                    italic: pullQuote,
                  }
                )}
                lang={pullQuote ? 'en' : ''}
              >
                <>
                  <span
                    className={cx(
                      titleSize === 'small' && 'md:text-4xl',
                      titleSize === 'large' && 'md:text-6xl',
                      'block'
                    )}
                    style={{ color: inlineStyles?.titleColor }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        inlineStyles?.headingsNoTranslate || pullQuote
                          ? headline_noTx || ''
                          : headline || ''
                      ),
                    }}
                  />
                  <span
                    data-element-id="subtitle-wrapper"
                    className={cx(
                      subtitleSize === 'small' && 'md:text-4xl',
                      subtitleSize === 'large' && 'md:text-6xl',
                      'block text-secondary',
                      animations?.two
                    )}
                    style={{ color: inlineStyles?.subtitleColor }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        inlineStyles?.headingsNoTranslate
                          ? headlineAccent_noTx || ''
                          : headlineAccent || ''
                      ),
                    }}
                  />
                </>
              </WrappedHeading>
              <span className="hidden">{combinedTitleSubtitle}</span>
            </div>
          ) : (
            ''
          )}
          <div
            className={cx(
              'mt-4 text-lg lg:mt-0',
              copyWidth,
              copyStyles,
              styles.listStyle,
              styles.paragraphStyle
            )}
          >
            <div
              className={animations?.three}
              style={{ color: inlineStyles?.textColor }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(text, inlineStyles?.textColor),
              }}
            ></div>
            {image?.sourceUrl ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                className={cx(
                  'w-auto my-2 block',
                  imageHeight,
                  imageAlignStyles,
                  animations?.four
                )}
                src={image?.sourceUrl}
                alt={image?.altText}
              />
            ) : null}
            {buttons ? (
              <div className={cx('mt-6', animations?.five)}>
                {buttons?.map((link, key) => {
                  if (!link) {
                    return null;
                  }
                  return (
                    <AnchorLink
                      key={key}
                      url={link.link?.url || ''}
                      title={link.link?.title || ''}
                      target={link.link?.target || ''}
                      buttonStyle={link?.buttonStyle || 'primary-outline'}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

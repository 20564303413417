import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useLanguage } from '@curated-property/utils';

export type IRateData = {
  /**
   * Label text for the input field
   */
  label?: string;

  /**
   * CSS overrides for the label
   */
  labelClass?: string;
  /**
   * Nested label text available for the input field
   */
  inputLabel?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const sections: IRateData[][] = [
  [
    { name: 'usePoints', value: 'false', type: 'checkbox' },
    { name: 'travelAgents', value: 'false', type: 'checkbox' },
    { name: 'aaaRate', value: 'false', type: 'checkbox' },
    { name: 'aarpRate', value: 'false', type: 'checkbox' },
    { name: 'seniorRate', value: 'false', type: 'checkbox' },
    { name: 'gvmtRates', value: 'false', type: 'checkbox' },
  ],
  [
    { name: 'promoCode', value: '', type: 'text', maxLength: 6 },
    { name: 'groupCode', value: '', type: 'text', maxLength: 6 },
    { name: 'corpAccount', value: '', type: 'text', maxLength: 10 },
  ],
];

export interface SpecialRatesFormProps {
  setRateValues(rates: IRateData[][]): void;
  sections: IRateData[][];
}

export function SpecialRatesForm({
  setRateValues,
  sections,
}: SpecialRatesFormProps) {
  const [t] = useTranslation();
  const lang = useLanguage();
  const [rateValuesResult, setRateValuesResult] = useState<IRateData[][]>();

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const result = sections.map((items) =>
      items.map((i) => {
        if (i.name === e.target.name) {
          i.value =
            i.type === 'checkbox'
              ? e.target.checked
                ? 'true'
                : 'false'
              : e.target.value;
        }
        return i;
      })
    );
    setRateValues(result);
    setRateValuesResult(result);
  }

  const checkDisabled = (item: IRateData, sectionKey: number) => {
    if ((rateValuesResult?.length || 0) > sectionKey && item.type === 'text') {
      const rateValues = rateValuesResult?.[sectionKey].map((r) => {
        if (r.name === item.name) {
          return '';
        } else {
          return r.value;
        }
      });

      return rateValues?.filter((r) => !!r).length !== 0;
    }

    return false;
  };

  return (
    <div role="application" className="grid gap-3 p-2">
      {sections.map((items, sectionkey) => {
        return (
          <div
            className="grid gap-3 md:grid-cols-2 lg:grid-cols-3"
            key={sectionkey}
          >
            {items.map((item, key) => (
              <label
                key={key}
                className={cx('label', {
                  'label-inline-reverse': item.type === 'checkbox',
                })}
              >
                {t(`rates.${item.name}`)}
                <input
                  onChange={onChange}
                  type={item.type}
                  name={item.name}
                  value={item.value}
                  checked={
                    item.type === 'checkbox' &&
                    (item.value === 'true' ? true : false)
                  }
                  className={cx({
                    'form-checkbox': item.type === 'checkbox',
                    'form-input disabled:opacity-50': item.type === 'text',
                    'mr-2': item.type === 'checkbox',
                    'ml-2 mr-0': lang === 'ar',
                  })}
                  disabled={checkDisabled(item, sectionkey)}
                />
              </label>
            ))}
          </div>
        );
      })}
    </div>
  );
}

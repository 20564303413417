import React from 'react';
import cx from 'classnames';
import { HeroVideo, HeroVideoControls } from './video';
import { HeroImageOverlayProps } from './hero-image.types';

export function HeroImageOverlay({
  hideAnimations,
  animations,
  videoAutoplay,
  videoCtaPlay,
  videoCtaPause,
  videoCtaShadow,
  videoPause,
  setVideoPause,
  videoActive,
  setVideoActive,
  videoMute,
  setVideoMute,
  videoUrl,
  videoMuteChoice,
  videoQualityOverride,
  videoQualitySlider,
  videoChoice,
  inlineStyles,
  copyContent,
  vidRef,
}: HeroImageOverlayProps) {
  return (
    <div className="h-full">
      <div
        ref={animations?.ref}
        data-testid="contentOverlay"
        className={cx(
          'max-w-screen-2xl mx-auto relative leading-none font-headline h-full w-full flex flex-col flex-wrap',
          'justify-end lg:justify-center items-center'
        )}
      >
        {copyContent}
        {videoChoice && (
          <HeroVideoControls
            inlineStyles={inlineStyles}
            animations={animations}
            videoMuteChoice={videoMuteChoice}
            videoMute={videoMute}
            setVideoMute={setVideoMute}
            vidRef={vidRef}
            videoPause={videoPause}
            videoCtaPause={videoCtaPause}
            videoCtaPlay={videoCtaPlay}
            videoCtaShadow={videoCtaShadow}
            videoActive={videoActive}
            setVideoActive={setVideoActive}
            setVideoPause={setVideoPause}
          />
        )}
      </div>
      {videoChoice && videoActive && (
        <HeroVideo
          videoUrl={videoUrl}
          videoQualityOverride={videoQualityOverride}
          videoQualitySlider={videoQualitySlider}
          vidRef={vidRef}
          videoAutoplay={videoAutoplay}
          hideAnimations={hideAnimations}
        />
      )}
    </div>
  );
}

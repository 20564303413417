/* eslint-disable complexity */
import React, {
  useReducer,
  useRef,
  useState,
  useContext,
  useEffect,
} from 'react';
import {
  appliedCloudinaryParams,
  FormChangeMonitor,
  parseBookingDate,
  SharedContext,
  useInitializeConductrics,
} from '@curated-property/utils';
import {
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
  GIS_merge,
} from '../functions/global-instance-styles';
import { Tabs, TabList, TabPanels } from '@reach/tabs';
import { FilterTags } from './filters/filter-flyout';
import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { addDays } from 'date-fns';
import { Room, Filters } from './buildFilters';
import { useTranslation } from 'next-i18next';
import { HandleAnimations } from '../functions/helper';
import { useRouter } from 'next/router';
import { useLocation } from '@dx-ui/framework-location-provider';
import {
  Information,
  Bath,
  Bed,
  KidFriendly,
  Resort,
} from '@curated-property/icon-list';
import { getAvailQuery } from './dx-gql';
import { FilterFlyoutDisplay } from './filters/filter-flyout-display';
import { FindYourBestRoom } from './filters/fybr-filters';
import {
  GuestsPanel,
  CheckBoxPanel,
  TubCustomCheckboxPanels,
} from './filters/fybr-filter-panels';
import {
  formatDate,
  GuestsState,
  mergeRooms,
  parseRoomFilterQueryParams,
} from './utils';
import { RoomCard } from './includes/room-card';
import { FilterTab } from './filters/filter-panel-tab';
import { FilterPanel } from './filters/filter-panel';
import {
  RoomDetailsModal,
  RoomDetailsModalProps,
} from './includes/room-details-modal';
import {
  RoomVirtualTourModal,
  RoomVirtualTourModalProps,
} from './includes/room-virtual-tour-modal';
import { RoomsCTAProps } from './includes/rooms-cta';
import {
  Status,
  getConductricsSelection,
  sendReward,
} from '@dx-ui/framework-conductrics';
import { FormProvider, useForm } from 'react-hook-form';
import { DatePicker } from '@dx-ui/osc-date-picker';
import { useIsClient } from 'usehooks-ts';

export interface RoomTypesProps {
  rooms: Room[];
  filters: Filters;
  pricingEnabled: boolean;
  allowAdultsOnly?: boolean;
  hideLeadRates?: boolean;
  hideBedsFilter?: boolean;
  hideMaxGuestsFilter?: boolean;
  hidePriceFilter?: boolean;
  arrivalDate?: string;
  gmtHours?: number;
  pricingDisclaimer?: string;
  sortRoomsBy?: string;
  ctyhocn: string;
  associatedHotels?: string[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  customFilters?: CustomFilters;
  resEnabled?: boolean;
  showFindYourBestRoomFilters?: boolean;
}

export interface CustomFilters {
  pageTitle?: string;
  SettingsRoomSettings?: {
    roomFilters1Title?: string;
    roomFilters2Title?: string;
  };
}

function usePrevious(data: any) {
  const ref = useRef();
  if (!data) {
    return ref.current;
  }
  ref.current = data;
  return data;
}

//export default function RoomTypes({
export function RoomTypes({
  rooms,
  pricingEnabled,
  allowAdultsOnly,
  hideLeadRates,
  hideBedsFilter,
  hideMaxGuestsFilter,
  hidePriceFilter,
  filters,
  gmtHours,
  arrivalDate,
  pricingDisclaimer,
  sortRoomsBy,
  ctyhocn,
  associatedHotels,
  globalStyles,
  instanceStyles,
  customFilters,
  resEnabled,
  showFindYourBestRoomFilters,
}: RoomTypesProps) {
  const sharedContext = useContext(SharedContext);
  const [roomDetailsModalProps, setRoomDetailsModalProps] =
    useState<RoomDetailsModalProps>();
  const closeRoomDetailsModal = () =>
    setRoomDetailsModalProps({ modalActive: false, setModalActive: undefined });
  const [roomVirtualTourModalProps, setRoomVirtualTourModalProps] =
    useState<RoomVirtualTourModalProps>();
  const closeRoomVirtualTourModal = () =>
    setRoomVirtualTourModalProps({
      modalActive: false,
      setModalActive: undefined,
    });

  useInitializeConductrics();

  const [conductricsData, setConductricsData] = useState({ items: [] });
  const [forceShowFYBR, setForceShowFYBR] = useState(false);
  useEffect(() => {
    const fetchConductics = async function () {
      await getConductricsSelection('curated3523FYBR', Status.OK).then(
        (res) => {
          setConductricsData({ items: res?.items });
        }
      );
    };
    fetchConductics();
  }, []);

  useEffect(() => {
    setForceShowFYBR(
      conductricsData?.items?.length > 0 && conductricsData?.items[0]?.c === 'B'
    );
  }, [conductricsData]);
  const [sortState, setSortState] = useState('');
  const [showerState, setShowerState] = useState(false);
  const startDate = new Date(parseBookingDate(gmtHours, arrivalDate));
  const isClient = useIsClient();
  const methods = useForm<{
    dates: { arrivalDate: Date | undefined; departureDate: Date | undefined };
  }>({
    defaultValues: {
      dates: {
        arrivalDate: startDate,
        departureDate: addDays(startDate, 1),
      },
    },
  });
  const { watch } = methods;
  const watchDates = watch('dates');
  const dates = {
    arrival: formatDate(watchDates.arrivalDate),
    departure: watchDates.departureDate
      ? formatDate(watchDates.departureDate)
      : undefined,
  };

  const [guests, setGuests] = useState<GuestsState>({
    adults: sharedContext?.allInclusive ? 2 : 1,
    kids: 0,
    kidsAges: [],
  });
  const [t] = useTranslation();
  const { query, locale: routerLocale = 'en' } = useRouter();
  const location = useLocation();

  let firstHotel, otherHotelRooms;
  const ctyhocns = associatedHotels?.length > 0 ? associatedHotels : [ctyhocn];

  function CorePlusQuery(ctyhocn: string) {
    // Please note that 'US' is used as a default country as a means of allowing rates through in localhost.
    const queryResult = useQuery({
      queryKey: [
        getAvailQuery,
        {
          ctyhocn: ctyhocn,
          arrivalDate: dates.arrival,
          departureDate: dates.departure,
          language: 'en',
          numAdults: sharedContext?.allInclusive ? 2 : 1,
          guestLocationCountry: location?.country || 'US',
        },
      ],

      enabled: pricingEnabled && !!dates.departure,
    });

    return queryResult;
  }

  function addCtyhocnToRoomTypes(rooms, ctyhocn) {
    rooms?.forEach((r) => {
      if (typeof r === 'object') {
        r.ctyhocn = ctyhocn;
      }
    });
    return rooms;
  }

  for (let i = 0; i < ctyhocns?.length; i++) {
    let query;
    try {
      query = CorePlusQuery(ctyhocns[i]);
    } catch (err) {
      // we don't need to do anything with this error, as it's somewhat expected
    }

    if (i === 0) {
      firstHotel = query;
      addCtyhocnToRoomTypes(
        firstHotel?.data?.hotel?.shopAvail?.roomTypes,
        ctyhocns[i]
      );
    } else {
      otherHotelRooms =
        addCtyhocnToRoomTypes(
          query?.data?.hotel?.shopAvail?.roomTypes,
          ctyhocns[i]
        ) || [];
      firstHotel?.data?.hotel?.shopAvail?.roomTypes.push(...otherHotelRooms);
    }
  }

  const shopAvailQuery = firstHotel;

  const { data, isError, isFetching: isLoading, status } = shopAvailQuery || {};

  const potentiallyPreviousData = usePrevious(isError ? {} : data);

  const mergedRooms = mergeRooms({
    rooms,
    availRooms: potentiallyPreviousData?.hotel?.shopAvail?.roomTypes,
  });

  const [filterState, dispatchFilterState] = useReducer(reducer, {
    balconyDetail: parseRoomFilterQueryParams(query['balconyDetail']),
    guests: parseRoomFilterQueryParams(query['guests']),
    numBeds: parseRoomFilterQueryParams(query['numBeds']),
    brandName: parseRoomFilterQueryParams(query['brandName']),
    adaAccessibleRoom: parseRoomFilterQueryParams(query['adaAccessibleRoom']),
    recommendedFor: parseRoomFilterQueryParams(query['recommendedFor']),
    premiumOptions: parseRoomFilterQueryParams(query['premiumOptions']),
    outdoorFeatures: parseRoomFilterQueryParams(query['outdoorFeatures']),
    roomFeatures: parseRoomFilterQueryParams(query['roomFeatures']),
    roomType: parseRoomFilterQueryParams(query['roomType']),
    bathroomAmenities: parseRoomFilterQueryParams(query['bathroomAmenities']),
    locations: parseRoomFilterQueryParams(query['locations']),
    customFilter1: parseRoomFilterQueryParams(query['customFilter1']),
    customFilter2: parseRoomFilterQueryParams(query['customFilter2']),
    bedType: parseRoomFilterQueryParams(query['bedType']),
    view: parseRoomFilterQueryParams(query['view']),
    showerTubAmenities: parseRoomFilterQueryParams(query['showerTubAmenities']),
  });

  const filteredRooms = mergedRooms.filter((room) => {
    return Object.keys(filterState).every((key) => {
      if (filterState[key].length === 0) {
        if (forceShowFYBR) sendReward('g-zeroresultscount');
        return true;
      }
      const roomAttribute = room[key];

      if (key === 'guests') {
        return (
          filterState[key].length && filterState[key][0] <= room['maxOccupancy']
        );
      } else if (key === 'showerTubAmenities') {
        return filterState[key]?.every((k) =>
          room?.showerTubAmenities[0]?.name?.includes(k)
        );
      } else if (key === 'customFilter1') {
        return filterState[key]?.every((k) =>
          room?.showCustomFilter1?.customFilter1Select?.includes(k)
        );
      } else if (key === 'customFilter2') {
        return filterState[key]?.every((k) =>
          room?.showCustomFilter2?.customFilter2Select?.includes(k)
        );
      } else if (Array.isArray(roomAttribute)) {
        return filterState[key]?.every((k) => roomAttribute.includes(k));
      } else
        return (
          filterState[key].includes(roomAttribute) ||
          filterState[key].includes(room.customView) ||
          filterState[key].includes(room.customBalcony)
        );
    });
  });

  const roomResultsCount = filteredRooms.length;

  // if any of the rooms have prices, rooms without prices are considered "sold out", or "not available"
  // vs if no rooms have prices then there was an error fetching pricing info
  const hasPrices = mergedRooms?.some(
    (r) => !!r.moreRatesFromRate?.rateAmountFmt
  );

  // If dates are set but departure is undefined for some reason (closing the widget early), set it to arrival+1
  // removing this temporarily to figure out what went wrong
  // {
  //   dates &&
  //     dates?.departure === undefined &&
  //     (dates.departure = formatDate(addDays(parseDate(dates.arrival), 1)));
  // }

  const [activeId, setActiveId] = useState('');

  const idCheck = (idName) => {
    setActiveId(idName);
    document.getElementById(idName).focus();
  };

  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  const promoteAvailable = (a, b) => {
    if (a.moreRatesFromRate && !b.moreRatesFromRate) {
      return -1;
    } else if (!a.moreRatesFromRate && b.moreRatesFromRate) {
      return 1;
    } else return 0;
  };

  const handleSortState = (item) => {
    if (sortState === item) {
      setSortState('');
    } else setSortState(item);
  };

  let maxOccupancy;
  if (filters?.guests?.length > 0) {
    maxOccupancy = filters.guests.reduce(function (prev, current) {
      return prev && prev.value > current.value ? prev : current;
    });
  }

  // Keep values that are not null
  const filterView = filters?.view?.filter((v) => v?.value !== null);
  const filterBathroom = filters?.bathroomAmenities?.filter(
    (v) => v?.value !== null
  );
  const filterShower = filters?.showerTubAmenities?.filter(
    (v) => v?.value !== null
  );

  const hideTabsIfFiltersDontExist =
    filterView?.length > 0 ||
    filterBathroom?.length > 0 ||
    filterShower?.length > 0;

  const filterByFeatures = (
    <FilterFlyoutDisplay
      filters={filters}
      filterState={filterState}
      dispatchFilterState={dispatchFilterState}
      idCheck={idCheck}
      handleSortState={handleSortState}
      sortState={sortState}
      hasPrices={hasPrices}
      customFilters={customFilters}
      labelColor={inlineStyles?.textColor}
      hideFilters={{
        beds: hideBedsFilter,
        maxGuests: hideMaxGuestsFilter,
        price: hidePriceFilter,
      }}
    />
  );

  const handlePriceSort = (a, b): number => {
    const aNum = Number(a.moreRatesFromRate?.rateAmountFmt.replace(/\D/g, ''));
    const bNum = Number(b.moreRatesFromRate?.rateAmountFmt.replace(/\D/g, ''));

    // Default room tiles sorting to price (low - high)
    // This applies to room tiles, room tiles lite and rooms from posts
    switch (true) {
      case sortState === '>':
        return aNum > bNum ? -1 : aNum < bNum ? 1 : 0;
      case sortState === '<':
        return aNum < bNum ? -1 : aNum > bNum ? 1 : 0;
      case sortRoomsBy !== 'custom':
        return aNum < bNum ? -1 : aNum > bNum ? 1 : 0;
      default:
        return 0;
    }
  };

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });

  return (
    <div
      data-element-id="room-types-wrapper"
      className={cx(
        inlineStyles?.showHide && 'hidden',
        'bg-bg-alt cp-roomTypes',
        paddingStyles
      )}
      style={{
        backgroundColor: inlineStyles?.filterBackgroundColor || null,
      }}
    >
      <div ref={animations?.ref} className="container px-0">
        <div className="grid lg:flex-col items-end justify-center px-2 md:px-4">
          {pricingEnabled && (
            <div
              className={cx(
                'rooms-widget-wrapper flex justify-center',
                animations?.one
              )}
            >
              {isClient ? (
                <FormProvider {...methods}>
                  <DatePicker language={routerLocale} hideFlexDates={true} />
                </FormProvider>
              ) : null}
            </div>
          )}
          <div className={cx('w-full', animations?.two)}>
            {forceShowFYBR ||
            (showFindYourBestRoomFilters && hideTabsIfFiltersDontExist) ? (
              <Tabs
                data-testid="filter-tabs"
                onChange={(index) => {
                  if (index == 1) {
                    //FYBR
                    sendReward('CuratedFYBRTabClick');
                  }
                }}
              >
                <TabList
                  className={
                    '!bg-transparent flex items-center justify-center my-8'
                  }
                >
                  <FilterTab id={0} label="filter-tab-features">
                    {t('filterByFeatures')}
                  </FilterTab>
                  <div className={'w-[1px] h-[40px] bg-black'}></div>
                  <FilterTab id={1} label="filter-tab-fybr">
                    {t('findYourBestRoom')}
                  </FilterTab>
                </TabList>
                <TabPanels>
                  <FilterPanel
                    className="grid grid-cols-2 md:grid-cols-5 gap-2 lg:flex lg:space-x-2 flex-wrap justify-center"
                    label="filter-panel-features"
                  >
                    <FormChangeMonitor
                      onAny={() =>
                        sendReward('g-curated-fybr-used-default-filter')
                      }
                    >
                      {filterByFeatures}
                    </FormChangeMonitor>
                  </FilterPanel>
                  <FilterPanel
                    className="flex flex-wrap justify-center"
                    label="filter-panel-fybr"
                  >
                    <FormChangeMonitor
                      onAny={() => sendReward('g-curated-fybr-used-new-filter')}
                    >
                      <FindYourBestRoom
                        dispatchFilterState={dispatchFilterState}
                        setShowerState={setShowerState}
                        filterData={{
                          guests: {
                            active: true,
                            tabTitle: t('guestsLabel'),
                            tabId: 'guests',
                            tabIcon: <KidFriendly className="w-8 mr-1" />,
                            tabPanelTitle: t('howManyGuests'),
                            component: (
                              <GuestsPanel
                                guests={guests}
                                setGuests={setGuests}
                                maxOccupancy={maxOccupancy?.value as number}
                                adultsOnly={allowAdultsOnly}
                                data={filters?.adaAccessibleRoom}
                                dispatchFilterState={dispatchFilterState}
                                filterState={filterState}
                              />
                            ),
                          },
                          beds: {
                            active: true,
                            tabTitle: t('beds'),
                            tabId: 'beds',
                            tabIcon: <Bed className="w-8 mr-1" />,
                            tabPanelTitle: t('whichBedSize'),
                            component: (
                              <CheckBoxPanel
                                data={filters?.bedType}
                                dispatchFilterState={dispatchFilterState}
                                filterState={filterState?.bedType}
                                filterKey="bedType"
                              />
                            ),
                          },
                          view: {
                            active: filterView?.length > 0,
                            tabTitle: t('view'),
                            tabId: 'view',
                            tabIcon: <Resort className="w-8 mr-1" />,
                            tabPanelTitle: t('whichView'),
                            component: (
                              <CheckBoxPanel
                                data={filterView}
                                dispatchFilterState={dispatchFilterState}
                                filterState={filterState?.view}
                                filterKey="view"
                              />
                            ),
                          },
                          showerTub: {
                            active:
                              filterShower?.length > 0 ||
                              filterBathroom?.length > 0,
                            tabTitle: t('showerTub'),
                            tabId: 'shower',
                            tabIcon: <Bath className="w-8 mr-1" />,
                            tabPanelTitle:
                              filterShower?.length > 0
                                ? t('showerOrTub')
                                : t('bathroomAmenities'),
                            component:
                              filterShower?.length > 0 ? (
                                <TubCustomCheckboxPanels
                                  data={filterShower}
                                  dispatchFilterState={dispatchFilterState}
                                  filterState={filterState?.showerTubAmenities}
                                  filterKey={'showerTubAmenities'}
                                  showerState={showerState}
                                  setShowerState={setShowerState}
                                />
                              ) : (
                                <CheckBoxPanel
                                  data={filterBathroom}
                                  dispatchFilterState={dispatchFilterState}
                                  filterState={filterState?.bathroomAmenities}
                                  filterKey={'bathroomAmenities'}
                                />
                              ),
                          },
                        }}
                      />
                    </FormChangeMonitor>
                  </FilterPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <div className="grid grid-cols-2 gap-2 lg:flex lg:space-x-2 flex-wrap justify-center py-6">
                {filterByFeatures}
              </div>
            )}
          </div>
        </div>

        {Object.values(filterState).some((a) => a?.length) ||
        sortState ||
        showerState ? (
          <FilterTags
            className={animations?.two}
            selectedFilters={Object.keys(filterState)
              .reduce((prev, key) => {
                const selectedElements = filterState[key];
                const i = selectedElements.map((value) => {
                  return {
                    label: filters[key].find((f) => f.value === value)?.label,
                    value: filters[key],
                    remove() {
                      document.getElementById(activeId)?.focus();
                      dispatchFilterState({
                        checked: false,
                        item: value,
                        key: key as Action['key'],
                      });
                    },
                  };
                });

                return prev.concat(i);
              }, [])
              .concat([
                {
                  label:
                    sortState === '<'
                      ? t('lowToHigh')
                      : sortState === '>'
                      ? t('highToLow')
                      : null,
                },
                {
                  label: showerState ? t('showerLabel') : null,
                },
              ])}
            clearAll={() => {
              setSortState('');
              setShowerState(false);
              dispatchFilterState({ clearAll: true });
              document.getElementById(activeId)?.focus();
            }}
            clearPriceSort={() => setSortState('')}
            clearShowerState={() => setShowerState(false)}
          />
        ) : null}

        <div
          data-testid="roomResults"
          className={cx(
            'flex justify-center mb-5 border-b py-4',
            animations?.three
          )}
          style={{ borderColor: inlineStyles?.filterRuleColor || null }}
        >
          <div style={{ color: inlineStyles?.filterTitleColor || null }}>
            {t('weFoundResults', { count: roomResultsCount })}
          </div>
        </div>

        <div className={cx('space-y-2 mb-4', animations?.three)}>
          {isError && (
            <div className="py-2 px-3 bg-warn-alt text-sm">
              <Information className="h-5 w-5 inline-block mr-2" />
              {t('fetchPricingError')}
            </div>
          )}

          {hasPrices && pricingDisclaimer && (
            <div className="py-2 px-3 bg-success-alt text-success text-sm flex items-center">
              <Information className="h-5 w-5 inline-block mr-2" />
              {pricingDisclaimer}
            </div>
          )}
        </div>
        <div
          className="grid md:grid-flow-row-dense grid-cols-1 gap-x-5 gap-y-5 md:gap-y-6 md:grid-cols-2 lg:grid-cols-3 px-2 md:px-4 2xl:px-0 pt-2 md:pt-4 py-4 overflow-hidden"
          style={{
            backgroundImage: inlineStyles?.componentBackgroundImage
              ? `url('${appliedCloudinaryParams(
                  inlineStyles?.componentBackgroundImage?.sourceUrl,
                  inlineStyles?.componentBackgroundRepeat
                )}')`
              : null,
            backgroundColor: inlineStyles?.componentBackgroundColor || null,
            backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
            backgroundRepeat:
              inlineStyles?.componentBackgroundRepeat || 'no-repeat',
            backgroundPosition:
              inlineStyles?.componentBackgroundPosition || 'left center',
          }}
        >
          {filteredRooms
            ?.sort(promoteAvailable)
            .sort(handlePriceSort)
            .map((r, index) => {
              const iconData = [
                r?.sleeps?.[0]
                  ? {
                      id: 'user',
                      name: r?.sleeps?.[0]?.name,
                    }
                  : undefined,
                (r?.squareFootage || 0) > 0
                  ? {
                      id: r?.squareFootageIconGroup?.iconList || 'roomPlan',
                      name: t('squareFootage', { sqFt: r.squareFootage }),
                    }
                  : undefined,
                r?.balconyIconGroup?.iconList
                  ? {
                      id: r?.balconyIconGroup?.iconList,
                      name: r?.customBalcony || r?.balconyDetail,
                    }
                  : r?.balconyDetail === 'Other' && r?.customBalcony
                  ? {
                      id: r?.customBalcony,
                      name: r?.customBalcony,
                    }
                  : r?.balconyDetail &&
                    r?.balconyDetail !== 'none' &&
                    r?.balconyDetail !== 'Other'
                  ? {
                      id: 'balcony',
                      name: r?.balconyDetail,
                    }
                  : undefined,
                r?.viewIconGroup?.iconList
                  ? {
                      id: r?.viewIconGroup?.iconList,
                      name: r?.customView || r?.view,
                    }
                  : r?.view === 'Other' && r?.customView
                  ? {
                      id: r?.customView,
                      name: r?.customView,
                    }
                  : r?.view && r?.view !== 'none' && r?.view !== 'Other'
                  ? {
                      id:
                        r?.view === 'Ocean Front' || r?.view === 'Partial Ocean'
                          ? 'oceanView'
                          : r?.view,
                      name: r?.view,
                    }
                  : undefined,
              ];
              const rate =
                location?.continent === 'EU'
                  ? r?.moreRatesFromRate?.amountAfterTaxFmt
                  : r?.moreRatesFromRate?.rateAmountFmt;

              const ctaData: RoomsCTAProps = {
                room: r,
                arrivalDate: dates?.arrival,
                departureDate: dates?.departure,
                rate: rate,
                ctyhocn: ctyhocn,
                currency: potentiallyPreviousData?.hotel?.shopAvail?.currency,
                hasPrices:
                  !isLoading && !isError && !hideLeadRates && hasPrices,
                guests: guests,
              };
              return (
                <React.Fragment key={r.roomTypeCode}>
                  <RoomCard
                    key={r.roomTypeCode}
                    ind={index}
                    room={r}
                    rate={rate}
                    ctyhocn={ctyhocn}
                    ctaData={ctaData}
                    openVirtualTourModal={() => {
                      if (r.virtualMedia.length > 0) {
                        setRoomVirtualTourModalProps({
                          modalActive: true,
                          setModalActive: (active: boolean) =>
                            setRoomVirtualTourModalProps({
                              ...roomVirtualTourModalProps,
                              ...r.virtualMedia[0],
                              modalActive: active,
                            }),
                          ...r.virtualMedia[0],
                          ctaData: ctaData,
                        });
                      }
                    }}
                    closeVirtualTourModal={closeRoomVirtualTourModal}
                    openDetailsModal={() => {
                      setRoomDetailsModalProps({
                        inlineStyles: {
                          ...inlineStyles,
                          roomDetailsModalBackgroundColor:
                            inlineStyles?.modalBackgroundColor,
                          roomDetailsModalTextColor:
                            inlineStyles?.modalTextColor,
                          roomDetailsModalAccentColor:
                            inlineStyles?.modalAccentColor,
                          roomDetailsModalIconColor:
                            inlineStyles?.modalIconColor,
                        },
                        icons: iconData,
                        modalActive: true,
                        setModalActive: (active: boolean) =>
                          setRoomDetailsModalProps({
                            ...roomDetailsModalProps,
                            modalActive: active,
                          }),
                        ctyhocn: ctyhocn,
                        hasPrices:
                          !isLoading && !isError && !hideLeadRates && hasPrices,
                        rate: rate,
                        room: r,
                        arrivalDate: dates.arrival,
                        departureDate: dates?.departure,
                        textAlignment: textAlignment,
                        currency:
                          potentiallyPreviousData?.hotel?.shopAvail?.currency,
                        resEnabled: resEnabled,
                        associatedHotels: associatedHotels,
                        guests: guests,
                        ctaData: ctaData,
                      });
                    }}
                    closeDetailsModal={closeRoomDetailsModal}
                    loading={isLoading}
                    status={status}
                    queryData={data}
                    isError={!!isError}
                    hideLeadRate={hideLeadRates}
                    pricingEnabled={pricingEnabled}
                    icons={iconData}
                    inlineStyles={inlineStyles}
                    textAlignment={textAlignment}
                    currency={
                      potentiallyPreviousData?.hotel?.shopAvail?.currency
                    }
                    resEnabled={resEnabled}
                    associatedHotels={associatedHotels}
                    guests={guests}
                  />
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <RoomDetailsModal {...roomDetailsModalProps}></RoomDetailsModal>
      <RoomVirtualTourModal
        {...roomVirtualTourModalProps}
      ></RoomVirtualTourModal>
    </div>
  );
}

function remove<T>(arr: T[], item: T) {
  const index = arr.findIndex((i) => i === item);
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export interface State {
  view: string[];
  balconyDetail: string[];
  recommendedFor: string[];
  numBeds: string[];
  guests: string[];
  adaAccessibleRoom: string[];
  premiumOptions: string[];
  outdoorFeatures: string[];
  roomFeatures: string[];
  roomType: string[];
  bathroomAmenities: string[];
  locations: string[];
  customFilter1: string[];
  customFilter2: string[];
  bedType: string[];
}

export interface Action {
  key: keyof Filters;
  checked: boolean;
  item: string | number | boolean;
}
interface ClearAll {
  clearAll: boolean;
}

function isClearAll(a: Action | ClearAll): a is ClearAll {
  return (a as ClearAll).clearAll !== undefined;
}

function reducer(state: State, action: Action | { clearAll: boolean }) {
  if (isClearAll(action)) {
    window.history.replaceState(null, null, '?');
    return {
      view: [],
      balconyDetail: [],
      guests: [],
      numBeds: [],
      brandName: [],
      adaAccessibleRoom: [],
      recommendedFor: [],
      premiumOptions: [],
      outdoorFeatures: [],
      roomFeatures: [],
      roomType: [],
      bathroomAmenities: [],
      showerTubAmenities: [],
      locations: [],
      customFilter1: [],
      customFilter2: [],
      bedType: [],
    };
  }

  if (action.key === 'showerTubAmenities') {
    switch (action.item) {
      case '':
        return {
          ...state,
          showerTubAmenities: [],
        };
    }
  }

  const params = new URLSearchParams(window.location.search);
  let paramVals = params.get(action.key)?.split(',');
  if (!paramVals) {
    paramVals = [];
  }
  if (action.checked) {
    if (action.key === 'guests' && paramVals.length) {
      paramVals = [String(action.item)];
    } else if (!paramVals.includes(String(action.item))) {
      paramVals.push(String(action.item));
    }
    params.set(action.key, paramVals.join(','));
    window.history.replaceState(null, null, '?' + params.toString());
    return {
      ...state,
      [action.key]:
        action.key === 'guests'
          ? [action.item]
          : [...state[action.key], action.item],
    };
  }
  // uncheck
  if (paramVals.includes(String(action.item))) {
    paramVals = paramVals.filter((item) => item !== String(action.item));
  }
  paramVals = paramVals.filter((item) => item !== '' || item === undefined);
  if (paramVals?.length === 0) {
    params.delete(action.key);
  } else {
    params.set(action.key, paramVals.join(','));
  }
  window.history.replaceState(null, null, '?' + params.toString());
  return {
    ...state,
    [action.key]:
      action.key === 'guests' ? [] : remove(state[action.key], action.item),
  };
}

import React from 'react';
import { DateFormat } from './date-formatter';
import cx from 'classnames';
import { useLanguage } from '@curated-property/utils';

interface Props {
  className?: string;
  day: DateFormat;
}

export function Date({ className, day: { date, text } }: Props) {
  const lang = useLanguage();
  return (
    <div className={cx('w-32 flex items-end justify-center', className)}>
      <span
        data-element-id="booking-widget-day-button"
        className={cx(
          'pr-1 text-5xl font-bold leading-none md:text-4xl lg:text-5xl text-primary tabular-nums',
          lang === 'ar' && 'pl-1'
        )}
      >
        {date.day}
      </span>
      <div className={cx('flex flex-col items-end', lang === 'ar' && 'pl-3')}>
        <span className="block font-bold leading-none uppercase tabular-nums">
          {text.month}
        </span>
        <span className="block uppercase tabular-nums">{text.weekday}</span>
      </div>
    </div>
  );
}

/* eslint-disable no-param-reassign */
import { Dates, BaseUriBuilderParams } from './types';
import { format, startOfToday, addDays } from 'date-fns';
// todo this can be smarter and use browser querystring if it exists
import querysting from 'querystring';

export type Rates = {
  redeemPts?: boolean;
  aaaRate?: boolean;
  aarpRate?: boolean;
  travelAgent?: boolean;
  seniorRate?: boolean;
  governmentRate?: boolean;
  promotionCode?: string;
  offerId?: string;
  groupCode?: string;
  corporateCode?: string;
  travelAgentId?: string;
  pnd?: string;
  ratePlanCodes?: string;
  roomCategory?: string;
  roomTypeCode?: string;
  fromId?: string;
};

export type BookPath = 'book' | 'modify';
export type Person = { age?: number };
export type Room = {
  adults: Person[];
  children: Person[];
};
export type BookUrlParams = {
  dates?: Dates;
  rates?: {
    redeemPts?: boolean;
    aaaRate?: boolean;
    aarpRate?: boolean;
    travelAgent?: boolean;
    seniorRate?: boolean;
    governmentRate?: boolean;
    promotionCode?: string;
    offerId?: string;
    groupCode?: string;
    corporateCode?: string;
    travelAgentId?: string;
    pnd?: string;
    ratePlanCodes?: string;
    roomCategory?: string;
    roomTypeCode?: string;
    fromId?: string;
  };
  rooms?: Room[];
  ctyhocn: string;
  additionalQS?: {
    [key: string]: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function bookUriBuilder({
  locale = 'en',
  path = 'book',
  urlParams,
  relative,
  baseUrl,
  page = 'rooms',
}: BaseUriBuilderParams<BookUrlParams, BookPath> & {
  page?: 'rooms' | 'rates' | 'flexibledates' | 'deeplink';
}): string {
  const urlparts: string[] = [buildBaseUrl({ relative, baseUrl, locale })];

  const numRooms = urlParams?.rooms?.length || 1;

  if (path === 'modify') {
    urlparts.push('modify');
  } else {
    urlparts.push('book');
  }

  urlparts.push(numRooms >= 10 ? 'group' : 'reservation', page);

  return `${urlparts.join('/')}/?${bookParamBuilder(urlParams)}`;
}

export function bookParamBuilder(
  urlParams: BaseUriBuilderParams<BookUrlParams, BookPath>['urlParams']
) {
  const { ctyhocn, dates, rooms, rates = {}, additionalQS = {} } =
    urlParams || {};

  const arrivalDate = dates?.arrivalDate || startOfToday();
  const departureDate = dates?.departureDate || addDays(startOfToday(), 1);

  const querystringObj = {
    ctyhocn,
    arrivalDate: formatDate(arrivalDate),
    departureDate: formatDate(departureDate),
    ...rates,
    ...roomsToQS(rooms),
    ...additionalQS,
  };
  return querysting.stringify(querystringObj);
}

function buildBaseUrl({
  relative,
  baseUrl,
  locale,
}: {
  relative?: boolean;
  baseUrl?: string;
  locale: string;
}) {
  return relative || (!relative && !baseUrl)
    ? `/${locale}`
    : `${baseUrl}/${locale}`;
}

function formatDate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

function roomsToQS(rooms?: Room[]) {
  if (!rooms?.length) {
    return {};
  }
  if ((rooms?.length || 1) >= 10) {
    return {
      numRooms: rooms?.length,
    };
  }
  return rooms.reduce((prev, curr, index) => {
    const roomNumber = index + 1;
    if (curr.adults.length) {
      prev[`room${roomNumber}NumAdults`] = curr.adults.length;
      const adultAges = curr.adults
        .filter((c) => c.age !== undefined)
        .map((c) => c.age);
      if (adultAges.length) {
        prev[`room${roomNumber}AdultAges`] = adultAges.join(',');
      }
    }
    if (curr.children.length) {
      prev[`room${roomNumber}NumChildren`] = curr.children.length;
      const childAges = curr.children
        .filter((c) => c.age !== undefined)
        .map((c) => c.age);
      if (childAges.length) {
        prev[`room${roomNumber}ChildAges`] = childAges.join(',');
      }
    }

    return prev;
  }, {} as { [key: string]: number | string });
}

import React, { useEffect, useState } from 'react';
import { WpMenuItemLink, useLanguage } from '@curated-property/utils';
import { isRelativeUrl, liveToStgLink } from './functions/helper';
import Link from 'next/link';
import { sanitize } from '@curated-property/utils';
import cx from 'classnames';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
} from '@curated-property/icons';
import { NewWindow } from '@curated-property/icon-list';
import { useTranslation } from 'next-i18next';

export interface Ifooter {
  links?: {
    label?: string;
    url?: string;
    target?: string;
  }[];
  menu?: WpMenuItemLink[];
  brandPhoneNumber?: string;
  copyrightContent?: string;
  showCopyright?: boolean;
  copyrightBackgroundColour?: string;
  copyrightTextColour?: string;
  dividerColour?: string;
  hotelLogo?: React.ReactNode;
  disclaimer?: string;
  backgroundImage?: string;
  backgroundColour?: string;
  textColour?: string;
  styleOptions?: {
    footerClassName?: string;
    backgroundColor?: string;
    backgroundImage?: string;
    backgroundColorInline?: string;
    textColorInline?: string;
    textColor?: string;
    linkStyles?: {
      underline?: boolean;
      fontSize?: string;
    };
    columnStyles?: {
      columnAlign?: 'center' | 'top';
      columnOne?: {
        width?: string;
        fontSize?: string;
        logoPadding?: string;
        logoClasses?: string;
        copyClasses?: string;
      };
      columnTwo?: {
        width?: string;
        padding?: string;
      };
      columnThree?: {
        width?: string;
      };
    };
    copyrightStyles?: {
      backgroundColor?: string;
      textColor?: string;
    };
  };
  socialLinks?: {
    facebookLink?: string;
    instagramLink?: string;
    twitterLink?: string;
    linkedinLink?: string;
  };
  appUrlPrefix?: string;
  additionalLogosSection?: React.ReactNode;
  additionalLogosCMS?: AdditionalLogosCMSProps[];
  footerNavOverride?: React.ReactNode;
  pageSlug?: string;
  country?: string;
}

interface AdditionalLogosCMSProps {
  image?: {
    altText?: string;
    sourceUrl?: string;
  };
  margin?: string;
  maxWidth?: string;
  tailwindClasses?: string;
}

export function Footer({
  links,
  menu,
  hotelLogo,
  styleOptions,
  brandPhoneNumber,
  copyrightContent,
  copyrightTextColour,
  copyrightBackgroundColour,
  showCopyright,
  disclaimer,
  backgroundImage,
  backgroundColour,
  dividerColour,
  textColour,
  socialLinks,
  appUrlPrefix,
  additionalLogosSection,
  additionalLogosCMS,
  footerNavOverride,
  country,
}: Ifooter) {
  const footerClassName = styleOptions?.footerClassName ?? '';
  const bgColor = styleOptions?.backgroundColor ?? 'bg-primary';
  const textColor = styleOptions?.textColor ?? 'text-bg';
  const { t } = useTranslation();

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  let columnAlign = 'items-center justify-center xl:justify-between'; //center
  let col1Margin = '';
  if (styleOptions?.columnStyles?.columnAlign === 'top') {
    columnAlign = 'items-start justify-center xl:justify-between';
    col1Margin = 'xl:-mt-4';
  }
  const col1FontSize =
    styleOptions?.columnStyles?.columnOne?.fontSize ?? 'text-xl';
  const col1Width = styleOptions?.columnStyles?.columnOne?.width ?? 'max-w-xl';
  const col1LogoClasses =
    styleOptions?.columnStyles?.columnOne?.logoClasses ?? '';
  const col1LogoPadding =
    styleOptions?.columnStyles?.columnOne?.logoPadding ?? 'px-2 sm:px-8';
  const col1CopyClasses =
    styleOptions?.columnStyles?.columnOne?.copyClasses ?? '';
  const col2Width = styleOptions?.columnStyles?.columnTwo?.width ?? 'max-w-md';
  const col2Padding =
    styleOptions?.columnStyles?.columnTwo?.padding ?? 'pt-8 sm:p-8 xl:p-4';
  const col3Width =
    styleOptions?.columnStyles?.columnThree?.width ?? 'max-w-xl';
  const linksUnderline =
    styleOptions?.linkStyles?.underline ?? true
      ? 'underline hover:no-underline'
      : '!no-underline';
  const linksSize = styleOptions?.linkStyles?.fontSize ?? 'text-sm';
  let contentWidth = 'max-w-xl';
  let largestWidth = 0;
  const colOneWidthNum = Number(
    styleOptions?.columnStyles?.columnOne?.width?.match(/\d+/)?.[0]
  );
  const colTwoWidthNum = Number(
    styleOptions?.columnStyles?.columnTwo?.width?.match(/\d+/)?.[0]
  );
  const colThreeWidthNum = Number(
    styleOptions?.columnStyles?.columnThree?.width?.match(/\d+/)?.[0]
  );
  if (colOneWidthNum > largestWidth) {
    largestWidth = colOneWidthNum;
  }
  if (colTwoWidthNum > largestWidth) {
    largestWidth = colTwoWidthNum;
  }
  if (colThreeWidthNum > largestWidth) {
    largestWidth = colThreeWidthNum;
  }
  if (largestWidth) {
    contentWidth = `max-w-${largestWidth}xl`;
  }

  brandPhoneNumber = brandPhoneNumber ?? '1-800-Hiltons';

  const copyrightStatement = `© ${new Date().getFullYear()} ${t('hilton')}`;
  const lang = useLanguage();

  let copyrightBar;
  if (showCopyright) {
    copyrightBar = (
      <div
        data-testid="copyrightBar"
        className={`text-center p-4 ${
          styleOptions?.copyrightStyles?.backgroundColor ?? 'bg-bg-alt'
        } ${styleOptions?.copyrightStyles?.textColor ?? 'text-text'}`}
        style={{
          color: copyrightTextColour,
          background: copyrightBackgroundColour,
        }}
      >
        {copyrightContent ?? copyrightStatement}
      </div>
    );
  }

  let wpFooterLinks = menu?.filter((i) =>
    i?.location?.includes('FOOTER_NAVIGATION')
  );
  if (wpFooterLinks?.length === 1) {
    wpFooterLinks[0].target = '_blank';
    links = links?.concat(wpFooterLinks);
    wpFooterLinks = undefined;
  }

  // Add French to Footer Links
  const frenchSvcUrl =
    'https://www.hilton.com/fr/p/conditions-generales-de-services/';
  const frenchLinkCheck = Object.values(links ?? []).some(
    (x) => x?.url === frenchSvcUrl
  );

  if (!frenchLinkCheck && lang === 'fr' && country?.toLowerCase() === 'fr') {
    const frLabel = `${t('condGenServices')}`;
    links?.push({
      label: frLabel,
      url: 'https://www.hilton.com/fr/p/conditions-generales-de-services/',
      target: '_blank',
    });
  }

  // Remove the Hilton Gift Card link on non English-language sites
  if (lang !== 'en') {
    links = links?.filter(
      (a) => a?.url !== 'https://www.buyhiltongiftcards.com/'
    );
  }

  const hasSocialLinks = Object.values(socialLinks ?? {}).some((x) => x);

  const liClasses =
    'inline-block w-full leading-loose text-center xl:text-left sm:w-1/2 sm:pr-2 align-text-top';

  return (
    <footer
      className={`w-full ${bgColor}  ${textColor} ${footerClassName}`}
      data-testid="footer"
      style={{
        backgroundImage:
          styleOptions?.backgroundImage?.substring(0, 15) === 'linear-gradient'
            ? styleOptions?.backgroundImage
            : styleOptions?.backgroundImage || backgroundImage
            ? `url(${styleOptions?.backgroundImage || backgroundImage})`
            : undefined,
        backgroundColor:
          backgroundColour || styleOptions?.backgroundColorInline,
        color: textColour || styleOptions?.textColorInline,
      }}
    >
      <div
        className={`px-2 sm:px-4 py-6 sm:py-12 flex flex-wrap  m-auto xl:max-w-none lg:container ${contentWidth} ${columnAlign}`}
      >
        {/* COLUMN 1 */}
        <div className={`flex items-left flex-col ${col1Width} ${col1Margin}`}>
          <div className="flex justify-center xl:justify-start items-center">
            <div
              className={`${
                lang === 'ar'
                  ? 'border-l border-l-current'
                  : 'border-r border-r-current'
              } ${col1LogoPadding} ${col1LogoClasses}`}
              style={{ borderColor: dividerColour }}
            >
              {hotelLogo}
            </div>
            <div
              className={`flex flex-col px-4 sm:px-8 sm:py-4 ${col1CopyClasses}`}
            >
              <div
                data-testid="socialLinks"
                className={`md:flex md:flex-row block items-center mb-0 ${
                  hasSocialLinks ? 'md:mb-3' : ''
                }`}
              >
                {hasSocialLinks ? (
                  <span className="md:inline-block block md:mr-1 mr-1 md:mb-0 mb-1">
                    {t('followUs')}
                  </span>
                ) : (
                  ''
                )}
                {socialLinks?.instagramLink ? (
                  <a
                    href={socialLinks?.instagramLink}
                    className="inline-block md:mx-1 ml-0 mr-1"
                    target="_blank"
                    aria-label={`Instagram, ${t('opensInNewWindow')}`}
                    rel="noreferrer"
                  >
                    <InstagramIcon className="fill-current w-6 h-5" />
                  </a>
                ) : (
                  ''
                )}
                {socialLinks?.facebookLink ? (
                  <a
                    href={socialLinks?.facebookLink}
                    className="inline-block md:mx-1 ml-0 mr-1"
                    target="_blank"
                    aria-label={`Facebook, ${t('opensInNewWindow')}`}
                    rel="noreferrer"
                  >
                    <FacebookIcon className="w-6 h-5 fill-current" />
                  </a>
                ) : (
                  ''
                )}
                {socialLinks?.twitterLink ? (
                  <a
                    href={socialLinks?.twitterLink}
                    className="inline-block md:mx-1 ml-0 mr-1"
                    target="_blank"
                    aria-label={`Twitter, ${t('opensInNewWindow')}`}
                    rel="noreferrer"
                  >
                    <TwitterIcon className="w-6 h-5 fill-current" />
                  </a>
                ) : (
                  ''
                )}
                {socialLinks?.linkedinLink ? (
                  <a
                    href={socialLinks?.linkedinLink}
                    className="inline-block md:mx-1.5 ml-1 mr-1"
                    target="_blank"
                    aria-label={`LinkedIn, ${t('opensInNewWindow')}`}
                    rel="noreferrer"
                  >
                    <LinkedinIcon className="w-6 h-5 fill-current" />
                  </a>
                ) : (
                  ''
                )}
              </div>
              <span>{t('howCanWeHelp')}</span>

              <a
                data-testid="brandPhoneNumber"
                href={`tel:${brandPhoneNumber}`}
                className={`${col1FontSize} font-bold`}
                aria-label={`${t('phone')}, ${brandPhoneNumber}`}
              >
                {brandPhoneNumber}
              </a>
              <span>{t('callUsTollFree')}</span>
            </div>
          </div>
          <div className="flex mt-4 lg:mt-0 justify-center sm:justify-start items-center flex-wrap">
            {additionalLogosCMS
              ? additionalLogosCMS?.map((logo, key) => {
                  const urlSansCloudinaryParams =
                    logo?.image?.sourceUrl?.replace(/,w_300,h_300/gi, '');
                  return (
                    <AdditionalLogosCMS
                      key={key}
                      image={{
                        altText: logo?.image?.altText,
                        sourceUrl: urlSansCloudinaryParams,
                      }}
                      margin={logo?.margin}
                      maxWidth={logo?.maxWidth}
                      tailwindClasses={logo?.tailwindClasses}
                    />
                  );
                })
              : additionalLogosSection}
          </div>
          {disclaimer ? (
            <div
              className="pt-4 w-full flex justify-center xl:justify-start px-4 lg:w-auto lg:px-8"
              dangerouslySetInnerHTML={{
                __html: sanitize(disclaimer),
              }}
            ></div>
          ) : (
            ''
          )}
        </div>

        {/* COLUMN 2 */}
        {!footerNavOverride ? (
          <ul
            className={`flex flex-col text-center xl:text-left ${col2Width} w-full xl:w-auto ${col2Padding}`}
          >
            {wpFooterLinks?.reverse().map((item, key) => (
              <WPFooterLink
                key={key}
                link={item?.url?.replace(`/hotels/${appUrlPrefix}`, '')}
                title={item?.label}
                appUrlPrefix={appUrlPrefix}
                language={lang}
                className={linksUnderline}
              />
            ))}
          </ul>
        ) : (
          <div>{footerNavOverride}</div>
        )}

        {/* COLUMN 3 */}
        <div className={`${col3Width} ${linksSize}`}>
          <ul className="pt-8 sm:pt-0">
            {links?.map((link) => (
              <li key={link.label} className={liClasses}>
                <a
                  data-testid={
                    link.url?.includes('hilton.com')
                      ? 'staticFooterLink'
                      : 'staticFooter3PLink'
                  }
                  className={`${linksUnderline}`}
                  target={link?.target || '_self'}
                  href={
                    process?.env?.APP_ENV !== 'prd'
                      ? liveToStgLink(link?.url ?? '')
                      : link.url
                  }
                >
                  {link.label}
                </a>
                {link?.target === '_blank' && (
                  <span
                    className="inline-block align-middle"
                    aria-hidden="true"
                    children={
                      <NewWindow fillColor="#fff" className="h-4 w-4 ml-1" />
                    }
                  />
                )}
              </li>
            ))}
            {isClient && <li id="teconsent" className={liClasses}></li>}
          </ul>
        </div>
      </div>
      {copyrightBar}
    </footer>
  );
}

interface Props {
  title: string | undefined;
  link: string | undefined;
  appUrlPrefix: string | undefined;
  language: any;
  className?: string;
}

export function WPFooterLink({
  title,
  link,
  appUrlPrefix,
  language,
  className,
}: Props) {
  if (!link) {
    return null;
  }

  let finalLink;

  if (isRelativeUrl(link)) {
    finalLink = (
      <Link
        href={`/${language}/hotels/${appUrlPrefix}${link}`}
        data-testid="footerlink"
        data-internal-link
        className={className}
      >
        {title || ''}
      </Link>
    );
  } else {
    finalLink = (
      <a
        data-testid="externalfooterlink"
        data-external-link
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {title || ''}
      </a>
    );
  }

  return <li>{finalLink}</li>;
}

export function AdditionalLogosCMS(props: AdditionalLogosCMSProps) {
  return (
    <img
      className={cx('w-full h-auto block', props?.tailwindClasses)}
      src={props?.image?.sourceUrl}
      alt={props?.image?.altText}
      style={{
        marginLeft: props?.margin !== 'default' ? `${props?.margin}px` : '8px',
        marginRight: props?.margin !== 'default' ? `${props?.margin}px` : '8px',
        maxWidth: props?.maxWidth ? `${props?.maxWidth}px` : '90px',
      }}
      loading="lazy"
    />
  );
}

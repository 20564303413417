/* eslint-disable no-nested-ternary */
import React, { forwardRef } from 'react';

export type ICalendarDay = {
  highlighted?: boolean;
  selected?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const CalendarDay = forwardRef<HTMLButtonElement, ICalendarDay>(
  (
    { className, highlighted, selected, type = 'button', ...rest },
    forwardedRef
  ) => (
    <button
      className={`cp-calendar-button w-full appearance-none block border-t border-bg border-solid py-1 text text-center uppercase tabular-nums focus:outline-none focus:shadow-outline disabled:text-text-disabled disabled:cursor-default ${
        selected
          ? 'cp-calendar-button--selected bg-primary text-text-inverse '
          : highlighted
          ? 'cp-calendar-button--highlighted bg-secondary text-text-inverse'
          : ''
      } ${className || ''}`}
      {...rest}
      type={type}
      ref={forwardedRef}
    />
  )
);
CalendarDay.displayName = 'CalendarDay';

export default CalendarDay;

interface Props {
  languageSelectorDropdownStyles?: {
    backgroundColour?: string;
    backgroundColourHover?: string;
    dividerColour?: string;
    textColour?: string;
    textColourHover?: string;
  };
}

export function OscColourOverrides({ languageSelectorDropdownStyles }: Props) {
  const generateCSSVariable = (varName, value) => {
    return value ? `--${varName}: ${value};` : '';
  };

  const cssStyles = `
        :root {
            ${generateCSSVariable(
              'language-selector-dropdown-bg-colour',
              languageSelectorDropdownStyles?.backgroundColour
            )}
            ${generateCSSVariable(
              'language-selector-dropdown-bg-colour-hover',
              languageSelectorDropdownStyles?.backgroundColourHover
            )}
            ${generateCSSVariable(
              'language-selector-dropdown-divider-colour',
              languageSelectorDropdownStyles?.dividerColour
            )}
            ${generateCSSVariable(
              'language-selector-dropdown-text-colour',
              languageSelectorDropdownStyles?.textColour
            )}
            ${generateCSSVariable(
              'language-selector-dropdown-text-colour-hover',
              languageSelectorDropdownStyles?.textColourHover
            )}
        }
    `;

  return <style>{cssStyles}</style>;
}

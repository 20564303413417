import React from 'react';
import { bookUriBuilder, BookUrlParams } from '@curated-property/uri-builder';
import { BookingWidget as Widget } from '@curated-property/dx-osc-ui';
import { useDateFnsLocale } from '@curated-property/dx-osc-ui';
import { useRouter } from 'next/router';
import times from 'lodash.times';
import { IRateData } from '@curated-property/dx-osc-ui';
import { startOfToday, format, parse, isBefore, addDays } from 'date-fns';

interface Props {
  CTYHOCN?: string;
  defaultArrivalDate?: string;
  ageRange?: {
    min: number;
    max: number;
  };
}

function makePerson(age?: number) {
  return { age };
}

const map = {
  usePoints: 'redeemPts',
  travelAgents: 'travelAgents',
  aaaRate: 'aaaRate',
  aarpRate: 'aarpRate',
  seniorRate: 'seniorRate',
  gvmtRates: 'governmentRate',
  promoCode: 'promoCode',
  groupCode: 'groupCode',
  corpAccount: 'corporateCode',
};

function parseDate(date: string) {
  return parse(date, 'yyyy-MM-dd', startOfToday());
}

function formatDate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

function rateMapper(rates: IRateData[]): BookUrlParams['rates'] {
  return rates.reduce((prev, rate) => {
    if (rate.type === 'checkbox' && rate.name && rate.value === 'true') {
      prev[map[rate.name]] = true;
    }
    if (rate.type === 'text' && rate.name && rate.value) {
      prev[map[rate.name]] = rate.value as string;
    }
    return prev;
  }, {} as NonNullable<BookUrlParams['rates']>);
}

export function BookingWidget({
  CTYHOCN,
  ageRange,
  defaultArrivalDate,
}: Props) {
  const { locale: routerLocale } = useRouter();
  const { locale } = useDateFnsLocale(routerLocale || 'en');
  const passedArrival = defaultArrivalDate
    ? parseDate(defaultArrivalDate)
    : startOfToday();
  const arrival = isBefore(startOfToday(), passedArrival)
    ? passedArrival
    : startOfToday();
  return (
    <div data-testid="booking-widget">
      <Widget
        locale={locale}
        minArrivalDate={formatDate(arrival)}
        ageRange={ageRange}
        defaultRoomState={[{ adults: 2, childAges: [], kids: 0 }]}
        onSumbit={(state) => {
          const rooms = state.rooms.map((r) => ({
            adults: times(r.adults, makePerson),
            children: r.childAges?.map(makePerson),
          }));

          const url = bookUriBuilder({
            baseUrl: 'https://www.hilton.com',
            locale: routerLocale as any,
            page: 'deeplink',
            urlParams: {
              rates: rateMapper(state.specialRates),
              rooms: rooms,
              ctyhocn: CTYHOCN || '',
              dates: {
                arrivalDate: state.arrivalDate,
                departureDate:
                  state.departureDate || addDays(state.arrivalDate, 1),
              },
            },
          });
          window.location.assign(url);
        }}
      />
    </div>
  );
}

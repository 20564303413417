import React, { useRef, useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { format, getDaysInMonth } from 'date-fns';
import en from 'date-fns/locale/en-US';
import cx from 'classnames';
import { EventCalendarInfoContext } from '../event-calendar-info-context';

interface EventCalendarDatePickerProps {
  dateStringNom?: string | null;
  dateNumber?: number;
  dateSelection?: any;
  btnClass?: string | '';
  disabledState?: boolean | false;
  currentMonth?: Date;
}

export default function EventCalendarDatePickerDay({
  dateStringNom,
  dateNumber,
  dateSelection,
  btnClass,
  disabledState,
  currentMonth,
}: EventCalendarDatePickerProps) {
  const locale = en;
  const daysInMonth = getDaysInMonth(currentMonth);
  const dateStringSafariSafe = dateStringNom.replace(/-/g, '/');
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { t } = useTranslation();

  function focusTo(tgt) {
    setTimeout(() => {
      if (tgt) {
        document.getElementById(tgt).focus();
      }
    }, 50);
  }

  // What month is this and what's the current date?
  const today = new Date();
  const lowestAvailableDateInMonth =
    parseInt(format(today, 'M')) === parseInt(format(currentMonth, 'M'))
      ? parseInt(format(today, 'd'))
      : 1;

  const hotelInfoContext = useContext(EventCalendarInfoContext);

  const contextStyles = hotelInfoContext?.inlineStyles;

  let bgInline = '';
  if (
    btnClass.includes('bg-primary') &&
    contextStyles?.eventsCalSelectedDatePickerDateBg
  ) {
    bgInline = contextStyles?.eventsCalSelectedDatePickerDateBg;
  } else {
    if (
      btnClass.includes('bg-secondary') &&
      contextStyles?.eventsCalSelectedDatePickerDateRangeBg
    ) {
      bgInline = contextStyles?.eventsCalSelectedDatePickerDateRangeBg;
    }
  }

  let textColorInline = '';
  if (!btnClass && contextStyles?.eventsCalBaseTextColor) {
    textColorInline = contextStyles?.eventsCalBaseTextColor;
  }
  if (
    (btnClass.includes('bg-primary') || btnClass.includes('bg-secondary')) &&
    contextStyles?.eventsCalSelectedDatepickerDateText
  ) {
    textColorInline = contextStyles?.eventsCalSelectedDatepickerDateText;
  }

  return (
    <>
      {dateNumber && (
        <button
          id={`date${dateNumber}`}
          data-testid={`date${dateNumber}`}
          tabIndex={!disabledState ? 0 : -1}
          disabled={disabledState}
          className={cx(
            'border-none leading-0 appearance-none block my-0 font-bold p-2 w-full tabular-nums disabled:text-text-disabled disabled:cursor-default',
            btnClass
          )}
          style={{
            color: textColorInline,
            backgroundColor: bgInline,
          }}
          onClick={(e) => {
            e.preventDefault();
            dateSelection(dateNumber);
            focusTo(`date${dateNumber}`);
          }}
          onKeyDown={(e) => {
            e.preventDefault();
            if (e.shiftKey && e.key === 'Tab') {
              document.getElementById('mobileMonth').focus();
            } else {
              switch (e.key) {
                case 'Tab':
                  focusTo('clearEventDatepicker');
                  break;
                // Spacebar
                case ' ':
                  dateSelection(dateNumber);
                  focusTo(`date${dateNumber}`);
                  break;

                case 'ArrowDown':
                  focusTo(
                    `date${
                      dateNumber + 7 > daysInMonth
                        ? daysInMonth
                        : dateNumber + 7
                    }`
                  );
                  break;
                case 'ArrowUp':
                  focusTo(
                    `date${
                      dateNumber - 7 < lowestAvailableDateInMonth
                        ? lowestAvailableDateInMonth
                        : dateNumber - 7
                    }`
                  );
                  break;

                case 'ArrowLeft':
                  focusTo(
                    `date${
                      dateNumber - 1 < lowestAvailableDateInMonth
                        ? lowestAvailableDateInMonth
                        : dateNumber - 1
                    }`
                  );
                  break;
                case 'ArrowRight':
                  focusTo(
                    `date${
                      dateNumber + 1 > daysInMonth
                        ? daysInMonth
                        : dateNumber + 1
                    }`
                  );
                  break;
              }
            }
          }}
          value={dateNumber}
          ref={buttonRef}
        >
          <span aria-hidden="true">{dateNumber.toString()}</span>
          <span className="sr-only">
            {`${format(new Date(dateStringSafariSafe), 'EEEE LLLL d yyyy', {
              locale,
            })}`}
          </span>
        </button>
      )}
    </>
  );
}

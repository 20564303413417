import cx from 'classnames';
import Link from 'next/link';
import Route from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { slugify } from '../functions/helper';
import { adobeEventTracking } from './adobe-analytics';
import {
  sanitize,
  useLanguage,
  useLinkContext,
  useInitializeConductrics,
} from '@curated-property/utils';
import {
  iconmapper,
  isRelativeUrl,
  stripInternalUrl,
  removeSingleCmsSiteId,
} from '../functions/helper';
import {
  Status,
  sendReward,
  getConductricsSelection,
} from '@dx-ui/framework-conductrics';
export interface ACFAnchorProps {
  fieldGroupName?: string;
  buttonStyle?: string | null;
  className?: string | null;
  link?: AnchorProps;
}

export interface AnchorProps {
  url?: string | null;
  title?: string | null;
  sectionHeading?: string | null;
  srContext?: string | null;
  target?: string | null;
  className?: string | null;
  buttonStyle?: string | null;
  tabIndex?: number;
  dataTestId?: string | null;
}

/* 
  Some of these button classes dont exist on any component, and JIT has a hard time recognising them
  I've added them here so that they appear to be "used"
  btn-primary primaryOutline btn-primary-outline secondary btn-secondary secondaryOutline btn-secondary-outline tertiary btn-tertiary tertiaryOutline btn-tertiary-outline quarternary btn-quarternary quarternaryOutline btn-quarternary-outline white btn-white white btn-white-outline white btn-black white btn-black-outline
*/

export function AnchorLink({
  url,
  title,
  sectionHeading,
  srContext,
  target,
  className,
  buttonStyle,
  tabIndex,
  dataTestId,
}: AnchorProps) {
  const [isWA, setIsWA] = useState(false);
  const { itemPrefix = '' } = useLinkContext?.() || {};
  const language = useLanguage();
  const currentRoute = Route?.router?.asPath;
  const { t } = useTranslation();

  useEffect(() => {
    if (
      typeof document !== 'undefined' &&
      document?.body?.dataset?.brandCode === 'wa'
    ) {
      setIsWA(true);
    }
  }, [setIsWA]);

  useInitializeConductrics();

  const [conductricsData, setConductricsData] = useState({ items: [] });
  const [hideCTAIcons, setHideCTAIcons] = useState(false);
  useEffect(() => {
    const fetchConductics = async function () {
      await getConductricsSelection(
        'curated4904-icons-in-external-ctas',
        Status.OK
      ).then((res) => {
        setConductricsData({ items: res?.items });
      });
    };
    fetchConductics();
  }, []);

  useEffect(() => {
    setHideCTAIcons(
      conductricsData?.items?.length > 0 && conductricsData?.items[0]?.c === 'B'
    );
  }, [conductricsData]);

  const adobe = () => {
    adobeEventTracking({
      ctaText: title,
      sectionHeading: sectionHeading || srContext,
      actionDetail: 'Button',
      interactionName: 'propertyButton',
    });
  };

  if (!url) {
    return null;
  }

  const iconMap = iconmapper();
  let iconKey = 'newWindow';
  let conductricsReward = 0;
  if (url?.includes('mailto:')) {
    iconKey = 'mail';
    conductricsReward = 1;
  } else if (url?.includes('tel:')) {
    iconKey = 'telephone';
    conductricsReward = 2;
  } else if (url?.includes('sms:')) {
    iconKey = 'mobile';
    conductricsReward = 3;
  } else if (title?.replace(/\s+/g, '')?.toLowerCase()?.includes('whatsapp')) {
    iconKey = 'whatsApp';
    conductricsReward = 4;
  }
  const Icon = iconMap[iconKey as keyof typeof iconMap];

  const urlWithoutSingleCmsSiteId = removeSingleCmsSiteId(url.trim());
  let processedUrl = stripInternalUrl(
    urlWithoutSingleCmsSiteId?.trim(),
    (process.env.NEXT_PUBLIC_INTERNAL_URLS || '').split(',')
  );

  if (processedUrl?.includes('-cms.dig.hhc.hilton.com')) {
    processedUrl = processedUrl?.split('-cms.dig.hhc.hilton.com')[1];
  } else if (processedUrl?.includes('-cms.dig-s.hhc.hilton.com')) {
    processedUrl = processedUrl?.split('-cms.dig-s.hhc.hilton.com')[1];
  } else if (processedUrl?.includes('-cms.dig-t.hhc.hilton.com')) {
    processedUrl = processedUrl?.split('-cms.dig-t.hhc.hilton.com')[1];
  }

  const classes = cx(
    buttonStyle !== 'plain'
      ? 'btn text text-lg sm:text-xl py-2 px-6 mx-1 mt-2'
      : 'text-lg text-current underline hover:no-underline',
    className
  );

  const handleExternalClick = () => {
    adobe();
    sendReward('CuratedEngageExternalCTAClick', conductricsReward);
  };

  if (isRelativeUrl(processedUrl)) {
    // prepend language and use it
    let finalUrl = processedUrl?.startsWith(`/${language}/`)
      ? processedUrl?.replace(`${language as string}/`, '')
      : processedUrl;
    finalUrl = `/${language}${itemPrefix}${finalUrl}`;
    return (
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <Link
        href={finalUrl}
        key={currentRoute + '-' + slugify(title || '')}
        legacyBehavior
      >
        <a
          href={finalUrl}
          data-testid={dataTestId}
          data-element-id="internal-anchor-link"
          tabIndex={tabIndex}
          className={`${classes} btn-${buttonStyle}`}
          target={target || undefined}
          aria-label={`${
            srContext &&
            !title?.toLowerCase().includes(srContext?.toLowerCase())
              ? title + ' for ' + srContext
              : title
          }${target === '_blank' ? `, ${t('opensInNewWindow')}` : ''}`}
          onClick={() => adobe()}
          dangerouslySetInnerHTML={{ __html: sanitize(title || '') }}
        ></a>
      </Link>
    );
  }
  // full url should return standard a link
  return (
    <span className="text-nowrap">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        data-testid={dataTestId}
        data-element-id="external-anchor-link"
        key={currentRoute + '-' + slugify(title || '')}
        onClick={handleExternalClick}
        tabIndex={tabIndex}
        data-external-link
        href={removeSingleCmsSiteId(url)}
        className={`${classes} btn-${buttonStyle}`}
        target={target || undefined}
        aria-label={`${
          srContext && !title?.toLowerCase().includes(srContext?.toLowerCase())
            ? title + ' for ' + srContext
            : title
        }, ${t('opensInNewWindow')}`}
      >
        <span className="flex items-center">
          <span dangerouslySetInnerHTML={{ __html: sanitize(title || '') }} />
          {!hideCTAIcons && !isWA && (
            <Icon fillColor="currentColor" className="ml-1 h-8" />
          )}
        </span>
      </a>
      {!hideCTAIcons && isWA ? (
        <Icon
          fillColor="currentColor"
          className="inline-block -ml-[11px] h-7"
        />
      ) : isWA ? (
        <div />
      ) : null}
    </span>
  );
}
